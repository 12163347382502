import { modelSchema } from "./Model";
import { CurrencyCode, currencyCodes, defaultCurrencyCode } from "@lib/currencies";
import { z } from "zod";

export const userSettingsSchema = modelSchema.extend({
    firstName: z.string()
        .optional()
        .catch(undefined),
    lastName: z.string()
        .optional()
        .catch(undefined),
    
    currency: z.custom<CurrencyCode>(
        (data) => typeof data === "string" && currencyCodes.includes(data)
    ).catch(defaultCurrencyCode),

    receiveStatusNotifications: z.boolean()
        .catch(false),
    receiveChangeNotifications: z.boolean()
        .catch(false),
    receiveBirthdayNotifications: z.boolean()
        .catch(false),
})

type UserSettings = z.infer<typeof userSettingsSchema>;

export default UserSettings;

export const getDefaultUserSettings = (userId: string) => {
    return {
        id: userId,
        ownerUid: userId,

        currency: defaultCurrencyCode,

        receiveStatusNotifications: false,
        receiveChangeNotifications: false,
        receiveBirthdayNotifications: false,
    } as UserSettings;
}