import { useCallback, createContext, useContext, useReducer } from 'react';

interface IDialogEntry {
    dialogId: string;
}

interface IDialogsState {
    displayedDialogEntries: IDialogEntry[];
    isShowingDialogs: boolean;
}

interface IDialogsStateProps {
    dialogsState: IDialogsState;
    addDialogState: (dialogId: string) => void;
    removeDialogState: (dialogId: string) => void;
}

type Action =
    | { type: "add-dialog"; entry: IDialogEntry }
    | { type: "remove-dialog"; dialogId: string }

const defaultDialogsState: IDialogsState = {
    displayedDialogEntries: [],
    isShowingDialogs: false,
}

function reducer (state: IDialogsState, action: Action): IDialogsState {
    let entries = state?.displayedDialogEntries ?? [];

    switch (action.type) {
        case 'add-dialog': {
            const entry = action.entry;

            entries = [...entries, entry];
            break;
        }
        case 'remove-dialog': {
            const dialogId = action.dialogId;

            entries = entries.filter(existingEntry => existingEntry.dialogId !== dialogId);
            break;
        }
    }

    console.log({entries});

    return {
        displayedDialogEntries: entries,
        isShowingDialogs: entries.length > 0,
    }
}

export const DialogsStateContext = createContext<IDialogsStateProps | undefined>(undefined);

export const DialogsStateProvider: React.FC = ({ children }) => {
    const [dialogsState, dispatch] = useReducer(reducer, defaultDialogsState);

    const removeDialogState = useCallback((dialogId: string) => {
        dispatch({ type: 'remove-dialog', dialogId });
    }, []);

    const addDialogState = useCallback((dialogId: string) => {
        dispatch({ type: 'add-dialog', entry: { dialogId } });

        return () => removeDialogState(dialogId);
    }, [removeDialogState]);

    return (
        <DialogsStateContext.Provider value={{dialogsState, addDialogState, removeDialogState }}>
            {children}
        </DialogsStateContext.Provider>
    );
}

const useDialogsState = () => useContext(DialogsStateContext)!;

export default useDialogsState;