import "./Popover.css";
import { createPortal } from "react-dom";
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut';

export interface PopoverProps {
    title?: string;
    onClose?: () => void;
    customCloseButton?: (isSaving: boolean, close: () => void) => React.ReactNode;
}

const Popover: React.FC<PopoverProps> = ({ title, onClose, customCloseButton, children }) => {
    const popoverParent = document.getElementById('modal-root');


    // Handle the escape key press outside the form
    useKeyboardShortcut("escape", () => {
        close();
    })

    // Handle the escape key press inside the form
    function handleKeyDown(e: React.KeyboardEvent<HTMLFormElement>) {
        if (e.key === 'Escape') {
            close();
        }
    }

    if (!popoverParent) {
        return null;
    }

    function close() {
        if (onClose) {
            onClose();
        }
    }

    return (
        createPortal(
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-center justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"/>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className="inline-block align-bottom p-4 sm:p-6 bg-gray-100 dark:bg-voodooGray rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full">
                        <form onKeyDown={handleKeyDown}>
                            {/* Title */}
                            <div className="flex justify-between items-center pb-3">
                                {title && <p className="text-2xl font-bold text-gray-500 dark:text-gray-300">{title}</p>}
                                {/* Close Button */}
                                <div className="modal-close cursor-pointer z-50" onClick={() => close()}>
                                    <svg className="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                    </svg>
                                </div>
                            </div>

                            {/* Body */}
                            {children}

                        </form>
                    </div>
                </div>
            </div>
            , popoverParent)
    )
}

export interface PopoverSectionProps {
    title?: string
}

export const PopoverSection: React.FC<PopoverSectionProps & React.HTMLAttributes<HTMLDivElement>> = ({ title, children, className, ...props }) => {
    return (
        <div {...props}>
            {title && <p className="text-2xl font-bold text-gray-500 pb-3">{title}</p>}
            <div className="mb-5 flex justify-center">
                <div className="dialog-section-body w-full">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Popover;