import { useGroup } from "@app/hooks/useGroups";
import { useTags } from "@app/hooks/useTags";
import { useItems } from "@app/hooks/useItems";
import { Redirect, useParams } from "react-router-dom";
import DetailsPage from "@app/detailsPage/DetailsPage";
import Group from "@modules/app/models/Group";

interface RouteParams {
    groupId: string;
}

const GroupDetailsPage: React.FC = () => {
    const { groupId } = useParams<RouteParams>();

    const group = useGroup(groupId);
    const tagCollection = useTags();
    const itemsInGroup = useItems(group?.tags);

    // If the group was not found
    if (!group) {
        return <Redirect to="/app" />
    }

    const tagsInGroup = Group.lookupReferencedTags(group, tagCollection.tags);

    return <DetailsPage items={itemsInGroup.items ?? []} tags={tagsInGroup} name={group.name ?? ''} group={group} />
}

export default GroupDetailsPage;