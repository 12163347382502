
import { Paragraph } from "@ui";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import useDialogsState from '@app/dialog/useDialogsState';

const GlobalDropzone: React.FC<{
    onFilesDrop?: (files: FileList) => void
}> = ({
    onFilesDrop,
}) => {
    const [showDragIndicator, setShowDragIndicator] = useState<boolean>(false);

    const dropzoneParent = document.getElementById('modal-root');

    const { dialogsState } = useDialogsState();

    const fileDropAllowed = !dialogsState.isShowingDialogs;

    useEffect(() => {
        const handleDrop = (e: DragEvent) => {
            if (!onFilesDrop || !fileDropAllowed)
                return;

            e.preventDefault();
    
            const files = e.dataTransfer?.files;
            
            setShowDragIndicator(false);
    
            if (files)
                onFilesDrop(files);
        }
    
        const handleDragOver = (e: DragEvent) => {
            e.preventDefault();
        }
        
        let dragEventTargets : EventTarget[] = [];

        const handleDragEnter = (e: DragEvent) => {
            if (e.target)
                dragEventTargets.push(e.target);
    
            if (fileDropAllowed && onFilesDrop)
                setShowDragIndicator(true);
        }
    
        const handleDragLeave = (e: DragEvent) => {
            dragEventTargets = dragEventTargets.filter(x => x !== e.target);

            if (dragEventTargets.length === 0)
                setShowDragIndicator(false);
        }

        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragleave', handleDragLeave);

        return () => {
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragleave', handleDragLeave);
        }
    }, [fileDropAllowed, onFilesDrop])

    return showDragIndicator && dropzoneParent && fileDropAllowed ? createPortal(
        <div className="fixed left-0 top-0 right-0 bottom-0 bg-gray-300 opacity-50 p-8">
            <div className="border-4 border-dashed w-full h-full border-black">
                <Paragraph>Drop your files here</Paragraph>
            </div>
        </div>
    , dropzoneParent) : <></>;
}

export default GlobalDropzone