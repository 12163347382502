import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M14.6667 0C15.403 0 16 0.596954 16 1.33333V14.6667C16 15.403 15.403 16 14.6667 16H1.33333C0.596954 16 0 15.403 0 14.6667V1.33333C0 0.596954 0.596954 0 1.33333 0H14.6667ZM14.6667 1H1.33333C1.16969 1 1.03359 1.11792 1.00537 1.27342L1 1.33333V14.6667C1 14.8303 1.11792 14.9664 1.27342 14.9946L1.33333 15H14.6667C14.8303 15 14.9664 14.8821 14.9946 14.7266L15 14.6667V1.33333C15 1.16969 14.8821 1.03359 14.7266 1.00537L14.6667 1ZM5.33333 11.9976V12.6667H10.6667V11.6895H6.73847L9.21734 7.74729L6.84452 4.30566H10.3839V3.33333H5.40845V3.99278L8.00663 7.76655L5.33333 11.9976Z"/>
    </svg>
  );
}

export default Icon;
