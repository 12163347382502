import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M11.6622 3.8135C11.9063 3.84661 12.0944 4.0558 12.0944 4.30893L12.0944 8.9756L12.0898 9.04345C12.0567 9.2875 11.8475 9.4756 11.5944 9.4756L11.5265 9.47104C11.2825 9.43793 11.0944 9.22873 11.0944 8.9756L11.0944 5.50623L4.547 12.0536C4.35174 12.2489 4.03516 12.2489 3.83989 12.0536C3.64463 11.8583 3.64463 11.5418 3.83989 11.3465L10.3775 4.80893L6.92771 4.80893L6.85987 4.80437C6.61582 4.77126 6.42771 4.56206 6.42771 4.30893C6.42771 4.03279 6.65157 3.80893 6.92771 3.80893L11.5944 3.80893L11.6622 3.8135Z"/>
    </svg>
  );
}

export default Icon;
