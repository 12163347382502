import { Yearly, Monthly, Weekly, Daily, Price, Interval, Years, Quantity, Sum, IntervalLongest, IntervalShortest, ArrowDown, ArrowUp, PriceFirst, PriceLast, BarRightArrowRight, BarLeftArrowLeft } from "@icons";
import { StatisticsHeader, StatisticsList, StatisticsListRow, Divider, Tag as TagLabel } from "@ui";
import { useTranslation } from "react-i18next";
import { currencyToString, dateTimeToDateString, floatToString, millisecondsToString, quantityToString, unitToString } from "@lib/stringConversion";
import { useStatisticsCalculations } from './useStatisticsCalculations';
import Item, { ItemUnit } from "@models/Item";
import './StatisticsPanel.css';
import { useUserSettings } from '@app/hooks/useUserSettings';
import Tag from '@models/Tag';
import routes from '@modules/routes/Routes';
import DetailsStatisticsRow from "./DetailsStatisticsRow";
import { DateTime } from "luxon";

export interface StatisticsPanelProps {
    items: Item[];
    tags: Tag[];
}

const StatisticsPanel: React.FC<StatisticsPanelProps> = ({ items, tags }) => {

    const { t } = useTranslation();
    const userSettings = useUserSettings();
    const statisticsCalculations = useStatisticsCalculations({ mode: 'single', items: items });

    const quantityByUnits = statisticsCalculations?.total.quantityByUnits ?? {};
    let quantityStrings = Object.keys(quantityByUnits).length === 0 
        // 0 items
        ? [ quantityToString(0, t, ItemUnit.piece) ]
        // 1+ items
        : Object.keys(quantityByUnits).map(key => {
            const unit = parseInt(key);
            const quantity = quantityByUnits[unit];
            return quantityToString(quantity, t, unit);
        });

    
    const avaragePricesByUnits = statisticsCalculations?.average.priceByUnits ?? {};
    let averagePricesStrings = Object.keys(avaragePricesByUnits).length === 0 
        // 0 items
        ? [ currencyToString(0, userSettings.currency) ]
        // 1+ items
        : Object.keys(avaragePricesByUnits).map(key => {
            const unit = parseInt(key);
            const price = avaragePricesByUnits[unit];
            return `${currencyToString(price, userSettings.currency)} (${unitToString(unit, t)})`;
        });

    return (
        <>
            <StatisticsHeader className="mb-1">{t('app.statistics.total')}</StatisticsHeader>
            <StatisticsList className="w-full">
                <DetailsStatisticsRow name={t('app.statistics.years')} months={statisticsCalculations?.total.months} weeks={statisticsCalculations?.total.weeks} days={statisticsCalculations?.total.days} value={`${floatToString(statisticsCalculations?.total.years ?? 0)} (${(statisticsCalculations?.dates.firstPurchase ?? DateTime.now()).year} - ${(statisticsCalculations?.dates.lastPurchase ?? DateTime.now()).year})`} icon={<Years color="primary" size="medium" />} />
                <DetailsStatisticsRow name={t('app.statistics.quantity')} value={quantityStrings.join(', ')} icon={<Quantity color="primary" size="medium" />} />
                <DetailsStatisticsRow name={t('app.statistics.sum')} value={currencyToString(statisticsCalculations?.total.price ?? 0, userSettings.currency)} icon={<Sum color="primary" size="medium" />}  />
            </StatisticsList>
            
            <Divider className="block mt-1 md:hidden"></Divider>
            <StatisticsHeader className="mb-1">{t('app.label.tags')}</StatisticsHeader>
            <div className="bg-white dark:bg-black rounded-md p-4 mb-4">
            {tags.map(tag => (
                        <TagLabel key={tag.id} color="action" value={tag.name} className="mr-2" linkTo={routes.app.tagDetails.build(tag.id)} />
                    ))}
            </div>

            <StatisticsHeader className="mb-1">{t('app.statistics.onAverage')}</StatisticsHeader>
            <StatisticsList className="w-full">
                <StatisticsListRow name={t('app.statistics.yearly')} value={currencyToString(statisticsCalculations?.average.yearly ?? 0, userSettings.currency)} icon={<Yearly color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.monthly')} value={currencyToString(statisticsCalculations?.average.monthly ?? 0, userSettings.currency)} icon={<Monthly color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.weekly')} value={currencyToString(statisticsCalculations?.average.weekly ?? 0, userSettings.currency)} icon={<Weekly color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.daily')} value={currencyToString(statisticsCalculations?.average.daily ?? 0, userSettings.currency)} icon={<Daily color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.price')} value={currencyToString(statisticsCalculations?.average.price ?? 0, userSettings.currency)} icon={<Price color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.pricePerUnit')} value={averagePricesStrings.join(', ')} icon={<Price color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.timeSpan')} value={millisecondsToString(statisticsCalculations?.average.timespan ?? 0, t)} icon={<Interval color="primary" size="small" />} />
            </StatisticsList>

            <StatisticsHeader className="mb-1">{t('app.statistics.times')}</StatisticsHeader>
            <StatisticsList className="w-full">
                <StatisticsListRow name={t('app.statistics.firstPurchase')} value={dateTimeToDateString(statisticsCalculations?.dates.firstPurchase ?? DateTime.now())} icon={<BarLeftArrowLeft color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.lastPurchase')} value={dateTimeToDateString(statisticsCalculations?.dates.lastPurchase ?? DateTime.now())} icon={<BarRightArrowRight color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.longestTimeSpan')} value={millisecondsToString(statisticsCalculations?.dates.longestTimespan ?? 0, t)} icon={<IntervalLongest color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.shortestTimeSpan')} value={millisecondsToString(statisticsCalculations?.dates.shortesTimespan ?? 0, t)} icon={<IntervalShortest color="primary" size="small" />} />
            </StatisticsList>

            <StatisticsHeader className="mb-1">{t('app.statistics.prices')}</StatisticsHeader>
            <StatisticsList className="w-full">
                <StatisticsListRow name={t('app.statistics.firstPurchasePrice')} value={currencyToString(statisticsCalculations?.prices.firstPrice ?? 0, userSettings.currency)} icon={<PriceFirst color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.lastPurchasePrice')} value={currencyToString(statisticsCalculations?.prices.lastPrice ?? 0, userSettings.currency)} icon={<PriceLast color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.lowestPurchasePrice')} value={currencyToString(statisticsCalculations?.prices.lowestPrice ?? 0, userSettings.currency)} icon={<ArrowDown color="primary" size="small" />} />
                <StatisticsListRow name={t('app.statistics.highestPurchasePrice')} value={currencyToString(statisticsCalculations?.prices.highestPrice ?? 0, userSettings.currency)} icon={<ArrowUp color="primary" size="small" />} />
            </StatisticsList>
        </>
    )
}

export default StatisticsPanel;