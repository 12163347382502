import { useTags } from "@app/hooks/useTags";
import OverviewPage, { OverviewFilter } from "@app/overview/OverviewPage";

const TagsOverviewPage: React.FC = () => {

    const tags = useTags();

    return <OverviewPage tags={tags.tags} currentFilter={OverviewFilter.Tags} />
}

export default TagsOverviewPage;