import Item from '@models/Item';
import { groupArrayByMany } from '@helpers';
import Tag from '@models/Tag';
import RepeatInterval from '@models/RepeatInterval';
import { DateTime } from 'luxon';

export enum DetailsChartMeasure {
    price,
    quantity,
}

export type DetailsChartMode = 
    | { mode: 'default', itemsInGroup: Item[], tagsInGroup: Tag[] }
    | { mode: 'running-total', itemsInGroup: Item[], tagsInGroup: Tag[] }

export type DetailsChartTimeGroup = 'month' | 'year';

export default function getDetailsChartData(mode: DetailsChartMode, measure: DetailsChartMeasure, timeGroup?: DetailsChartTimeGroup) {

    const { itemsInGroup, tagsInGroup } = mode;

    const itemsGroupedByName = groupArrayByMany(itemsInGroup, (item) => item.tags.map(tag => tag.id).filter(tagId => tagsInGroup.map(x => x.id).includes(tagId)));

    const groupedItemsKvps = Object.keys(itemsGroupedByName).map(x => ({ key: x, value: itemsGroupedByName[x] }))

    const getItemValue = (item: Item) => {
        switch (measure) {
            case DetailsChartMeasure.price:
                return item.price;
            case DetailsChartMeasure.quantity:
                return item.quantity;
        }
    }

    const chartData = groupedItemsKvps.map(kvp => ({
        label: tagsInGroup.find(tag => tag.id === kvp.key)?.name ?? "unknown",
        data: kvp.value.flatMap(item => ([
            {
                x: item.date,
                y: getItemValue(item),
            },
            ...item.repeatInterval 
                ? RepeatInterval.getRepetitionsBetweenDates(item.repeatInterval, item.date).map(date => ({
                    x: date,
                    y: getItemValue(item),
                })) 
                : []
        ]))
    }));

    for (const dataSet of chartData) {
        // Set the date of the dataPoint to the start of the time unit
        for (const dataPoint of dataSet.data) {
            switch (timeGroup) {
                case 'month':
                    dataPoint.x = dataPoint.x.startOf('month');
                    break;
                case 'year':
                    dataPoint.x = dataPoint.x.startOf('year');
                    break;
                default:
                    dataPoint.x = dataPoint.x.startOf('day');
                    break;
            }
        }

        // Group data depending on their date
        const dataPoints: { [key: string]: { x: DateTime; y: number; } } = {};
        for (const dataPoint of dataSet.data) {
            const dateKey = dataPoint.x.toMillis().toString();
            if (Object.keys(dataPoints).includes(dateKey)) {
                dataPoints[dateKey].y += dataPoint.y;
            } else {
                dataPoints[dateKey] = dataPoint;
            }
        }

        dataSet.data = Object.values(dataPoints);
    }
    
    // Sort the data by the x axis
    for (const dataSet of chartData) {
        dataSet.data.sort((a, b) => a.x.toMillis() - b.x.toMillis());
    }

    // Generate a running total
    if (mode.mode === 'running-total') {
        for (const dataSet of chartData) {
            let prevY = 0;
            for (const dataPoint of dataSet.data) {
                dataPoint.y += prevY;
                prevY = dataPoint.y;
            }
        }
    }

    // const jsDateChartData = chartData.map(dataSet => ({ 
    //     ...dataSet, 
    //     data: dataSet.data.map(dataPoint => ({
    //         ...dataPoint,
    //         x: dataPoint.x.toJSDate(),
    //     })
    // }))

    return chartData;
}