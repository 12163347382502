import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 15C11.866 15 15 11.866 15 8.00001C15 4.13401 11.866 1.00001 8 1.00001C4.134 1.00001 0.999997 4.13401 0.999997 8.00001C0.999997 11.866 4.134 15 8 15ZM4.25736 9.41421C4.45262 9.21895 4.7692 9.21895 4.96447 9.41421L6.73223 11.182L12.0355 5.87868C12.2308 5.68342 12.5474 5.68342 12.7426 5.87868C12.9379 6.07394 12.9379 6.39052 12.7426 6.58579L7.08614 12.2423L7.08579 12.2426C6.89052 12.4379 6.57394 12.4379 6.37868 12.2426L4.25736 10.1213C4.0621 9.92606 4.0621 9.60948 4.25736 9.41421Z"/>
</svg>
  );
}

export default Icon;