import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.98468 2.65625C5.82953 2.65625 3.95512 3.85825 2.99528 5.62842L1.99742 5.38084C3.0857 3.17424 5.35792 1.65625 7.98468 1.65625C11.4464 1.65625 14.2923 4.29262 14.6242 7.66708L15.1449 7.14645C15.3401 6.95118 15.6567 6.95118 15.852 7.14645C16.0472 7.34171 16.0472 7.65829 15.852 7.85355L14.4377 9.26777C14.2425 9.46303 13.9259 9.46303 13.7306 9.26777L12.3164 7.85355C12.1212 7.65829 12.1212 7.34171 12.3164 7.14645C12.5117 6.95118 12.8283 6.95118 13.0235 7.14645L13.6275 7.7504C13.3381 4.88927 10.9221 2.65625 7.98468 2.65625ZM7.98468 14C10.4088 14 12.4777 12.4793 13.2895 10.3398L14.2662 10.5821C13.3419 13.1577 10.8784 15 7.98468 15C4.45604 15 1.5672 12.2607 1.32872 8.7926L0.853553 9.26777C0.658291 9.46303 0.341709 9.46303 0.146447 9.26777C-0.0488155 9.0725 -0.0488155 8.75592 0.146447 8.56066L1.56066 7.14645C1.75592 6.95118 2.0725 6.95118 2.26777 7.14645L3.68198 8.56066C3.87724 8.75592 3.87724 9.0725 3.68198 9.26777C3.48672 9.46303 3.17013 9.46303 2.97487 9.26777L2.31982 8.61272C2.46808 11.613 4.94762 14 7.98468 14Z"/>
    </svg>
  );
}

export default Icon;