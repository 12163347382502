import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
        <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M4.66669 7C4.1144 7 3.66669 7.44772 3.66669 8C3.66669 8.55228 4.1144 9 4.66669 9C5.21897 9 5.66669 8.55228 5.66669 8C5.66669 7.44772 5.21897 7 4.66669 7ZM7.00002 8C7.00002 7.44772 7.44774 7 8.00002 7C8.55231 7 9.00002 7.44772 9.00002 8C9.00002 8.55228 8.55231 9 8.00002 9C7.44774 9 7.00002 8.55228 7.00002 8ZM10.3334 8C10.3334 7.44772 10.7811 7 11.3334 7C11.8856 7 12.3334 7.44772 12.3334 8C12.3334 8.55228 11.8856 9 11.3334 9C10.7811 9 10.3334 8.55228 10.3334 8Z"/>
    </svg>
  );
}

export default Icon;