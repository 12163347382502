import { Link } from "../";
import React from "react";
import classNames from 'classnames';
import routes from '../../modules/routes/Routes';
import { useTranslation } from "react-i18next";

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
    includeCopyright?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    includeCopyright = true,
    className,
    children,
    ...props
}) => {
    const { t } = useTranslation();
    // Copy the children and convert it into an array
    let childrenWithCopyright = [...React.Children.toArray(children)];

    // If includeCopyright is set, Insert the copyright text at the beginning
    if (includeCopyright) {
        childrenWithCopyright.unshift(<> {t('authentication.footer.AllRights')}</>)
    }

    // Add a spacer element between each of the elements
    const spacerElement = (<> • </>)

    childrenWithCopyright = childrenWithCopyright
        .map((e, i) => 
            // Only Add the spacer if this is not the last element
            i < childrenWithCopyright.length - 1 ? [e, spacerElement] : [e]
        )
        .reduce((a, b) => a.concat(b))
        .map((e, i) => (<span key={i}>{e}</span>));

    const classes = classNames(className, 'h-10 p-10 md:pl-20');

    return (
        <div className={classes} {...props}>
            <div className="text-center md:text-left text-gray-500">
                {childrenWithCopyright}
            </div>
        </div>
    );
};

export default Breadcrumbs;

export const DefaultBreadcrumbs: React.FC<BreadcrumbsProps> = ({
    // Do not forward the includeCopyright and children properties
    includeCopyright = true,
    children,

    ...props
}) => {
    const { t } = useTranslation();
    return (
        <Breadcrumbs {...props}>
            <Link to={{ pathname: "mailto:costi@costify.app?subject=How%20can%20I%20join?" }} target="_blank">{t('authentication.footer.ContactUs')}</Link>
            <Link to={routes.authentication.login.build()}>{t('authentication.footer.Login')}</Link>
            <Link to={routes.homepage.termsOfService.build()} target="_blank">{t('authentication.footer.TermsOfService')}</Link>
            <Link to={routes.homepage.privacyPolicy.build()} target="_blank">{t('authentication.footer.PrivacyPolicy')}</Link>
        </Breadcrumbs>
    );
}