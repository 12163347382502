import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape Copy" fillRule="evenodd" clipRule="evenodd" d="M7.97944 0L8.0658 0.00559396L8.12794 0.0162039L8.21119 0.0463375L8.28446 0.0884365L8.32192 0.117169L12.3533 4.14604L12.4112 4.21529C12.5462 4.41016 12.5269 4.67958 12.3533 4.85315L12.2841 4.911C12.0892 5.046 11.8198 5.02671 11.6462 4.85315L8.4998 1.70759V10.4996L8.49174 10.5895C8.4494 10.8227 8.24526 10.9996 7.9998 10.9996L7.90992 10.9915C7.67667 10.9492 7.4998 10.7451 7.4998 10.4996V1.70759L4.35335 4.85315L4.2841 4.911C4.08923 5.046 3.81981 5.02671 3.64624 4.85315C3.45098 4.65788 3.45098 4.3413 3.64624 4.14604L7.67765 0.117187L7.73279 0.0767813L7.78839 0.0463311L7.84878 0.0228351L7.911 0.00746061L7.97944 0ZM0.5 7C0.223858 7 0 7.22386 0 7.5V15.5C0 15.7761 0.223858 16 0.5 16H15.5C15.7761 16 16 15.7761 16 15.5V7.5C16 7.22386 15.7761 7 15.5 7C15.2239 7 15 7.22386 15 7.5V15H1V7.5C1 7.22386 0.776142 7 0.5 7Z"/>
  </svg>
  );
}

export default Icon;