import { DialogsStateProvider } from "@modules/app/dialog/useDialogsState"
import { BankConnectionsProvider } from "@modules/app/hooks/useBankConnections"
import { GroupsProvider } from "@modules/app/hooks/useGroups"
import { ItemsProvider } from "@modules/app/hooks/useItems"
import { TagsProvider } from "@modules/app/hooks/useTags"
import { UserSettingsProvider } from "@modules/app/hooks/useUserSettings"
import AuthProvider from "@modules/authentication/AuthProvider"

const DataProviders: React.FC = ({ children }) => {
    return (
        <AuthProvider>
            <GroupsProvider>
                <TagsProvider>
                    <ItemsProvider>
                        <BankConnectionsProvider>
                            <UserSettingsProvider>
                                <DialogsStateProvider>
                                    {children}
                                </DialogsStateProvider>
                            </UserSettingsProvider>
                        </BankConnectionsProvider>
                    </ItemsProvider>
                </TagsProvider>
            </GroupsProvider>
        </AuthProvider>
    )
}

export default DataProviders;