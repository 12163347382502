import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM4.49998 7C4.49998 6.72386 4.72384 6.5 4.99998 6.5H11C11.2761 6.5 11.5 6.72386 11.5 7C11.5 7.27614 11.2761 7.5 11 7.5H4.99998C4.72384 7.5 4.49998 7.27614 4.49998 7ZM4.49998 9C4.49998 8.72386 4.72384 8.5 4.99998 8.5H11C11.2761 8.5 11.5 8.72386 11.5 9C11.5 9.27614 11.2761 9.5 11 9.5H4.99998C4.72384 9.5 4.49998 9.27614 4.49998 9Z"/>
    </svg>
  );
}

export default Icon;
