import classNames from 'classnames';
import * as H from 'history';
import { Link } from 'react-router-dom';

const colorClasses = {
    default: 'bg-indigo-100 dark:bg-gray-900 dark:text-gray-400',
    card: 'bg-gray-200 text-gray-700 dark:bg-gray-900 dark:text-gray-400',
    exempted: 'bg-red-200 text-gray-700',
    success: 'bg-green-600 text-white',
    danger: 'bg-red-600 text-white',
    action: 'bg-black bg-opacity-10 dark:bg-gray-800 text-black dark:text-white hover:bg-white cursor-pointer dark:hover:bg-gray-700 hover:shadow-md p-4',
}

const sizeClasses = {
    small: 'px-1.5 py-0 text-xs font-semibold h-5 my-auto rounded',
    medium: 'px-2 py-1 text-sm font-semibold rounded-md',
}

export const colors = Object.keys(colorClasses);
export const sizes = Object.keys(sizeClasses);

export interface TagProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: keyof typeof colorClasses;
    size?: keyof typeof sizeClasses;
    value: string;
    onRemove?: () => void;
    linkTo?: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>);
}

const Tag: React.FC<TagProps> = ({
    color = 'default',
    size = 'medium',
    value,
    onRemove,
    onClick,
    linkTo,
    className,
    ...props
}) => {

    const classes = classNames(
        className,
        'inline-flex items-center font-semibold',
        colorClasses[color],
        sizeClasses[size]
    )

    return linkTo ? (
        <Link to={linkTo} className={classes} {...props as any}>
            <span className="leading-relaxed max-w-xs" x-text="tag">{value}</span>

            { onRemove && (
                <div onClick={onRemove} className="inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none -mr-2">
                    <svg className="w-6 h-6 fill-current mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z" />
                    </svg>
                </div>
            )}
        </Link>
    ) : (
        <button type="button" className={classes} onClick={(e) => {
            e.preventDefault();
            if (onClick) {
                onClick(e);
            }
        }} {...props}>
            <span className="leading-relaxed max-w-xs" x-text="tag">{value}</span>

            { onRemove && (
                <div onClick={onRemove} className="inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none -mr-2">
                    <svg className="w-6 h-6 fill-current mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z" />
                    </svg>
                </div>
            )}
        </button>
    );
};

export default Tag;