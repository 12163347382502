import classNames from 'classnames';
import { createContext, useContext, useState } from "react";

interface IPillContext {
    selectedValue?: string;
    setSelectedValue: (option: string) => void;
}

const PillContext = createContext<IPillContext | undefined>(undefined);

// Pill
export interface PillProps extends React.HTMLAttributes<HTMLUListElement> {
    defaultValue?: string;
    onValueChange?: (value: string) => void;
}

const Pill: React.FC<PillProps> = ({
    defaultValue,
    onValueChange,
    className,
    children,
    ...props
}) => {

    const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue);

    const classes = classNames(
        className,
        'flex flex-row flex-wrap mb-2 inline-flex list-none bg-white dark:bg-gray-800 rounded-lg',
    )

    const changeSelectedValue = (value: string) => {
        setSelectedValue(value);
        onValueChange && onValueChange(value);
    }

    return (
        <PillContext.Provider value={{ selectedValue, setSelectedValue: changeSelectedValue }}>
            <ul className={classes} {...props}>
                {children}
            </ul>
        </PillContext.Provider>
    );
};

export default Pill;

// Pills Item
export interface PillItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    value: string;
}

export const PillItem: React.FC<PillItemProps> = ({
    value,
    className,
    children,
    ...props
}) => {

    const pillContext = useContext(PillContext);
    const isSelected = pillContext?.selectedValue === value;

    const classes = classNames(
        className,
        isSelected ? 'text-black dark:text-white bg-gray-100 dark:bg-gray-700 m-1': ' m-1 text-black dark:text-white',
        'rounded-md px-4 py-1 text-center font-semibold text-sm w-full px-3 mr-1 focus:outline-none focus:ring-0',
    );

    return (
        <li className='flex'>
            <button className={classes} {...props} onClick={() => pillContext?.setSelectedValue(value)}>
                {children}
            </button>
        </li>
    );
};