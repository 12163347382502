import { Link } from 'react-router-dom';
import * as H from 'history';
import { LoadingSpinner } from '../../icons';
import classNames from 'classnames';

const colorClasses = {
    primary: 'bg-blue-500 hover:bg-blue-700 text-white hover:border-transparent',
    secondary: 'bg-white hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-800 dark:text-white',
    tertiary: 'bg-transparent text-blue-600 hover:bg-white dark:hover:bg-gray-800',
    success: 'bg-green-500 hover:bg-green-600 text-white',
    mute: 'bg-gray-500 hover:bg-green-700 text-white',
    warning: 'bg-red-500 hover:bg-red-700 text-white ',
    alert: 'bg-transparent text-red-500 dark:bg-opacity-0 ',
}

const sizeClasses = {
    small: 'p-1 text-xs font-semibold',
    medium: 'px-3 py-2 text-sm font-semibold',
    large: 'p-3 text-base font-semibold',
}

export const colors = Object.keys(colorClasses) as unknown as keyof typeof colorClasses;
export const sizes = Object.keys(sizeClasses) as unknown as keyof typeof sizeClasses;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: keyof typeof colorClasses;
    size?: keyof typeof sizeClasses;
    icon?: React.ReactNode;
    linkTo?: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>);
    loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    color = 'primary',
    size = 'medium',
    value,
    icon,
    className,
    linkTo,
    loading = false,
    disabled,
    ...props
}) => {

    const sharedClasses = classNames(
        className,
        'relative inline-flex items-center disabled:opacity-50 rounded-md',
        'group',
        colorClasses[color],
        sizeClasses[size]
    );

    const spinnerClasses = classNames(
        'absolute inset-0 m-auto'
    );

    const iconClasses = classNames(
        'w-6 h-6 inline-block',
        loading && 'invisible',
    );

    const textClasses = classNames(
        'm-auto',
        value && (icon ? 'hidden md:block ml-0 md:ml-2' : 'block'),
        loading && 'invisible',
    );
    
    return linkTo ? (
        <Link to={linkTo} className={sharedClasses} disabled={disabled || loading} {...props as any}>
            {loading && <LoadingSpinner className={spinnerClasses} />}
            {icon && <span className={iconClasses}>{icon}</span>}
            <span className={textClasses}>{value}</span>
        </Link>
    ) : (
        <button className={sharedClasses} disabled={disabled || loading} {...props}>
            {loading && <LoadingSpinner className={spinnerClasses} />}
            {icon && <span className={iconClasses}>{icon}</span>}
            <span className={textClasses}>{value}</span>
        </button>
    );
};

export default Button;