import React, { useContext, useEffect, useState } from 'react';
import { auth } from '@lib/firebase';
import firebase from 'firebase';
import i18n from '@lib/i18n';
import { TFuncKey } from 'react-i18next';

export function useAuth() {
    return useContext(AuthContext);
}

type AuthContextProps = {
    currentUser: firebase.User | null | undefined,
    authenticationNotification: string | null,
    signup: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
    login: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
    logout: () => Promise<void>,
    sendPasswordResetMail: (email: string) => Promise<void>,
    verifyPasswordResetCode: (code: string) => Promise<string>,
    resetPassword: (code: string, newPassword: string) => Promise<void>,
    verifyEmail: (code: string) => Promise<void>,
    setAuthenticationNotification: (state: string | null) => void,
}

const authenticationFunctions = {
    signup: function (email: string, password: string) {
        return auth.createUserWithEmailAndPassword(email, password);
    },

    login: function (email: string, password: string) {
        return auth.signInWithEmailAndPassword(email, password);
    },

    logout: function () {
        return auth.signOut();
    },

    sendPasswordResetMail: function (email: string) {
        return auth.sendPasswordResetEmail(email);
    },

    verifyPasswordResetCode: function (code: string) {
        return auth.verifyPasswordResetCode(code);
    },

    resetPassword: function (code: string, newPassword: string) {
        return auth.confirmPasswordReset(code, newPassword);
    },

    verifyEmail: function (code: string) {
        return auth.applyActionCode(code);
    },
}

const AuthContext = React.createContext<AuthContextProps>({
    currentUser: null,
    authenticationNotification: null,
    setAuthenticationNotification: (_: string | null) => { },
    ...authenticationFunctions
});

const AuthProvider: React.FC = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const [authenticationNotification, setAuthenticationNotification] = useState<string | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        })

        return unsubscribe
    }, []);

    const _setAuthenticationNotification = function (state: string | null) {
        setAuthenticationNotification(state);
    }

    return (
        <AuthContext.Provider value={{
            currentUser: currentUser,
            authenticationNotification: authenticationNotification,
            setAuthenticationNotification: _setAuthenticationNotification,
            ...authenticationFunctions
        }}>
            {/* Only render children if loading is false */}
            { !loading && children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export function handleError(error: firebase.auth.Error): AuthError | null {
    function t(s: TFuncKey<"translation">, f?: any) {
        return i18n.t(s, f);
    }

    // Error codes for each function can be found here:
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#methods_1
    switch (error.code) {
        // General
        case 'auth/invalid-email':
            return {
                message: t('authentication.erros.invalidEmailAddress'),
                field: 'email',
            }
        case 'auth/user-not-found':
            return {
                message: t('authentication.erros.userNotFound'),
                field: 'email',
            }
        case 'auth/user-disabled':
            return {
                message: t('authentication.erros.userDisabled'),
                field: 'email',
            }
        case 'auth/weak-password':
            return {
                message: t('authentication.erros.weakPassword'),
                field: 'password',
            }

        // Login
        case 'auth/wrong-password':
            return {
                message: t('authentication.erros.wrongPassword'),
                field: 'password',
            }

        // Registration
        case 'auth/email-already-in-use':
            return {
                message: t('authentication.erros.emailAlreadyInUse'),
                field: 'email',
            }

        // Verify reset code & Password reset & Email verification
        case 'auth/expired-action-code':
            return {
                message: t('authentication.erros.expiredActionCode'),
            }
        case 'auth/invalid-action-code':
            return {
                message: t('authentication.erros.invalidActionCode'),
            }

        default:
            return {
                message: t('authentication.erros.otherError'),
            }
    }
}

export interface AuthError {
    message: string;
    field?: 'email' | 'password';
}