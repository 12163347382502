import firebase from "firebase";
import { modelSchema } from "./Model";
import RepeatInterval, { repeatIntervalSchema } from "./RepeatInterval";
import { getPercentageChange } from "@lib/helpers";
import { DateTime } from "luxon";
import { z } from "zod";
import { dateTimeSchema, documentReferenceSchema } from "@lib/customSchemas";
import Tag from "./Tag";

export enum ItemUnit {
    piece = 0,
    kilogram = 1,
    ton = 2,
    liter = 3,
    meter = 4,
    kilometer = 5,
    cubicmeter = 6,
    kwh = 7,
}

// Item
export const itemSchema = modelSchema.extend({
    name: z.string()
        .catch("-"),
    tags: z.array(documentReferenceSchema())
        .catch([]),
    unit: z.number()
        .min(0)
        .max(7)
        .catch(0),
    price: z.number()
        .catch(0),
    quantity: z.number()
        .positive()
        .catch(1),
    date: dateTimeSchema(),
    repeatInterval: repeatIntervalSchema
        .nullable()
        .catch(null),
    importHash: z.number()
        .nullish()
        .catch(null),
})

type Item = z.infer<typeof itemSchema>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace Item {
    export function lookupReferencedTags(item: Item, availableTags: Tag[]) {
        return availableTags.filter(tag => 
            item.tags.filter(groupTag => groupTag.id === tag.id).length > 0
        )
    }

    // Convert the 
    type ItemConverterType = object & { date?: DateTime | undefined }
    export class FirestoreConverter implements firebase.firestore.FirestoreDataConverter<ItemConverterType> {
        toFirestore(modelObject: ItemConverterType): firebase.firestore.DocumentData {
            return { 
                ...modelObject,
                date: modelObject.date?.toJSDate(),
            }
        }
        fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>, options: firebase.firestore.SnapshotOptions): ItemConverterType {
            const snapshotData = snapshot.data();
            
            try {
                return {
                    ...snapshotData,
                    date: DateTime.fromJSDate(snapshotData.date.toDate()),
                }
            }
            catch (e) {
                return {
                    ...snapshotData,
                    date: undefined,
                }
            }
        }        
    }
}

export default Item;

// Item Collection
export const itemCollectionSchema = z.object({
    items: z.array(itemSchema),
});

export type ItemCollection = z.infer<typeof itemCollectionSchema> & {
    getItemsWithTags(tags: Tag[]): Item[];
    getItemMetadata(): ReturnType<typeof ItemCollection.getItemCollectionMetadata>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ItemCollection {
    export function lookupItemsWithTags(items: Item[], tags: Tag[]) {
        return items.filter(x => 
                x.tags.some(tagReference => 
                    tags.map(tag => tag.id)
                        .includes(tagReference.id)
                )
            )
    }

    export function getItemCollectionMetadata(items: Item[]) {
        let itemsWithRepetitions: [Item, DateTime][] = [];
        const now = DateTime.now();
        for (const item of items) {
            itemsWithRepetitions.push([item, item.date]);

            if (item.repeatInterval)
                itemsWithRepetitions.push(...RepeatInterval.getRepetitionsBetweenDates(item.repeatInterval, item.date, now).map(repeatitionDate => [item, repeatitionDate] as [Item, DateTime]));
        }
        itemsWithRepetitions.sort((l, r) => l[1].toMillis() - r[1].toMillis());

        let increasedCosts = null;
        if (itemsWithRepetitions.length >= 2) {
            const currentPrice = itemsWithRepetitions[itemsWithRepetitions.length - 1][0].price;
            const previousPrice = itemsWithRepetitions[itemsWithRepetitions.length - 2][0].price;

            increasedCosts = getPercentageChange(previousPrice, currentPrice);
        }

        return {
            totalCost: itemsWithRepetitions.reduce((prev, current) => prev + current[0].price, 0),
            increasedCosts,
            count: itemsWithRepetitions.length,
        }
    }
}


// Item Group
export class ItemGroup {
    name: string;
    tags: firebase.firestore.DocumentReference[];
    items: Item[];
    itemsCount: number;

    constructor(
        name: string,
        tags: firebase.firestore.DocumentReference[],
        items: Item[],
        itemsCount: number,
    ) {
        this.name = name;
        this.tags = tags;
        this.items = items;
        this.itemsCount = itemsCount;
    }

    public lookupReferencedTags(availableTags: Tag[]) {
        return availableTags.filter(tag => 
            this.tags.filter(groupTag => groupTag.id === tag.id).length > 0
        )
    }
}