const routes = {
    authentication: {
        login: { template: '/app/login', build: () => `/app/login` },
        registration: { template: '/app/register', build: () => `/app/register` },
        forgot: { template: '/app/forgot', build: () => `/app/forgot` },
        reset: { template: '/app/reset', build: () => `/app/reset` },
        verify: { template: '/app/verify', build: () => `/app/verify` },
        recover: { template: '/app/recover', build: () => `/app/recover` },
    },
    app: {
        overview: { template: '/app/overview', build: () => `/app/overview` },
        groupsOverview: { template: '/app/groups', build: () => `/app/groups` },
        tagsOverview: { template: '/app/tags', build: () => `/app/tags` },
        itemsOverview: { template: '/app/items', build: () => `/app/items` },

        groupDetails: { template: '/app/groups/:groupId', build: (groupId: string) => `/app/groups/${groupId}` },
        tagDetails: { template: '/app/tags/:tagId', build: (tagId: string) => `/app/tags/${tagId}` },
        itemGroupDetails: { template: '/app/items/:itemName', build: (itemGroupName: string) => `/app/items/${encodeURIComponent(itemGroupName)}` },
    },
    homepage: {
        privacyPolicy: { template: '/privacy-policy', build: () => `/privacy-policy` },
        termsOfService: { template: '/terms-of-service', build: () => `/terms-of-service` },
    }
}

export default routes;