import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M15.8979 7.70457C16.047 7.90055 16.0321 8.18149 15.8531 8.36048L12.5533 11.6603L12.5021 11.7051C12.3061 11.8542 12.0252 11.8393 11.8462 11.6603L11.8014 11.6091C11.6523 11.4131 11.6672 11.1322 11.8462 10.9532L14.2994 8.49998H10.9603C10.96 8.49998 10.9598 8.49999 10.9596 8.49999L5.04 8.49998L1.70017 8.49999L4.15338 10.9532C4.33237 11.1322 4.34729 11.4131 4.19813 11.6091L4.15338 11.6603C3.97439 11.8393 3.69346 11.8542 3.49748 11.7051L3.44628 11.6603L0.146447 8.36048C-0.0325436 8.18149 -0.0474596 7.90055 0.101699 7.70457L0.146447 7.65337L3.44628 4.35354C3.64154 4.15828 3.95812 4.15828 4.15338 4.35354C4.33237 4.53253 4.34729 4.81346 4.19813 5.00944L4.15338 5.06065L1.71405 7.49999H5.03915C5.03943 7.49998 5.03972 7.49998 5.04 7.49998H14.2855L11.8462 5.06065L11.8014 5.00944C11.6523 4.81346 11.6672 4.53253 11.8462 4.35354C12.0414 4.15828 12.358 4.15828 12.5533 4.35354L15.8531 7.65337L15.8979 7.70457Z"/>
    </svg>
  );
}

export default Icon;