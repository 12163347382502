import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
  <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM4.31313 8.36048C4.13414 8.18149 4.11923 7.90055 4.26839 7.70457L4.31313 7.65337L7.61297 4.35354C7.80823 4.15828 8.12481 4.15828 8.32007 4.35354C8.49906 4.53253 8.51398 4.81346 8.36482 5.00944L8.32007 5.06065L5.88073 7.49998H11.1262C11.4024 7.49998 11.6262 7.72384 11.6262 7.99998C11.6262 8.27613 11.4024 8.49998 11.1262 8.49998H5.86685L8.32007 10.9532C8.49906 11.1322 8.51398 11.4131 8.36482 11.6091L8.32007 11.6603C8.14108 11.8393 7.86015 11.8542 7.66417 11.7051L7.61297 11.6603L4.31313 8.36048Z"/>
</svg>
  );
}

export default Icon;
