import OverviewPage, { OverviewFilter } from "@app/overview/OverviewPage";
import { useItemGroups } from "@modules/app/hooks/useItems";

const ItemsOverviewPage: React.FC = () => {

    const itemGroups = useItemGroups();

    return <OverviewPage itemGroups={itemGroups} currentFilter={OverviewFilter.Items} />
}

export default ItemsOverviewPage;