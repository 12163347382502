import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 2C0.223858 2 0 2.22386 0 2.5V13.5C0 13.7761 0.223858 14 0.5 14C0.776142 14 1 13.7761 1 13.5V2.5C1 2.22386 0.776142 2 0.5 2ZM3.14645 8.36054C3.04794 8.26203 2.99913 8.13265 3.00001 8.00354L3 8.00006C3 7.83675 3.0783 7.69172 3.19941 7.60047L6.44628 4.3536C6.64154 4.15834 6.95812 4.15834 7.15338 4.3536C7.33238 4.53259 7.34729 4.81352 7.19813 5.0095L7.15338 5.06071L4.71403 7.50006H13.5C13.7761 7.50006 14 7.72392 14 8.00006C14 8.2762 13.7761 8.50006 13.5 8.50006H4.70018L7.15338 10.9533C7.33238 11.1323 7.34729 11.4132 7.19813 11.6092L7.15338 11.6604C6.97439 11.8394 6.69346 11.8543 6.49748 11.7051L6.44628 11.6604L3.14645 8.36054Z"/>
    </svg>
  );
}

export default Icon;