import { Label, Textfield} from '@ui';
import Popover, { PopoverProps, PopoverSection } from '@app/popover/Popover';
import { useTranslation } from "react-i18next";

export interface UserSettingsFormProps {
}

const UserSettingsDialog: React.FC<UserSettingsFormProps & PopoverProps> = ({ onClose, ...PopoverProps }) => {
    const { t } = useTranslation();
    
    return (
        <Popover title={t('app.shortcutsOverview.keyboardShortcuts')} {...PopoverProps} onClose={onClose}>
            <PopoverSection>
                <Label value={t('app.label.addNewItem')} disabled>
                    <Textfield value="n" readOnly />
                </Label>
                <Label value={t('app.label.addNewGroup')} disabled>
                    <Textfield value="g" readOnly  />
                </Label>
                <Label value={t('app.label.hideShowLeftSidebar')} disabled>
                    <Textfield value="l" readOnly />
                </Label>
                <Label value={t('app.label.hideShowRightSidebar')} disabled>
                    <Textfield value="r" readOnly />
                </Label>
                <Label value={t('app.shortcutsOverview.openShortcuts')} disabled>
                    <Textfield value="k" readOnly />
                </Label>
                <Label value={t('app.dialog.import.title')} disabled>
                    <Textfield value="i" disabled readOnly />
                </Label>
                <Label value={t('app.shortcutsOverview.showAll')} disabled>
                    <Textfield value="1" readOnly />
                </Label>
                <Label value={t('app.shortcutsOverview.showGroups')} disabled>
                    <Textfield value="2" readOnly />
                </Label>
                <Label value={t('app.shortcutsOverview.showItems')} disabled>
                    <Textfield value="3" readOnly />
                </Label>
                <Label value={t('app.shortcutsOverview.showTags')} disabled>
                    <Textfield value="4" readOnly />
                </Label>
                <Label value={t('app.label.backToOverview')} disabled>
                    <Textfield value="o" readOnly />
                </Label>
                <Label value={t('app.label.search')} disabled>
                    <Textfield value="s" readOnly />
                </Label>
                <Label value={t('app.label.saveDialog')}  disabled>
                    <Textfield value="Enter" readOnly />
                </Label>
                <Label value={t('app.label.closeDialog')} disabled>
                    <Textfield value="ESC" readOnly />
                </Label>
            </PopoverSection>
        </Popover>
    )
};

export default UserSettingsDialog;