import { useContext, useEffect, useReducer, createContext, useMemo } from 'react';
import firebase from 'firebase';
import { database, firestore } from '@lib/firebase';
import { useAuth } from '@modules/authentication/AuthProvider';
import Group, { GroupCollection, groupSchema } from '@models/Group';
import React from 'react';
import Tag from '@models/Tag';
    
type Action =
    | { type: "set-loading"; }
    | { type: "set-group"; groups: Group[] }

function reducer (state: GroupCollection | undefined, action: Action): GroupCollection | undefined {
    switch (action.type) {
        case 'set-loading':
            return undefined
        case 'set-group':
            return {
                ...state,
                groups: action.groups,
            }
        default:
            return state;
    }
}

const GroupsContext = createContext<GroupCollection | undefined>(undefined);

export const GroupsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, undefined);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser)
            return;

        return database.groups
            .where('ownerUid', '==', currentUser.uid)
            .onSnapshot(snapshot => {
                console.log('Updating Groups');

                const groups = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    try {
                        return groupSchema.parse({ ...docData, ref: doc.ref }) as Group;
                    }
                    catch (e) {
                        console.error(e, { originalObject: docData })
                        return null;
                    }
                }).filter((group): group is Group => group !== null) 

                dispatch({
                    type: 'set-group',
                    groups
                })
            })
    }, [currentUser]);

    return (
        <GroupsContext.Provider value={state}>
            {children}
        </GroupsContext.Provider>
    )
}

export function useGroupsOpt() {
    return useContext(GroupsContext);
}

export function useGroups() {
    return useGroupsOpt()!;
}

export function useGroup(groupId?: string) {
    const { groups } = useGroups();

    const group = useMemo(() => {
        return groups.find(group => group.id === groupId);
    }, [groups, groupId]);
    
    return group;
}

export function createGroup(
    existingGroupReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    userId: string,
    name: string,
    tagNames: string[],
    availableTags: Tag[],
) {
    const matchingTags = availableTags.filter(tag => tagNames.includes(tag.name));
    const newTagNames = tagNames.filter(tag => !availableTags.map(existingTag => existingTag.name).includes(tag));

    const existingTagReferences = matchingTags.map(tag => database.tags.doc(tag.id));
    const newTagReferences: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[] = [];

    const batch = firestore.batch();
    for (const newTagName of newTagNames) {
        const newTagReference = database.tags.doc();

        batch.set(newTagReference, {
            id: newTagReference.id,
            ownerUid: userId,
            name: newTagName,
        } as Tag);

        newTagReferences.push(newTagReference);
    }

    const documentRef = database.groups.doc(existingGroupReference?.id);
    batch.set(documentRef, {
        id: documentRef.id,
        ownerUid: userId,
        name: name,
        tags: [...existingTagReferences, ...newTagReferences],
        itemGroups: [],
    } as Group);

    return batch.commit()
}

export function deleteGroup(group: Group) {
    const documentRef = database.groups.doc(group.id);

    return documentRef.delete()
}