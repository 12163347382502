import { Link, Button, Tag as TagLabel, Textfield, Table, TableHeader, TableColumn, TableCell, TableRow, Pill, PillItem } from '@ui';
import { useHistory } from 'react-router-dom';
import { useTags } from '@app/hooks/useTags';
import AddGroupDialog from "@app/addGroupDialog/AddGroupDialog";
import AddItemDialog from "@app/addItemDialog/AddItemDialog";
import { useMemo, useState } from "react";
import Item from "@models/Item";
import { ArrowLeft, Plus } from '@icons';
import Page from '../../components/page/Page';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut';
import StatisticsPanel from './StatisticsPanel';
import GroupsListPanel from './GroupsListPanel';
import { useTranslation } from "react-i18next";
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import getDetailsChartData, { DetailsChartMode, DetailsChartTimeGroup } from './useDetailsChartData';
import { dateTimeToDateString, quantityToString } from '@lib/stringConversion';
import { currencyToString } from '@lib/stringConversion';
import Tag from '@models/Tag';
import Group from '@models/Group';
import routes from '@modules/routes/Routes';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { TableSortOrder } from '@ui/Table/Table';
import { advancedCompare } from '@lib/helpers';
import { DetailsChartMeasure } from './useDetailsChartData';

export interface DetailsPageProps {
    items: Item[];
    tags: Tag[];
    name: string;
    group?: Group;
}

enum ItemsTableColumn {
    name = 'name',
    date = 'date',
    tags = 'tags',
    quantity = 'quantity',
    price = 'price',
}

const DetailsPage: React.FC<DetailsPageProps> = ({ items, tags, name, group, ...props }) => {

    const { t } = useTranslation();

    const history = useHistory();

    const [itemToEdit, setItemToEdit] = useState<Item | null>(null);
    const [showAddGroupDialog, setShowAddGroupDialog] = useState<boolean>(false);
    const [showAddItemDialog, setShowAddItemDialog] = useState<boolean>(false);
    const [sortedTableColumn, setSortedTableColumn] = useState({ column: ItemsTableColumn.date, order: TableSortOrder.descending });

    const [chartMode, setChartMode] = useState<string>('default');
    const [chartTimeGroup, setChartTimeGroup] = useState<string>('none');
    const [chartMeasure, setChartMeasure] = useState<string>('none');

    const globalTagCollection = useTags();

    const userSettings = useUserSettings();

    const isShowingDialog = useMemo(() => {
        return showAddGroupDialog || showAddItemDialog;
    }, [showAddGroupDialog, showAddItemDialog]);

    useKeyboardShortcut("n", () => {
        if (!isShowingDialog)
            setShowAddItemDialog(true);
    });

    useKeyboardShortcut("o", () => {
        history.push(routes.app.overview.build());
    });

    let detailsChartTimeGroup: DetailsChartTimeGroup | undefined;
    let detailsChartTimeUnit: 'day' | 'month' | 'year' = 'day';
    let detailsChartTooltipFormat = 'dd.MM.yyyy';
    switch (chartTimeGroup) {
        case 'month':
            detailsChartTimeGroup = 'month';
            detailsChartTimeUnit = 'month';
            detailsChartTooltipFormat = 'MM.yyyy';
            break;
        case 'year':
            detailsChartTimeGroup = 'year';
            detailsChartTimeUnit = 'year';
            detailsChartTooltipFormat = 'yyyy';
            break;
    }

    const chartSeries = useMemo(() => {
        const chartPointColors = [ 'rgb(59, 130, 246)', 'rgb(1, 255, 1)', 'rgb(255, 1, 1)' ];

        let detailsChartMode: DetailsChartMode;
        switch (chartMode) {
            case 'running-total':
                detailsChartMode = { mode: 'running-total', itemsInGroup: items, tagsInGroup: tags };
                break;
            default:
                detailsChartMode = { mode: 'default', itemsInGroup: items, tagsInGroup: tags };
                break;
        }

        let detailsChartMeasure: DetailsChartMeasure;
        switch (chartMeasure) {
            case 'quantity':
                detailsChartMeasure = DetailsChartMeasure.quantity;
                break;
            default:
                detailsChartMeasure = DetailsChartMeasure.price
                break;
        }

        return {
            datasets: getDetailsChartData(detailsChartMode, detailsChartMeasure, detailsChartTimeGroup)
                .map(({ ...props }, i) => ({
                    ...props,
                    // Chart Dataset configurations 
                    borderColor: 'rgba(120, 120, 120, 0.75)',
                    borderWidth: 4,
                    tension: 0.25,
                    pointBackgroundColor: chartPointColors[i % chartPointColors.length],
                    pointBorderColor: chartPointColors[i % chartPointColors.length],
                    pointBorderWidth: 8,
                    pointHitRadius: 8,
                    fill: 'start',
                }))
        }
    }, [items, tags, chartMode, chartMeasure, detailsChartTimeGroup]);

    function handleGroupDelete() {
        history.push("/");
    }

    const sortedItems = useMemo(() => {
        if (sortedTableColumn.order === TableSortOrder.none)
            return items;

        // Copy the items as Array.sort is mutational
        const copiedItems = [...items];

        copiedItems.sort((l, r) => {
            let ordering = advancedCompare(l[sortedTableColumn.column], r[sortedTableColumn.column]);
            
            if (sortedTableColumn.order === TableSortOrder.descending)
                ordering *= -1;

            return ordering;
        });

        return copiedItems;
    }, [items, sortedTableColumn]);

    return (
        <Page
            leftSidebar={<GroupsListPanel selectedGroup={group} />}
            rightSidebar={<StatisticsPanel items={items ?? []} tags={tags ?? []} />}>

            <header className="flex items-center justify-between">
                <Button color="tertiary" className='mr-2' icon={<ArrowLeft color='blue' />} linkTo={routes.app.overview.build()} title="Back to the overview" />
                <Textfield textStyle="LargeHeader" focusOnly value={name} className="flex-grow bg-opacity-0 focus:bg-opacity-0 p-0 md:p-0" onChange={(e) => undefined} />
                <Button color="primary" value={t('app.button.newItem')} icon={<Plus color="white" />} className="ml-4 flex-none" onClick={() => setShowAddItemDialog(true)} />
            </header>


            <div className="hidden sm:block p-3">
                <div>
                    {tags.map(tag => (
                        <TagLabel key={tag.id} color="action" value={tag.name} className="mr-2" linkTo={routes.app.tagDetails.build(tag.id)} />
                    ))}
                </div>
            </div>

            {chartSeries && chartSeries.datasets.length > 0 && (
                <div className="p-0">
                    <Line options={{
                        responsive: true,
                        aspectRatio: 3,
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: detailsChartTimeUnit,
                                    tooltipFormat: detailsChartTooltipFormat,
                                },
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    color: 'rgb(144, 144, 144)',
                                    font: {
                                        size: 14,
                                        style: 'normal'
                                    }
                                }
                            },
                            y: {
                                display: true,
                                grid: {
                                    color: 'rgba(144,144,144,0.5)',
                                },
                                ticks: {
                                    color: 'rgb(144, 144, 144)',
                                    font: {
                                        size: 14,
                                        style: 'normal'
                                    }
                                }
                            }
                        }
                    }} data={chartSeries} />
                    
                    <div className="grid grid-cols-1 gap-0 mb-0 mt-2 md:mb-6">
                        <div className="text-center">
                            <Pill onValueChange={value => setChartMeasure(value)} defaultValue="price">
                                <PillItem value="price">Preis</PillItem>
                                <PillItem value="quantity">Anzahl</PillItem>
                            </Pill>
                            <span className="mx-2 text-black dark:text-white "> </span>
                            <Pill onValueChange={value => setChartMode(value)} defaultValue="default">
                                <PillItem value="default">Einzelne Werte</PillItem>
                                <PillItem value="running-total">Kumulierte Werte</PillItem>
                            </Pill>
                            <span className="mx-2 text-black dark:text-white "> </span>
                            <Pill onValueChange={value => setChartTimeGroup(value)} defaultValue="day">
                                <PillItem value="day">Wochen</PillItem>
                                <PillItem value="month">Monaten</PillItem>
                                <PillItem value="year">Jahre</PillItem>
                            </Pill>
                        </div>
                    </div>
                </div>
            )}
            <div className="hidden sm:block">
                <Table className="w-full">
                    <TableHeader>
                        <TableColumn 
                            onSort={(order) => setSortedTableColumn({ column: ItemsTableColumn.name, order })} 
                            isSorted={sortedTableColumn.column === ItemsTableColumn.name ? sortedTableColumn.order : undefined}>
                                {t('app.label.itemName')}
                        </TableColumn>
                        <TableColumn 
                            onSort={(order) => setSortedTableColumn({ column: ItemsTableColumn.date, order })} 
                            isSorted={sortedTableColumn.column === ItemsTableColumn.date ? sortedTableColumn.order : undefined}>
                                {t('app.label.date')}
                        </TableColumn>
                        <TableColumn 
                            onSort={(order) => setSortedTableColumn({ column: ItemsTableColumn.tags, order })} 
                            isSorted={sortedTableColumn.column === ItemsTableColumn.tags ? sortedTableColumn.order : undefined}>
                                {t('app.label.tags')}
                        </TableColumn>
                        <TableColumn
                            className="text-right" 
                            onSort={(order) => setSortedTableColumn({ column: ItemsTableColumn.quantity, order })} 
                            isSorted={sortedTableColumn.column === ItemsTableColumn.quantity ? sortedTableColumn.order : undefined}>
                                {t('app.label.quantity')}
                        </TableColumn>
                        <TableColumn
                            className="text-right" 
                            onSort={(order) => setSortedTableColumn({ column: ItemsTableColumn.price, order })} 
                            isSorted={sortedTableColumn.column === ItemsTableColumn.price ? sortedTableColumn.order : undefined}>
                                {t('app.label.price')}
                        </TableColumn>
                    </TableHeader>
                    <tbody>
                        {sortedItems.map((item, i) => (
                            <TableRow key={i}>
                                <TableCell><Link to="#" onClick={() => { setItemToEdit(item); setShowAddItemDialog(true); }}>{item.name}</Link></TableCell>
                                <TableCell>{dateTimeToDateString(item.date)}</TableCell>
                                <TableCell>{Item.lookupReferencedTags(item, globalTagCollection.tags ?? []).map((tag, i) => (<TagLabel key={i} color='action' value={tag.name} size='small' className="mr-2" linkTo={routes.app.tagDetails.build(tag.id)} />))}</TableCell>
                                <TableCell className="text-right">{quantityToString(item.quantity, t, item.unit)}</TableCell>
                                <TableCell className="text-right p-2">{currencyToString(item.price, userSettings.currency)}</TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </div>

            {/* Portals */}
            {group && showAddGroupDialog &&
                <AddGroupDialog
                    editGroup={group}
                    availableTags={globalTagCollection.tags}
                    onClose={() => setShowAddGroupDialog(false)}
                    onDelete={() => handleGroupDelete()}
                />
            }

            {showAddItemDialog &&
                <AddItemDialog
                    editItem={itemToEdit}
                    availableTags={globalTagCollection.tags}
                    onClose={() => { setItemToEdit(null); setShowAddItemDialog(false); }}
                    onDelete={() => handleGroupDelete()}
                />
            }
        </Page>
    )
}

export default DetailsPage;