import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import routes from '@routes';

// import PrivateRoute from '../modules/routes/PrivateRoute';
import AppRoute from '@modules/routes/AppRoute';
import AuthenticationRoute from '@modules/routes/AuthenticationRoute';
import FirebaseRoute from '@modules/routes/FirebaseRoute';

import Login from '@authentication/login/Login';
import Registration from '@authentication/registration/Registration';
import Forgot from '@authentication/forgot/Forgot';
import Reset from '@authentication/reset/Reset';
import Verify from '@authentication/verify/Verify';

import FullOverviewPage from '@pages/app/overview/FullOverviewPage';
import GroupsOverviewPage from '@pages/app/overview/GroupsOverviewPage';
import TagsOverviewPage from '@pages/app/overview/TagsOverviewPage';
import ItemsOverviewPage from '@pages/app/overview/ItemsOverviewPage';

import GroupDetailsPage from '@pages/app/details/GroupDetailsPage';
import TagDetailsPage from '@pages/app/details/TagDetailsPage';

import PrivacyPolicy from '@homepage/privacy-policy/PrivacyPolicy';
import TermsOfService from '@homepage/terms-of-service/TermsOfService';
import DataProviders from './DataProviders';
import ItemsDetailsPage from './app/details/ItemsDetailsPage';
import GlobalEvents from './GlobalEvents';

const Router: React.FC = (() => {
    return (
        <BrowserRouter>
            <DataProviders>
                <GlobalEvents />
                <Switch>
                    {/* Authentication */}
                    <Route exact path="/" render={() => (<Redirect to={routes.app.overview.build()} />)} /> {/* To be changed to link to Homepage root */}
                    <Route exact path="/app" render={() => (<Redirect to={routes.app.overview.build()} />)} />
                    <AuthenticationRoute path={routes.authentication.login.template} component={Login} />
                    <AuthenticationRoute path={routes.authentication.registration.template} component={Registration} />
                    <AuthenticationRoute path={routes.authentication.forgot.template} component={Forgot} />
                    <Route path={routes.authentication.reset.template} component={Reset} />
                    <Route path={routes.authentication.verify.template} component={Verify} />
                    {/* <Route path={routes.authentication.recover.template} component={Recover} /> */}

                    {/* App */}
                    <AppRoute path={routes.app.groupDetails.template} component={GroupDetailsPage} />
                    <AppRoute path={routes.app.tagDetails.template} component={TagDetailsPage} />
                    <AppRoute path={routes.app.itemGroupDetails.template} component={ItemsDetailsPage} />
                    
                    <AppRoute path={routes.app.overview.template} component={FullOverviewPage} />
                    <AppRoute path={routes.app.groupsOverview.template} component={GroupsOverviewPage} />
                    <AppRoute path={routes.app.tagsOverview.template} component={TagsOverviewPage} />
                    <AppRoute path={routes.app.itemsOverview.template} component={ItemsOverviewPage} />

                    {/* Homepage */}
                    <Route path={routes.homepage.privacyPolicy.template} component={PrivacyPolicy} />
                    <Route path={routes.homepage.termsOfService.template} component={TermsOfService} />

                    {/* Firebase mail handler */}
                    <FirebaseRoute path="/auth" />
                </Switch>
            </DataProviders>
        </BrowserRouter>
    );
});

export default Router;