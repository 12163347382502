import { Link as BrowserLink, useHistory } from "react-router-dom"
import { useAuth } from "@authentication/AuthProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Tag as TagLabel, Button, Card, CardHeader, OffscreenContainer, Dropdown, DropdownItem, DropdownSplitter, Paragraph, LargeHeader } from '@ui';
import { deleteTag, useTags } from '@app/hooks/useTags';
import AddGroupDialog from "@app/addGroupDialog/AddGroupDialog";
import NavigationBar from "@components/navigationBar/NavigationBar";
import { Folder, Ellipsis, Plus, Upload } from "@icons";
import AddItemDialog from '@app/addItemDialog/AddItemDialog';
import Page from '@components/page/Page';
import useKeyboardShortcut from "@hooks/useKeyboardShortcut";
import { useTranslation } from "react-i18next";
import Group from '@app/models/Group';
import { ItemCollection, ItemGroup } from "@models/Item";
import Tag from "@models/Tag";
import classNames from "classnames";
import routes from "@modules/routes/Routes";
import { deleteItemGroup, useItems } from '@app/hooks/useItems';
import { currencyToString, percentageToString } from "@lib/stringConversion";
import { useUserSettings } from '@app/hooks/useUserSettings';
import { deleteGroup } from "@app/hooks/useGroups";
import { arrayToDictionary } from "@lib/helpers";
import { useConfirmDialog } from '../confirmDialog/ConfirmDialog';
import FileImportDialog from '../fileImportDialog/FileImportDialog';
import GlobalDropzone from "@modules/components/globalDropzone/GlobalDropzone";
import { useImportedItemHashes } from '../hooks/useItems';
import OnboardingScreen from "@modules/components/onboardingScreen/OnboardingScreen";
import SetupBankAccessDialog from "../setupBankAccessDialog/SetupBankAccessDialog";

export enum OverviewFilter {
    All,
    Groups,
    Items,
    Tags,
}

export interface OverviewPageProps {
    groups?: Group[];
    tags?: Tag[];
    itemGroups?: ItemGroup[];
    currentFilter?: OverviewFilter;
}

const OverviewPage: React.FC<OverviewPageProps> = ({ groups = [], tags = [], itemGroups = [], currentFilter = OverviewFilter.All }) => {
    const { authenticationNotification, setAuthenticationNotification } = useAuth();
    const history = useHistory();

    const { t } = useTranslation();

    const [confirmDialog, showConfirmDialog] = useConfirmDialog();

    const [currentAuthenticationNotification, setCurrentAuthenticationNotification] = useState<string | null>(null);
    const [showAddGroupDialog, setShowAddGroupDialog] = useState<boolean>(false);
    const [showAddItemDialog, setShowAddItemDialog] = useState<boolean>(false);
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const [showSetupBankAccessDialog, setShowSetupBankAccessDialog] = useState<boolean>(false);
    const [shouldEditGroup, setShouldEditGroup] = useState<Group | null>(null);

    const [importDialogFiles, setImportDialogFiles] = useState<FileList | null>(null);

    const [showDropdownForGroup, setShowDropdownForGroup] = useState<Group | null>(null);
    const [showDropdownForTag, setShowDropdownForTag] = useState<Tag | null>(null);
    const [showDropdownForItemGroup, setShowDropdownForItemGroup] = useState<ItemGroup | null>(null);

    const globalTagCollection = useTags();
    const globalItemCollection = useItems();
    const importedItemHashes = useImportedItemHashes();
    const userSettings = useUserSettings();

    // console.log({ groups, tags, itemGroups, globalTagCollection });

    const isShowingDialog = useMemo(() => {
        return showAddGroupDialog || showAddItemDialog;
    }, [showAddGroupDialog, showAddItemDialog]);

    useKeyboardShortcut("g", () => {
        if (!isShowingDialog)
            setShowAddGroupDialog(true);
    });

    useKeyboardShortcut("n", () => {
        if (!isShowingDialog)
            setShowAddItemDialog(true);
    });

    useKeyboardShortcut("i", () => {
        if (!isShowingDialog)
        setShowImportDialog(true);
    });

    useKeyboardShortcut("1", () => {
        history.push(routes.app.overview.build());
    });

    useKeyboardShortcut("2", () => {
        history.push(routes.app.groupsOverview.build());
    });

    useKeyboardShortcut("3", () => {
        history.push(routes.app.itemsOverview.build());
    });

    useKeyboardShortcut("4", () => {
        history.push(routes.app.tagsOverview.build());
    });

    // Show a banner if the user just verified his email and dismiss the property
    useEffect(() => {
        if (authenticationNotification) {
            setCurrentAuthenticationNotification(authenticationNotification);
            setAuthenticationNotification(null);
        }
    }, [authenticationNotification, setAuthenticationNotification]);

    function navigateToGroup(group: Group) {
        history.push(routes.app.groupDetails.build(group.id))
    }

    function navigateToTag(tag: Tag) {
        history.push(routes.app.tagDetails.build(tag.id))
    }

    function navigateToItemGroup(itemGroup: ItemGroup) {
        history.push(routes.app.itemGroupDetails.build(itemGroup.name))
    }

    function openAddGroupDialog(editItem?: Group) {
        setShowAddGroupDialog(true);
        setShouldEditGroup(editItem ?? null);
    }

    const groupsItemsMetadata = useMemo(() => {
        return arrayToDictionary(groups, group => group.id, group => {
            const groupTags = Group.lookupReferencedTags(group, globalTagCollection.tags);
            const itemsInGroup = globalItemCollection.getItemsWithTags(groupTags);

            return ItemCollection.getItemCollectionMetadata(itemsInGroup);
        })
    }, [groups, globalTagCollection, globalItemCollection])

    const tagsItemsMetadata = useMemo(() => {
        return arrayToDictionary(globalTagCollection.tags, tag => tag.id, tag => {
            const itemsWithTag = globalItemCollection.getItemsWithTags([tag]);

            return ItemCollection.getItemCollectionMetadata(itemsWithTag);
        })
    }, [globalTagCollection.tags, globalItemCollection])

    const itemGroupsMetadata = useMemo(() => {
        return arrayToDictionary(itemGroups, itemGroup => itemGroup.name, itemGroup => {
            return ItemCollection.getItemCollectionMetadata(itemGroup.items);
        })
    }, [itemGroups])

    const openImportDialog = useCallback((files?: FileList) => {
        setImportDialogFiles(files ?? null);
        setShowImportDialog(true);
    }, [])


    const openSetupBankAccessDialog = useCallback(() => {
        setShowSetupBankAccessDialog(true);
    }, [])

    return (
        <Page navigationBar={
            <NavigationBar leftItems={(
                <>
                    <Button color="tertiary" className="mr-2" icon={<Folder color="blue" size="medium" />} title={t('app.button.newGroup')} onClick={() => setShowAddGroupDialog(true)} />
                    <Button color="primary" className="inline-flex mr-2" icon={<Plus color="white" size="medium" />} value={t('app.button.newItem')} onClick={() => setShowAddItemDialog(true)} />
                    <Button color="tertiary" icon={<Upload color="blue" size="medium" />} title="CSV import" onClick={() => openImportDialog()} />
                    <Button color="tertiary" value={"Connect Bank Account"} title="Connect Bank" onClick={() => openSetupBankAccessDialog()} />
                </>
            )} />
        }>

            {currentAuthenticationNotification && (
                //@CHANGEME add banner styles
                <p className="bg-green-500 rounded-sm mb-4 p-4">{currentAuthenticationNotification}</p>
            )}

            <div className="flex flex-1 flex-wrap">
                <div className="w-full">
                    {(groups.length > 0 || tags.length > 0 || itemGroups.length > 0) ? (<>
                        <header className="w-full flex items-center justify-left">
                            <LargeHeader className="mb-4">
                                <BrowserLink to={routes.app.overview.build()} className={classNames('m-2', currentFilter === OverviewFilter.All && 'underline')}>{t('app.label.all')}</BrowserLink>
                                /
                                <BrowserLink to={routes.app.groupsOverview.build()} className={classNames('m-2', currentFilter === OverviewFilter.Groups && 'underline')}>{t('app.label.groups')}</BrowserLink>
                                /
                                <BrowserLink to={routes.app.itemsOverview.build()} className={classNames('m-2', currentFilter === OverviewFilter.Items && 'underline')}>{t('app.label.items')}</BrowserLink>
                                /
                                <BrowserLink to={routes.app.tagsOverview.build()} className={classNames('m-2', currentFilter === OverviewFilter.Tags && 'underline')}>{t('app.label.tags')}</BrowserLink>
                            </LargeHeader>
                        </header>
                        <div className="p-0 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3 relative">
                            {/* Group Cards */}
                            {groups.map(group => {
                                const itemsMetadata = groupsItemsMetadata[group.id];

                                return (
                                    <Card key={group.id} onClick={e => navigateToGroup(group)} className="group" size="medium">
                                        <OffscreenContainer className="lg:hidden lg:group-hover:block absolute right-0 top-0 z-9">
                                            <Button color="tertiary" icon={<Ellipsis />} onClick={e => { setShowDropdownForGroup(group); e.stopPropagation(); }} />
                                            {showDropdownForGroup === group && (
                                                <Dropdown onClose={() => setShowDropdownForGroup(null)}>
                                                    <DropdownItem onClick={e => { navigateToGroup(group) }}>{t('app.button.open')}</DropdownItem>
                                                    <DropdownItem onClick={e => { openAddGroupDialog(group) }}>{t('app.button.edit')}</DropdownItem>
                                                    <DropdownSplitter />
                                                    <DropdownItem onClick={e => { showConfirmDialog({ 
                                                        title: t('app.overview.deleteGroupConfirmation'), 
                                                        description: t('app.overview.deleteConfirmationDescription'), 
                                                        okLabel: t('app.button.delete'),
                                                        okButtonColor: 'warning',
                                                        onOk: () => deleteGroup(group)},
                                                    )}}>{t('app.button.delete')}</DropdownItem>
                                                </Dropdown>
                                            )}
                                        </OffscreenContainer>
                                        
                                        <div className="text-xs font-semibold">{group.name}</div>
                                        {itemsMetadata.count > 0 && (
                                            <div className="flex w-full">
                                                <div className="py-1 mr-2 text-3xl font-bold">{currencyToString(itemsMetadata.totalCost, userSettings.currency)}</div>
                                                {itemsMetadata.increasedCosts ? 
                                                    <TagLabel size="small" color={itemsMetadata.increasedCosts <= 0 ? 'success' : 'danger'} value={(itemsMetadata.increasedCosts > 0 ? '+' : '') + percentageToString(itemsMetadata.increasedCosts, t)} /> : <></>}
                                            </div>
                                        )}
                                        <div className="mb-16">
                                            {
                                                Group.lookupReferencedTags(group, globalTagCollection.tags).map(tag => (
                                                    <TagLabel key={tag.id} size="small" value={tag.name} className="mr-2 mb-2" />
                                                ))
                                            }
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div>
                                                <div className="text-lg font-semibold">{itemsMetadata.count}</div>
                                                <div className="text-base font-semibold opacity-60">Atrikel</div>
                                            </div>
                                            <div>
                                                <div className="text-lg font-semibold">12.01.2001</div>
                                                <div className="text-base font-semibold opacity-60">Seit</div>
                                            </div>
                                            <div>
                                                <div className="text-lg font-semibold">15.02.2022</div>
                                                <div className="text-base font-semibold opacity-60">Letztes</div>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })}

                            {/* Tag Cards */}
                            {tags.map(tag => {
                                const itemsMetadata = tagsItemsMetadata[tag.id];

                                return (
                                    <Card key={tag.id} onClick={e => navigateToTag(tag)} className="group">
                                    <OffscreenContainer className="lg:hidden lg:group-hover:block absolute right-0 top-0 z-9">
                                        <Button color="tertiary" className="px-2" icon={<Ellipsis />} onClick={e => { setShowDropdownForTag(tag); e.stopPropagation(); }} />
                                        {showDropdownForTag === tag && (
                                            <Dropdown onClose={() => setShowDropdownForTag(null)}>
                                                <DropdownItem onClick={e => { navigateToTag(tag) }}>{t('app.button.open')}</DropdownItem>
                                                <DropdownItem onClick={e => { /* @TODO */ }}>{t('app.button.edit')}</DropdownItem>
                                                <DropdownSplitter />
                                                <DropdownItem onClick={e => { showConfirmDialog({ 
                                                    title: t('app.overview.deleteTagConfirmation'), 
                                                    description: t('app.overview.deleteConfirmationDescription'), 
                                                    okLabel: t('app.button.delete'),
                                                    okButtonColor: 'warning',
                                                    onOk: () => deleteTag(tag)},
                                                )}}>{t('app.button.delete')}</DropdownItem>
                                            </Dropdown>
                                        )}
                                    </OffscreenContainer>

                                    <div className="rounded-2xl text-xs font-semibold dark:bg-white bg-black bg-opacity-10 inline-block px-2 py-0.5 mb-1">{itemsMetadata.count}</div>
                                    
                                    <CardHeader className="mb-2 text-ellipsis truncate overflow-hidden">{tag.name}</CardHeader>

                                    {itemsMetadata.count > 0 && (
                                        <div className="flex w-full mb-3">
                                            <Paragraph className="py-1 mr-2">{currencyToString(itemsMetadata.totalCost, userSettings.currency)}</Paragraph>
                                            {itemsMetadata.increasedCosts ? <TagLabel size="small" color={itemsMetadata.increasedCosts <= 0 ? 'success' : 'danger'} value={(itemsMetadata.increasedCosts > 0 ? '+' : '') + percentageToString(itemsMetadata.increasedCosts, t)} /> : <></>}
                                        </div>
                                    )}
                                </Card>
                            )
                        })}

                        {/* ItemGroup Cards */}
                        {itemGroups.map(itemGroup => {
                            const itemsMetadata = itemGroupsMetadata[itemGroup.name];

                            return (
                                <Card key={itemGroup.name} onClick={e => navigateToItemGroup(itemGroup)} className="group">
                                        <OffscreenContainer className="lg:hidden lg:group-hover:block absolute right-0 top-0 z-9">
                                            <Button color="tertiary" className="px-2" icon={<Ellipsis />} onClick={e => { setShowDropdownForItemGroup(itemGroup); e.stopPropagation(); }} />
                                            {showDropdownForItemGroup === itemGroup && (
                                                <Dropdown onClose={() => setShowDropdownForItemGroup(null)}>
                                                    <DropdownItem onClick={e => { /*e.stopPropagation();*/ navigateToItemGroup(itemGroup) }}>{t('app.button.open')}</DropdownItem>
                                                    <DropdownSplitter />
                                                    <DropdownItem onClick={e => { /*e.stopPropagation();*/ deleteItemGroup(itemGroup) }}>{t('app.button.delete')}</DropdownItem>
                                                </Dropdown>
                                            )}
                                        </OffscreenContainer>
                                        <div className="rounded-2xl text-xs font-semibold dark:bg-white bg-black bg-opacity-10 inline-block px-2 py-0.5 mb-1">{itemGroup.itemsCount}</div>
                                       <CardHeader className="mb-2 block max-h-14 text-ellipsis truncate overflow-hidden">{itemGroup.name}</CardHeader>

                                        {itemsMetadata.count > 0 && (
                                            <div className="flex w-full mb-3">
                                                <Paragraph className="py-1 mr-2">{currencyToString(itemsMetadata.totalCost, userSettings.currency)}</Paragraph>
                                                {itemsMetadata.increasedCosts ? <TagLabel size="small" color={itemsMetadata.increasedCosts <= 0 ? 'success' : 'danger'} value={(itemsMetadata.increasedCosts > 0 ? '+' : '') + percentageToString(itemsMetadata.increasedCosts, t)} /> : <></>}
                                            </div>
                                        )}

                                        <div>
                                            {
                                                itemGroup.lookupReferencedTags(globalTagCollection.tags).map(tag => (
                                                    <TagLabel key={tag.id} color="card" value={tag.name} className="mr-2 mb-2" />
                                                ))
                                            }
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                    </>) : (<>
                        <OnboardingScreen />
                    </>)}
                </div>
            </div >

            {/* Portals */}
            {showAddGroupDialog && 
                <AddGroupDialog 
                    editGroup={shouldEditGroup} 
                    onClose={() => { setShowAddGroupDialog(false); setShouldEditGroup(null) }} 
                    availableTags={globalTagCollection.tags ?? []} 
                />}

            {showAddItemDialog && 
                <AddItemDialog 
                    onClose={() => setShowAddItemDialog(false)} 
                    availableTags={globalTagCollection.tags ?? []} 
                />}

            {showImportDialog && 
                <FileImportDialog 
                    onClose={() => setShowImportDialog(false)}
                    availableTags={globalTagCollection.tags ?? []}
                    alreadyImportedHashes={importedItemHashes}
                    files={importDialogFiles ?? undefined}
                />}

            {showSetupBankAccessDialog && 
                <SetupBankAccessDialog 
                    onClose={() => setShowSetupBankAccessDialog(false)}
                />}

            <GlobalDropzone onFilesDrop={files => openImportDialog(files)} />

            {confirmDialog}

        </Page >
    )
}

export default OverviewPage;