import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.messagingSenderId,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app;
export const firestore = app.firestore();
export const auth = app.auth();
export const functions = app.functions();

// Configure the functions emulator if both REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST and REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT are set as environment variables.
if (process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST !== undefined && process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT !== undefined)
    functions.useEmulator(process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST, parseInt(process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT))

export interface IFormattedDocument {
    id: string;
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    data: firebase.firestore.DocumentData;
}

export const database = {
    groups: firestore.collection('groups'),
    items: firestore.collection('items'),
    tags: firestore.collection('tags'),
    userSettings: firestore.collection('userSettings'),
    bankConnections: firestore.collection('plaidConnections'),

    formatDocument: (doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        return {
            id: doc.id,
            ref: doc.ref,
            data: doc.data()
        } as IFormattedDocument
    }
}

export const callableFunctions = {
    transactions: {
        createLinkToken: functions.httpsCallable('createLinkToken'),
        registerPublicToken: functions.httpsCallable('registerPublicToken'),
        syncTransactions: functions.httpsCallable('syncTransactions'),
    }
}

firestore.enablePersistence()
    .then(() => {
        console.log('Offline persistence enabled.');
    })
    .catch(e => {
        console.error('Offline persistence could not be enabled.', e);
    })

// export const analytics = firebase.analytics();

const firestoreBatchLimit = 500;
export function largeBatch() {
    type OperationType = 
    | { type: "delete", documentRef: firebase.firestore.DocumentReference<unknown> }
    | { type: "set", documentRef: firebase.firestore.DocumentReference<any>, data: Partial<any>, options?: firebase.firestore.SetOptions }
    | { type: "update", documentRef: firebase.firestore.DocumentReference<any>, data: firebase.firestore.UpdateData }

    const documentsToBatch: OperationType[] = [];

    const commit = async () => {
        for (let i = 0; i < documentsToBatch.length; i += firestoreBatchLimit) {
            const chunk = documentsToBatch.slice(i, i + firestoreBatchLimit);

            const batch = firestore.batch();

            for (const documentToBatch of chunk) {
                switch (documentToBatch.type) {
                    case 'delete': 
                        batch.delete(documentToBatch.documentRef);
                        break;
                    case 'set': 
                        if (documentToBatch.options)
                            batch.set(documentToBatch.documentRef, documentToBatch.data, documentToBatch.options);
                        else
                            batch.set(documentToBatch.documentRef, documentToBatch.data);
                        break;
                    case 'update': 
                        batch.update(documentToBatch.documentRef, documentToBatch.data);
                        break;
                }
            }

            await batch.commit();

            // onBatchProgressUpdate && onBatchProgressUpdate(i * firestoreBatchLimit + chunk.length, documentsToBatch.length);
        }
    }

    return {
        delete: (documentRef: firebase.firestore.DocumentReference<any>) => documentsToBatch.push({ type: 'delete', documentRef }),
        set: <T>(documentRef: firebase.firestore.DocumentReference<T>, data: Partial<T>, options?: firebase.firestore.SetOptions) => documentsToBatch.push({ type: 'set', documentRef, data, options }),
        update: (documentRef: firebase.firestore.DocumentReference<any>, data: firebase.firestore.UpdateData) => documentsToBatch.push({ type: 'update', documentRef, data }),
        commit,
    }
}