import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M12.3137 2.10051L14.435 4.22183L4.53553 14.1213L2.41421 12L3.12132 11.2929L4.53553 12.7071L5.24264 12L3.82843 10.5858L5.06586 9.34835L6.48008 10.7626L7.18718 10.0555L5.77297 8.64124L7.01041 7.40381L8.42462 8.81802L9.13173 8.11091L7.71751 6.6967L8.95495 5.45926L10.3692 6.87348L11.0763 6.16637L9.66206 4.75216L10.8995 3.51472L12.3137 4.92893L13.0208 4.22183L11.6066 2.80761L12.3137 2.10051ZM11.6066 1.3934C11.9971 1.00287 12.6303 1.00287 13.0208 1.3934L15.1421 3.51472C15.5327 3.90524 15.5327 4.53841 15.1421 4.92893L5.24264 14.8284C4.85212 15.219 4.21895 15.219 3.82843 14.8284L1.70711 12.7071C1.31658 12.3166 1.31658 11.6834 1.70711 11.2929L11.6066 1.3934Z"/>
</svg>
  );
}

export default Icon;
