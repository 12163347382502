import { useTag } from "@app/hooks/useTags";
import { useItems } from "@app/hooks/useItems";
import { Redirect, useParams } from "react-router-dom";
import DetailsPage from "@app/detailsPage/DetailsPage";

interface RouteParams {
    tagId: string;
}

const TagDetailsPage: React.FC = () => {
    const { tagId } = useParams<RouteParams>();

    const tag = useTag(tagId);
    const tagReferences = tag?.ref ? [tag.ref] : [];
    const itemsInGroup = useItems(tagReferences);

    // If the group was not found
    if (!tag) {
        return <Redirect to="/app" />
    }

    return <DetailsPage items={itemsInGroup.items ?? []} tags={[tag]} name={tag.name ?? ''} />
}

export default TagDetailsPage;