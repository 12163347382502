import { Textfield, Link, Tagfield } from '@ui';
import { useAuth } from '@authentication/AuthProvider';
import useFormFields from "@hooks/useFormFields";
import Tag from '@models/Tag';
import Group from '@models/Group';
import Dialog, { DialogProps, DialogSection } from '@app/dialog/Dialog';
import { Trash } from '@icons';
import { useTranslation } from "react-i18next";
import { createGroup, deleteGroup } from '@app/hooks/useGroups';

export interface AddGroupProps {
    editGroup?: Group | null | undefined;
    availableTags: Tag[];
    onDelete?: () => void;
}

export const AddGroupDialog: React.FC<AddGroupProps & DialogProps> = ({ editGroup, availableTags, onClose, onDelete, ...dialogProps }) => {
    const { currentUser } = useAuth();

    const { t } = useTranslation();

    const mode = editGroup ? 'edit' : 'add';

    const initialTags = editGroup && Group.lookupReferencedTags(editGroup, availableTags);
    const initialTagNames = initialTags?.map(tag => tag.name);

    const { formFields, createChangeHandler, setFormField } = useFormFields({
        name: editGroup?.name ?? '',
        tags: initialTagNames ?? [],
    });

    if (!currentUser)
        return null;

    const save = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        let fixedName = formFields.name;
        if (!fixedName || !fixedName.trim())
            fixedName = t('app.dialog.addGroupDialog.defaultName');

        const definedTags = formFields.tags;

        createGroup(editGroup?.ref, currentUser.uid, fixedName, definedTags, availableTags)
            .then(() => {
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const handleDelete = (e: React.SyntheticEvent) => {
        if (!editGroup)
            return;

        deleteGroup(editGroup)
            .then(() => {
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    return (
        <Dialog title={`${mode === 'add' ? 'Add' : 'Edit'} Group`} {...dialogProps} onClose={onClose} onSave={e => save(e)}>
            <DialogSection>
                <Textfield autoFocus placeholder={t('app.label.enterGroupName')} value={formFields.name} onChange={createChangeHandler("name")} />
                <Tagfield
                    placeholder={t('app.label.enterTagName')}
                    availableTags={availableTags.map(tag => tag.name)}
                    selectedTags={formFields.tags}
                    value={formFields.tags}
                    onSelectionChange={(selectedTags => setFormField('tags', selectedTags))}
                />
                {mode === 'edit' && (
                    <Link onClick={(e) => handleDelete(e)} to="#">
                        <Trash color="alert" className="mr-1 inline-flex" />{t('app.button.delete')}
                    </Link>
                )}
            </DialogSection>
        </Dialog>
    )
};

export default AddGroupDialog;