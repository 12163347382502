import { DateTime, Duration } from "luxon";
import { TFunction, TFuncKey, Namespace  } from 'react-i18next';
import { ItemUnit } from '@models/Item';
import { CurrencyCode } from "./currencies";

const defaultNumberFormatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
});

export function currencyToString(input: number, currencyCode: CurrencyCode) {
    let currencyFormatter: Intl.NumberFormat;
    try {
        currencyFormatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencyCode,
        });
    }
    catch (e) {
        console.error(e);

        currencyFormatter = defaultNumberFormatter;
    }

    return currencyFormatter.format(input);
}

export function floatToString(input: number, maximumFractionDigits: number = 2) {
    const numberFormatter = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: maximumFractionDigits,
    });

    return numberFormatter.format(input);
}

export function dateToDateString(input: Date) {
    return dateTimeToDateString(DateTime.fromJSDate(input));
}

export function dateTimeToDateString(input: DateTime) {
    return input.toLocaleString({ day: '2-digit', month: '2-digit', year: '2-digit' });
}

export function durationToString(input: Duration, t: TFunction<Namespace>) {
    const amountString = defaultNumberFormatter.format(input.as('days'));
    
    return t('app.conversionHelpers.durationConversion.days', { duration: amountString });
}

export function millisecondsToString(input: number, t: TFunction<Namespace>) {
    return durationToString(Duration.fromMillis(input), t);
}

export function unitToString(unit: ItemUnit | undefined, t: TFunction<Namespace>) {
    let translationKey: TFuncKey<"translation">;
    switch (unit) {
        case ItemUnit.piece: default:
            translationKey = 'app.conversionHelpers.unitConversions.pieces';
            break;
        case ItemUnit.kilogram:
            translationKey = 'app.conversionHelpers.unitConversions.kilograms';
            break;
        case ItemUnit.ton:
            translationKey = 'app.conversionHelpers.unitConversions.tons';
            break;
        case ItemUnit.liter:
            translationKey = 'app.conversionHelpers.unitConversions.liters';
            break;
        case ItemUnit.meter:
            translationKey = 'app.conversionHelpers.unitConversions.meters';
            break;
        case ItemUnit.kilometer:
            translationKey = 'app.conversionHelpers.unitConversions.kilometers';
            break;
        case ItemUnit.cubicmeter:
            translationKey = 'app.conversionHelpers.unitConversions.cubicmeters';
            break;
        case ItemUnit.kwh:
            translationKey = 'app.conversionHelpers.unitConversions.kwh';
            break;
    }

    return t(translationKey);
}

export function quantityToString(input: number, t: TFunction<Namespace>, unit?: ItemUnit) {
    const quantityString = defaultNumberFormatter.format(input);

    const unitString = unitToString(unit, t);

    return t('app.conversionHelpers.quantityConversion', { quantity: quantityString, unit: unitString });
}

export function percentageToString(input: number, t: TFunction<Namespace>) {
    const amountString = defaultNumberFormatter.format(input);
    
    return t('app.conversionHelpers.percentageConversion', { amount: amountString });
}