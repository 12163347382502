import { Link as RouterLink, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

export const TypographyClasses = {
    ExtraLargeWebsiteHeader: 'text-3xl sm:text-4xl md:text-8xl font-extrabold mb-4 italic text-blue-500',
    LargeHeader: 'text-2xl sm:text-3xl md:text-4xl font-bold text-black dark:text-white',
    SectionHeader: 'text-2xl font-bold text-gray-500',
    CardHeader: 'text-xl font-bold text-black dark:text-white',
    StatisticsHeader: 'text-sm font-semibold text-gray-500',
    WebsiteHeader: 'text-2xl font-extrabold mb-2 italic text-blue-500',
    WebsiteHeaderItalic: 'text-2xl font-bold mb-2 italic dark:text-white',
    Paragraph: 'text-sm font-normal dark:text-gray-400',
    LabelText: 'text-sm font-semibold dark:text-white',
    NoteText: 'text-sm font-normal text-gray-400',
    Link: 'text-sm font-normal text-blue-500 hover:underline',
}

export const ExtraLargeWebsiteHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h1 className={classNames(className, TypographyClasses.ExtraLargeWebsiteHeader)} {...props}>{children}</h1>
);

export const LargeHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h1 className={classNames(className, TypographyClasses.LargeHeader)} {...props}>{children}</h1>
);

export const SectionHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h2 className={classNames(className, TypographyClasses.SectionHeader)} {...props}>{children}</h2>
);

export const CardHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h3 className={classNames(className, TypographyClasses.CardHeader)} {...props}>{children}</h3>
);

export const StatisticsHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h4 className={classNames(className, TypographyClasses.StatisticsHeader)} {...props}>{children}</h4>
);

export const WebsiteHeader: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h2 className={classNames(className, TypographyClasses.WebsiteHeader)} {...props}>{children}</h2>
);

export const WebsiteHeaderItalic: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
    <h2 className={classNames(className, TypographyClasses.WebsiteHeaderItalic)} {...props}>{children}</h2>
);

export const Paragraph: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ className, children, ...props }) => (
    <p className={classNames(className, TypographyClasses.Paragraph)} {...props}>{children}</p>
);

export const LabelText: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ className, children, ...props }) => (
    <p className={classNames(className, TypographyClasses.LabelText)} {...props}>{children}</p>
);

export const NoteText: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ className, children, ...props }) => (
    <p className={classNames(className, TypographyClasses.NoteText)} {...props}>{children}</p>
);

export const Link: React.FC<LinkProps> = ({ className, children, ...props }) => (
    <RouterLink className={classNames(className, TypographyClasses.Link)} {...props}>{children}</RouterLink>
);