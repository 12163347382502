import Dialog, { DialogProps, DialogSection } from '@app/dialog/Dialog';
import { useCallback, useEffect, useState } from 'react';
import './SetupBankAccessDialog.css';
import React from "react";
import { callableFunctions } from '@lib/firebase';
import { usePlaidLink } from "react-plaid-link";
import { Button } from '@ui';
import { useBankConnections } from '../hooks/useBankConnections';

export interface SetupBankAccessDialogProps extends DialogProps {
}

const SetupBankAccessDialog: React.FC<SetupBankAccessDialogProps> = ({ onClose, ...dialogProps }) => {
    const [error, setError] = useState<string | null>(null);

    const [linkToken, setLinkToken] = useState<string | null>(null);

    const { bankConnections }= useBankConnections();

    const save = async (e: React.SyntheticEvent) => {
        e.preventDefault();
    };

    // Request link token when opening the Dialog
    useEffect(() => {
        console.log("Requesting Link Token...")
        callableFunctions.transactions.createLinkToken()
            .then((result) => {
                const { linkToken } = result.data;

                if (!linkToken || typeof linkToken !== 'string') {
                    console.error("Unable to create LinkToken. LinkToken is not a string.", { linkToken, result });
                    return;
                }

                console.log({ linkToken });

                setLinkToken(linkToken);
            })
            .catch((e) => {
                console.error(e);
                setError("Something went wrong. Please try again later.");
            });
    }, [setLinkToken]);

    const onSuccess = useCallback(async (publicToken: string) =>  {
        try {
            console.log("Registering Public Token...")
            await callableFunctions.transactions.registerPublicToken({ publicToken });
            
            console.log("Public Token registered with success. Now synchronizing transactions...");
            await callableFunctions.transactions.syncTransactions();        
        } catch (e) {
            console.error(e);
            setError("Something went wrong. Please try again later.");
        }
    }, [])

    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        onSuccess,
    };

    const { open } = usePlaidLink(config);

    return (
        <Dialog title={"Connect your bank account with Costify"} {...dialogProps} onClose={onClose} onSave={e => save(e)} error={error}>
            <DialogSection className="p-4 mb-4 rounded-md bg-white dark:bg-gray-800">
                {bankConnections.length > 0 && (<>
                    <b>Connected accounts:</b>
                    {bankConnections.map((bankConnection) => (
                        <p key={bankConnection.id}>{bankConnection.institutionMetadata?.name ?? bankConnection.id}</p>
                    ))}
                    <br/>
                    </>)}
                Costify will receive access to your Bank transactions. With this access we will be able to provide you with the right insights into your expenses.
                <br/><br/>
                With this connection we will be able to...
                <ul>
                    <li>✔ Access your transactions</li>
                    <li>✖ Make payments</li>
                    <li>✖ Share your banking data with others</li>
                </ul>

                <br/>
                You can revoke this access at any time from the settings.

                <br/><br/>
                <Button className='block' onClick={() => open()} value={linkToken === null ? "Loading..." : "Connect your Account"} disabled={linkToken === null} />
            </DialogSection>
        </Dialog>
    );
};

export default SetupBankAccessDialog;