import { groupArray, distinctArray } from "@lib/helpers";
import { useEffect, useState } from "react";
import { ImportRow, ImportRows } from "./FileImportDialog";

export interface ImportGroup {
    identifier: string;
    name: string;
    tags: string[];
    ignored: boolean;
    expanded: boolean;

    rows: ImportRows;
}

const useGroupedImportRows = (
    rows: ImportRows | null,
    nameColumn: number | null,
    comissionerColumn: number | null,
    allColumnsAssigned: boolean,
) => {
    const [importGroups, setImportGroups] = useState<ImportGroup[] | undefined>();
    const [importGroupsModified, setImportGroupsModified] = useState<boolean>(false);

    useEffect(() => {
        if (rows === null || nameColumn === null || !allColumnsAssigned)
            return;

        // Group all rows by the combination of name and comissioner
        const getNameIdentifier = (row: ImportRow) => row[nameColumn] + (comissionerColumn !== null ? ' | ' + row[comissionerColumn] : '');
        const groupedRows = groupArray(rows, (row) => getNameIdentifier(row));

        // Turn the grouped rows into ImportGroups
        const groups = Object.keys(groupedRows).map(groupIdentifier => {
            const rowsInGroup = groupedRows[groupIdentifier];

            const groupName = rowsInGroup[0][nameColumn];

            let defaultGroupTags = [] as string[];
            
            if (comissionerColumn !== null)
                defaultGroupTags = distinctArray(rowsInGroup.map(row => row[comissionerColumn]).filter(tag => tag.trim()));
            
            const group: ImportGroup = {
                identifier: groupIdentifier,
                name: groupName,
                tags: defaultGroupTags,
                ignored: false,
                expanded: false,
                rows: rowsInGroup,
            }

            return group;
        })
        
        groups.sort((l, r) => l.name.localeCompare(r.name));

        setImportGroups(groups);
        setImportGroupsModified(false);
    }, [rows, nameColumn, comissionerColumn, allColumnsAssigned]);

    const combineGroups = (groups: ImportGroup[], primaryGroup: ImportGroup) => {
        if (importGroups === undefined)
            return;

        // Remove the given groups
        const groupIdentifiers = groups.map(group => group.identifier);
        const importGroupsWithoutSelected = importGroups.filter(group => !groupIdentifiers.includes(group.identifier));

        // Create a combined group
        const combinedGroup: ImportGroup = {
            name: primaryGroup.name,
            identifier: primaryGroup.identifier,
            ignored: primaryGroup.ignored,
            expanded: primaryGroup.expanded,
            tags: distinctArray(groups.flatMap(group => group.tags)),
            rows: groups.flatMap(group => group.rows),
        }

        const combinedGroups = [...importGroupsWithoutSelected, combinedGroup];

        combinedGroups.sort((l, r) => l.name.localeCompare(r.name));

        setImportGroups(combinedGroups);
        setImportGroupsModified(true);
    }

    const combineGroupsWithNameSubstring = (substring: string, primaryGroup: ImportGroup) => {
        if (importGroups === undefined)
            return;

        const groupsWithSubstring = importGroups.filter(group => group.name.includes(substring));

        combineGroups(groupsWithSubstring, primaryGroup);
    }

    const updateGroup = (group: ImportGroup) => {
        if (importGroups === undefined)
            return;

        const groups = [...importGroups];

        const groupIndex = groups.findIndex(x => x.identifier === group.identifier);

        if (groupIndex === -1)
            return;

        groups[groupIndex] = group;

        setImportGroups(groups);
        setImportGroupsModified(true);
    }

    const splitGroup = (group: ImportGroup) => {
        if (importGroups === undefined)
            return;

        // Remove the given group
        const importGroupsWithoutSelected = importGroups.filter(existingGroup => existingGroup.identifier !== group.identifier);

        const newGroups = group.rows.map((row, i) => ({
            name: group.name,
            identifier: group.name + (comissionerColumn !== null ? ' | ' + row[comissionerColumn] : '') + ' | ' + i,
            ignored: group.ignored,
            expanded: group.expanded,
            tags: group.tags,
            rows: [row],
        } as ImportGroup));

        const combinedGroups = [...importGroupsWithoutSelected, ...newGroups];

        combinedGroups.sort((l, r) => l.name.localeCompare(r.name));

        setImportGroups(combinedGroups);
        setImportGroupsModified(true);
    }

    return {
        importGroups,
        importGroupsModified,
        combineGroups,
        combineGroupsWithNameSubstring,
        splitGroup,
        updateGroup,
    }
}

export default useGroupedImportRows;