import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 2.22386 0.223858 2 0.5 2C0.776142 2 1 2.22386 1 2.5V13.5C1 13.7761 0.776142 14 0.5 14C0.223858 14 0 13.7761 0 13.5V2.5ZM15 2.5C15 2.22386 15.2239 2 15.5 2C15.7761 2 16 2.22386 16 2.5V13.5C16 13.7761 15.7761 14 15.5 14C15.2239 14 15 13.7761 15 13.5V2.5ZM12.2043 7.51486C11.6285 7.02094 11.2676 6.83335 10.6667 6.83335C10.0228 6.83335 9.65446 7.04869 9.00214 7.62543L8.79942 7.79979C8.4585 8.08221 8.27214 8.16669 8 8.16669C7.67838 8.16669 7.47657 8.04869 6.99786 7.62543L6.87101 7.51486C6.29521 7.02094 5.93431 6.83335 5.33333 6.83335C4.68943 6.83335 4.32113 7.04869 3.66881 7.62543L3.46608 7.79979C3.12516 8.08221 2.9388 8.16669 2.66667 8.16669L2.59882 8.17125C2.35477 8.20436 2.16667 8.41356 2.16667 8.66669C2.16667 8.94283 2.39052 9.16669 2.66667 9.16669C3.31057 9.16669 3.67888 8.95135 4.33119 8.37461L4.53392 8.20025C4.87484 7.91783 5.0612 7.83335 5.33333 7.83335C5.65495 7.83335 5.85676 7.95135 6.33548 8.37461L6.46232 8.48518C7.03812 8.9791 7.39902 9.16669 8 9.16669C8.6439 9.16669 9.01221 8.95135 9.66452 8.37461L9.86725 8.20025C10.2082 7.91783 10.3945 7.83335 10.6667 7.83335C10.9883 7.83335 11.1901 7.95135 11.6688 8.37461L11.7957 8.48518C12.3715 8.9791 12.7324 9.16669 13.3333 9.16669C13.6095 9.16669 13.8333 8.94283 13.8333 8.66669C13.8333 8.39054 13.6095 8.16669 13.3333 8.16669L13.2611 8.16451C12.979 8.14673 12.7731 8.01613 12.3312 7.62543L12.2043 7.51486Z"/>
    </svg>
  );
}

export default Icon;