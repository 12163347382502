import HomepageLayout from '@modules/components/HomepageLayout';
import { WebsiteHeaderItalic, Paragraph, WebsiteHeader } from '@ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService: React.FC = () => {
    const { t } = useTranslation("termsOfService");

    // Get a translated list of sections
    const translatedStrings = t("termsOfService.sections", { returnObjects: true });

    return (
        <HomepageLayout>
            <WebsiteHeader>{t("termsOfService.title")}</WebsiteHeader>

            {translatedStrings.map((section, index) => (
                <React.Fragment key={index}>
                    {section.title && <WebsiteHeaderItalic className="mt-4 mb-2 italic">{section.title}</WebsiteHeaderItalic>}
                    {section.content && <Paragraph className="mb-2 whitespace-pre-line">{section.content}</Paragraph>}
                </React.Fragment>
            ))}
        </HomepageLayout>
    )
};

export default TermsOfService;