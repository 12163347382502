import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M7.99998 1.33331C7.72384 1.33331 7.49998 1.55717 7.49998 1.83331V7.49998H1.83331C1.55717 7.49998 1.33331 7.72384 1.33331 7.99998C1.33331 8.27612 1.55717 8.49998 1.83331 8.49998H7.49998V14.1666C7.49998 14.4428 7.72384 14.6666 7.99998 14.6666C8.27612 14.6666 8.49998 14.4428 8.49998 14.1666V8.49998H14.1666C14.4428 8.49998 14.6666 8.27612 14.6666 7.99998C14.6666 7.72384 14.4428 7.49998 14.1666 7.49998H8.49998V1.83331C8.49998 1.55717 8.27612 1.33331 7.99998 1.33331Z"/>
  </svg>
  );
}

export default Icon;


