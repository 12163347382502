import "./Dialog.css";
import { createPortal } from "react-dom";
import { Button, ButtonProps, Paragraph } from '@ui';
import { ButtonColors } from '@ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut';
import classNames from "classnames";
import { useRandomElementId } from "@hooks/useRandomElementId";
import useDialogsState from "./useDialogsState";

const sizeClasses = {
    small: 'sm:max-w-lg sm:w-full w-full',
    medium: 'sm:max-w-5xl w-full',
    large: 'sm:max-w-7xl w-full',
}

export const sizes = Object.keys(sizeClasses) as unknown as keyof typeof sizeClasses;

export interface DialogProps {
    title?: string;
    allowNesting?: boolean;
    closeLabel?: string;
    saveLabel?: string;
    size?: keyof typeof sizeClasses;
    saveButtonColor?: typeof ButtonColors;
    error?: string | null;
    onSave?: (e: React.SyntheticEvent) => Promise<void>;
    onClose?: () => void;
    customCloseButton?: (isSaving: boolean, close: () => void) => React.ReactNode;
    customSaveButton?: (isSaving: boolean, close: () => void) => React.ReactNode;
    customButtons?: (isSaving: boolean, close: () => void) => React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ 
    title, 
    allowNesting = false, 
    closeLabel, 
    saveLabel, 
    saveButtonColor,
    size = 'small',
    error,
    onSave, 
    onClose, 
    customCloseButton, 
    customSaveButton, 
    customButtons, 
    children 
}) => {

    const sharedClasses = classNames(
        'inline-block align-bottom p-4 sm:p-6 bg-gray-100 dark:bg-voodooGray rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle',
        sizeClasses[size]
    );

    const dialogParent = document.getElementById('modal-root');

    const dialogId = useRandomElementId();

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { addDialogState } = useDialogsState();
    
    useEffect(() => addDialogState(dialogId), [dialogId, addDialogState]);

    // Handle the escape key press outside the form
    useKeyboardShortcut("escape", () => {
        close();
    })

    // Handle the escape key press inside the form
    function handleKeyDown(e: React.KeyboardEvent<HTMLFormElement>) {
        if (e.key === 'Escape') {
            close();
        }
    }

    if (!dialogParent) {
        return null;
    }

    function close() {
        if (onClose) {
            onClose();
        }
    }

    function save(e: React.FormEvent) {
        if (onSave) {
            setIsSaving(true);
            onSave(e)
                .finally(() => setIsSaving(false));
        }
    }

    let footerButtons: React.ReactNode;

    if (customButtons)
        footerButtons = customButtons(isSaving, close);
    else
        footerButtons = (
            <>
                {customSaveButton 
                    ? customSaveButton(isSaving, close) 
                    : <DialogSaveButton isSaving={isSaving} value={saveLabel ? saveLabel : undefined} color={saveButtonColor} />
                }
                
                {customCloseButton 
                    ? customCloseButton(isSaving, close) 
                    : <DialogCloseButton isSaving={isSaving} value={closeLabel ? closeLabel : undefined} onClick={() => close()} />
                }
            </>
        );
    
    // Prevent opening dialogs while another dialog is opened.
    // This only considers dialogs without the allowNesting option.
    const dialogIdClass = `costify-dialog-${dialogId}`;
    const dialogNestingClass = 'costify-dialog-nesting';

    const isNested = dialogParent?.querySelectorAll(`:scope > :not(.${dialogIdClass}):not(.${dialogNestingClass})`).length > 0;

    if (!allowNesting && isNested) {
        close();
        return <></>;
    }
    
    const classes = classNames(
        `fixed z-10 inset-0 overflow-y-auto`, 
        dialogIdClass, 
        allowNesting && dialogNestingClass,
        'costify-dialog',
    );

    return (
        createPortal(
            <div className={classes} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-center justify-center sm:min-h-screen p-2 md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"/>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className={sharedClasses}>
                        <form onSubmit={(e) => { e.stopPropagation(); e.preventDefault(); save(e) }} onKeyDown={handleKeyDown}>
                            {/* Title */}
                            <div className="flex justify-between items-center pb-3">
                                {title && <p className="text-2xl font-bold text-gray-500 dark:text-gray-300">{title}</p>}
                                {/* Close Button */}
                                <div className="modal-close cursor-pointer z-50" onClick={() => close()}>
                                    <svg className="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                    </svg>
                                </div>
                            </div>

                            {/* Body */}
                            {children}

                            {/* Error */}
                            {error && (
                                <Paragraph>{error}</Paragraph>
                            )}

                            {/* Footer */}
                            <div className="sm:flex sm:flex-row-reverse mt-4">
                                {footerButtons}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            , dialogParent)
    )
}

export interface DialogSectionProps {
    title?: string
}

export const DialogSection: React.FC<DialogSectionProps & React.HTMLAttributes<HTMLDivElement>> = ({ title, children, className, ...props }) => {
    
    const classes = classNames(
        className,
    );

    return (
        <div {...props} className={classes}>
            {title && <p className="text-xl font-semibold mb-1 dark:text-white">{title}</p>}
            <div className="flex justify-center">
                <div className="dialog-section-body w-full">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const DialogCloseButton: React.FC<ButtonProps & { isSaving: boolean }> = ({ className, isSaving, value, ...props }) => {
    const { t } = useTranslation();
    const classes = classNames("w-full sm:w-1/2 mt-3 sm:m-0", className);
    return <Button type="button" color="secondary" size="large" className={classes} value={value ?? t('app.button.cancel')} disabled={isSaving} {...props} />
}

export const DialogSaveButton: React.FC<ButtonProps & { isSaving: boolean }> = ({ className, isSaving, value, ...props }) => {
    const { t } = useTranslation();
    const classes = classNames("w-full sm:w-1/2 sm:ml-3", className);
    return <Button type="submit" color="primary" size="large" className={classes} value={value ?? t('app.button.save')} loading={isSaving} {...props} />
}

export default Dialog;