import HomepageLayout from '@modules/components/HomepageLayout';
import { Paragraph, WebsiteHeader, WebsiteHeaderItalic } from '@ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
    const { t } = useTranslation("privacyPolicy");

    // Get a translated list of sections
    const translatedStrings = t("privacyPolicy.sections", { returnObjects: true });

    return (
        <HomepageLayout>
            <WebsiteHeader>{t("privacyPolicy.title")}</WebsiteHeader>

            {translatedStrings.map((section, index) => (
                <React.Fragment key={index}>
                    {section.title && <WebsiteHeaderItalic className=" mt-4 mb-2">{section.title}</WebsiteHeaderItalic>}
                    {section.content && <Paragraph className="mb-2">{section.content}</Paragraph>}
                </React.Fragment>
            ))}
        </HomepageLayout>
    )
};

export default PrivacyPolicy;