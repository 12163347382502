import { useTags } from "@app/hooks/useTags";
import { useGroups } from "@app/hooks/useGroups";
import OverviewPage, { OverviewFilter } from "@app/overview/OverviewPage";
import { useItemGroups } from "@modules/app/hooks/useItems";

const FullOverviewPage: React.FC = () => {

    const groups = useGroups();
    const tags = useTags();
    const itemGroups = useItemGroups();

    return <OverviewPage groups={groups.groups} tags={tags.tags} itemGroups={itemGroups} currentFilter={OverviewFilter.All} />
}

export default FullOverviewPage;