import { DateTime } from 'luxon';
import Item from '@models/Item';
import { groupArray } from '@helpers';
import RepeatInterval from '@models/RepeatInterval';

type CalculationMethod =
| { mode: 'single', items: Item[] }
// | { mode: 'difference', groupA: Group, groupB: Group }

export function useStatisticsCalculations({ mode, items }: CalculationMethod) {
    const now = DateTime.now();

    // Add copies of the items for each repeating date
    items = items.flatMap(item => item.repeatInterval 
        ? [item, ...RepeatInterval.getRepetitionsBetweenDates(item.repeatInterval, item.date, now).map(repetitionDate => ({ ...item, date: repetitionDate } as Item))]
        : [item]);

    // Sort the items by date
    items = items.sort(function(a, b) { return a.date.valueOf() - b.date.valueOf() });

    if (items.length < 1)
        return null;

    const firstItem = items[0];
    const lastItem = items[items.length - 1];

    const startDate = firstItem.date;
    const endDate = lastItem.date;
    const currentDate = DateTime.now().startOf('day');

    const years = endDate.diff(startDate, ['years']).years;
    const months = endDate.diff(startDate, ['months']).months;
    const weeks = endDate.diff(startDate, ['weeks']).weeks;
    const days = endDate.diff(startDate, ['days']).days;

    const yearsTilNow = currentDate.diff(startDate, ['years']).years;
    const monthsTilNow = currentDate.diff(startDate, ['months']).months;
    const weeksTilNow = currentDate.diff(startDate, ['weeks']).weeks;
    const daysTilNow = currentDate.diff(startDate, ['days']).days;

    // Generate kvps for each unit with the sum of quantity as value.
    // Group the items by the unit
    const itemsByUnit = groupArray(items, item => item.unit);

    const totalQuantitiesbyUnits: {
        [x: number]: number
    } = {};

    const averagePricesbyUnits: {
        [x: number]: number
    } = {};

    // Iterate over each unit and calculate the sum of each quantity
    Object.keys(itemsByUnit).forEach((key, i) => {
        const intKey = parseInt(key);
        const itemsWithCurrentUnit = itemsByUnit[intKey];

        const totalQuantities = itemsWithCurrentUnit.reduce(((sum, curr) => sum + curr.quantity), 0);
        const totalPrices = itemsWithCurrentUnit.reduce(((sum, curr) => sum + curr.price), 0);

        totalQuantitiesbyUnits[intKey] = totalQuantities;
        averagePricesbyUnits[intKey] = totalPrices / totalQuantities;
    });

    let totalTimespan = 0;
    let longestTimespan = 0;
    let shortesTimespan: number | undefined;

    let totalPrice = 0;
    let highestPrice = 0;
    let lowestPrice: number | undefined;

    // Timespan
    for (let i = 1; i < items.length; i++) {
        let item = items[i];
        let prevItem = items[i - 1];

        const timespan = item.date.toMillis() - prevItem.date.toMillis();

        totalTimespan += timespan;

        if (timespan > longestTimespan)
            longestTimespan = timespan;

        if (shortesTimespan === undefined || timespan < shortesTimespan)
            shortesTimespan = timespan;
    }

    // Price
    for (let i = 0; i < items.length; i++) {
        let item = items[i];
        
        const price = item.price;

        totalPrice += price;

        if (price > highestPrice)
            highestPrice = price;

        if (lowestPrice === undefined || price < lowestPrice)
            lowestPrice = price;
    }

    return {
        total: {
            years: years,
            months: months,
            weeks: weeks,
            days: days,

            quantityByUnits: totalQuantitiesbyUnits,
            price: totalPrice,
        },

        average: {
            yearly: totalPrice / Math.ceil(yearsTilNow),
            monthly: totalPrice / Math.ceil(monthsTilNow),
            weekly: totalPrice / Math.ceil(weeksTilNow),
            daily: totalPrice / Math.ceil(daysTilNow),
            price: totalPrice / items.length,
            priceByUnits: averagePricesbyUnits,
            timespan: items.length > 1 ? totalTimespan / (items.length - 1) : 0, // - 1 to get the amount of durations. 3 items have 2 durations.
        },

        dates: {
            firstPurchase: firstItem.date,
            lastPurchase: lastItem.date,
            longestTimespan,
            shortesTimespan,
        },

        prices: {
            firstPrice: firstItem.price,
            lastPrice: lastItem.price,
            
            lowestPrice: lowestPrice ?? 0,
            highestPrice: highestPrice ?? 0,
        }
    }
} 