import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { handleError, useAuth } from "@authentication/AuthProvider";
import HomepageLayout from "@modules/components/HomepageLayout";
import { Link } from "@ui";
import { useTranslation } from "react-i18next";
import routes from "@modules/routes/Routes";

const Reset: React.FC = () => {
    const { verifyEmail, setAuthenticationNotification } = useAuth();
    const { t } = useTranslation();

    const [verifying, setVerifying] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const history = useHistory();
    const location = useLocation().search;

    const verificationCode = new URLSearchParams(location).get('oobCode');

    // Verify if the resetCode is set and valid
    useEffect(() => {
        setError(null);

        if (verificationCode == null) {
            history.push('/');
        } else {
            verifyEmail(verificationCode)
                .then(() => {
                    setAuthenticationNotification(t('authentication.verificationBanner'));
                    
                    history.push('/');
                })
                .catch(e => {
                    const authError = handleError(e);

                    if (authError) {
                        setError(authError.message);
                    }
                })
                .finally(() => {
                    setVerifying(false);
                });
        }
    }, [ history, verificationCode, verifyEmail, setAuthenticationNotification, t ]);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        
        return;
    }

    return (
        <HomepageLayout onSubmit={handleSubmit}>
            <h1 className="text-4xl font-bold mb-4">
                {t('authentication.verify.title')}
            </h1>
            { verifying ? (
                <div className = "flex mb-10">
                    <p className = "text-gray-400">{t('authentication.common.loading')}</p>
                </div >
            ) : (
                error && (
                    <>
                        {/* @TODO */}
                        <div className = "flex mb-10">
                            <p className = "text-gray-400">
                                {error} <Link to={routes.authentication.login.build()}>{t('authentication.verify.loginLink')}</Link>
                            </p>
                        </div >
                    </>
                )
            )}
            
        </HomepageLayout >
    )
}

export default Reset;