import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M4.13056 12.2004C3.88651 12.1672 3.6984 11.9581 3.6984 11.7049L3.6984 7.03826L3.70297 6.97041C3.73608 6.72636 3.94527 6.53826 4.1984 6.53826L4.26625 6.54282C4.5103 6.57593 4.6984 6.78513 4.6984 7.03826L4.6984 10.5076L11.2458 3.96025C11.441 3.76499 11.7576 3.76499 11.9529 3.96025C12.1482 4.15551 12.1482 4.47209 11.9529 4.66736L5.41532 11.2049L8.86507 11.2049L8.93292 11.2095C9.17697 11.2426 9.36507 11.4518 9.36507 11.7049C9.36507 11.9811 9.14121 12.2049 8.86507 12.2049L4.1984 12.2049L4.13056 12.2004Z"/>
    </svg>
  );
}

export default Icon;
