import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.5 0C3.22386 0 3 0.223857 3 0.5V1.33333H1.33333C0.596954 1.33333 0 1.93029 0 2.66667V12.6667C0 13.403 0.596954 14 1.33333 14H2V14.6667C2 15.403 2.59695 16 3.33333 16H14.6667C15.403 16 16 15.403 16 14.6667V4.66667C16 3.93029 15.403 3.33333 14.6667 3.33333H14V2.66667C14 1.93029 13.403 1.33333 12.6667 1.33333H11V0.5C11 0.223858 10.7761 0 10.5 0C10.2239 0 10 0.223857 10 0.5V1.33333H4V0.5C4 0.223858 3.77614 0 3.5 0ZM3 2.33333V2.83333C3 3.10948 3.22386 3.33333 3.5 3.33333C3.77614 3.33333 4 3.10948 4 2.83333V2.33333H10V2.83333C10 3.10948 10.2239 3.33333 10.5 3.33333C10.7761 3.33333 11 3.10948 11 2.83333V2.33333H12.6667L12.7266 2.3387C12.8821 2.36693 13 2.50303 13 2.66667V12.6667L12.9946 12.7266C12.9664 12.8821 12.8303 13 12.6667 13H1.33333L1.27342 12.9946C1.11792 12.9664 1 12.8303 1 12.6667V2.66667L1.00537 2.60675C1.03359 2.45125 1.16969 2.33333 1.33333 2.33333H3ZM14 4.33333V12.6667C14 13.403 13.403 14 12.6667 14H3V14.6667C3 14.8303 3.11792 14.9664 3.27342 14.9946L3.33333 15H14.6667C14.8303 15 14.9664 14.8821 14.9946 14.7266L15 14.6667V4.66667C15 4.50303 14.8821 4.36693 14.7266 4.3387L14.6667 4.33333H14ZM4 4.66669V6.66669H2V4.66669H4ZM4 7.33331H2V9.33331H4V7.33331ZM4.66669 4.66669H6.66669V6.66669H4.66669V4.66669ZM6.66669 7.33331H4.66669V9.33331H6.66669V7.33331ZM7.33331 4.66669H9.33331V6.66669H7.33331V4.66669ZM9.33331 7.33331H7.33331V9.33331H9.33331V7.33331ZM12 4.66669V6.66669H10V4.66669H12ZM12 7.33331H10V9.33331H12V7.33331ZM4 10V12H2V10H4ZM6.66669 10H4.66669V12H6.66669V10ZM7.33331 10H9.33331V12H7.33331V10ZM12 12V10H10V12H12Z"/>
</svg>
  );
}

export default Icon;