import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5 1C5 0.447693 5.44769 0 6 0H10C10.5523 0 11 0.447693 11 1H15.5C15.7761 1 16 1.22388 16 1.5C16 1.77612 15.7761 2 15.5 2H10H6H0.5C0.223877 2 0 1.77612 0 1.5C0 1.22388 0.223877 1 0.5 1H5ZM13.5 4H2.5V14C2.5 14.5523 2.94769 15 3.5 15H12.5C13.0523 15 13.5 14.5523 13.5 14V4ZM1.5 3V14C1.5 15.1046 2.39545 16 3.5 16H12.5C13.6046 16 14.5 15.1046 14.5 14V3H1.5ZM3.5 5H4.5V14H3.5V5ZM5.5 14V5H6.5V14H5.5ZM8.5 5H7.5V14H8.5V5ZM9.5 14V5H10.5V14H9.5ZM12.5 5H11.5V14H12.5V5Z"/>
    </svg>
  );
}

export default Icon;