import { z } from "zod";
import firebase from 'firebase';
import { DateTime } from "luxon";

// Validates whether the object is an instance firebase.firestore.DocumentReference
export const documentReferenceSchema = () => z.custom<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>(
    (data: unknown) => {
        return data instanceof firebase.firestore.DocumentReference
    }, 
    "Reference is not an instance of DocumentReference"
);

export const dateTimeSchema = () => z.custom<DateTime>(
    (data: unknown) => {
        return data instanceof DateTime && data.isValid
    }, 
    "Invalid DateTime"
);