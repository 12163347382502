import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import routes from './Routes';

// A route that redirects the user to the correct component depending on the mode query parameter set by firebase.
// This is only used when a user opens a reset/verification link from a firebase mail.
const FirebaseRoute: React.FC<RouteProps> = (props: RouteProps) => {
    const location = useLocation().search;

    const mode = new URLSearchParams(location).get('mode') as 'resetPassword' | 'recoverEmail' | 'verifyEmail' | string | null;
    
    let target: string;

    switch (mode) {
        case 'resetPassword':
            target = routes.authentication.reset.build();
            break;
        case 'recoverEmail':
            target =  routes.authentication.recover.build();
            break;
        case 'verifyEmail':
            target = routes.authentication.verify.build();
            break;
        default:
            target = routes.app.overview.build();
            break;
    }

    return <Route {...props} render={() => (
        <Redirect to={target + location} />
    )} />
};

export default FirebaseRoute;