import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Union" opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M2.15002 5H3.90002V6.75H2.15002V5ZM2.15002 10H3.90002V11.75H2.15002V10ZM3.90002 12.5H2.15002V14.25H3.90002V12.5ZM4.65002 5H6.40002V6.75H4.65002V5ZM6.40002 10H4.65002V11.75H6.40002V10ZM4.65002 12.5H6.40002V14.25H4.65002V12.5ZM8.90002 5H7.15002V6.75H8.90002V5ZM7.15002 10H8.90002V11.75H7.15002V10ZM8.90002 12.5H7.15002V14.25H8.90002V12.5ZM9.65002 5H11.4V6.75H9.65002V5ZM11.4 10H9.65002V11.75H11.4V10ZM9.65002 12.5H11.4V14.25H9.65002V12.5ZM13.9 5H12.15V6.75H13.9V5ZM12.15 10H13.9V11.75H12.15V10ZM13.9 12.5H12.15V14.25H13.9V12.5Z"/>
      <path id="Union_2" fillRule="evenodd" clipRule="evenodd" d="M3.5 0C3.22386 0 3 0.223858 3 0.5V2H1C0.447715 2 0 2.44772 0 3V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V3C16 2.44772 15.5523 2 15 2H13V0.5C13 0.223858 12.7761 0 12.5 0C12.2239 0 12 0.223858 12 0.5V2H4V0.5C4 0.223858 3.77614 0 3.5 0ZM12 3.375V3H4V3.375C4 3.65114 3.77614 3.875 3.5 3.875C3.22386 3.875 3 3.65114 3 3.375V3H1V15H15V3H13V3.375C13 3.65114 12.7761 3.875 12.5 3.875C12.2239 3.875 12 3.65114 12 3.375ZM3.90002 7.5H2.15002V9.25H3.90002V7.5ZM4.65002 7.5H6.40002V9.25H4.65002V7.5ZM8.90002 7.5H7.15002V9.25H8.90002V7.5ZM9.65002 7.5H11.4V9.25H9.65002V7.5ZM13.9 7.5H12.15V9.25H13.9V7.5Z"/>
  </svg>
  );
}

export default Icon;