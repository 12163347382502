import { useGroups } from "@app/hooks/useGroups";
import OverviewPage, { OverviewFilter } from "@app/overview/OverviewPage";

const GroupsOverviewPage: React.FC = () => {

    const groups = useGroups();

    return <OverviewPage groups={groups.groups} currentFilter={OverviewFilter.Groups} />
}

export default GroupsOverviewPage;