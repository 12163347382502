import React, { useRef, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import firebase from "firebase";
import { handleError, useAuth } from "@authentication/AuthProvider";
import HomepageLayout from "@modules/components/HomepageLayout";
import { Button, Textfield, Validation, Link, LargeHeader, Paragraph } from "@ui";
import { ArrowLeftRounded } from "@icons";
import routes from "@modules/routes/Routes";
import { Trans, useTranslation } from "react-i18next";

const Registration: React.FC = () => {
    const { signup } = useAuth();
    const { t } = useTranslation();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setEmailError(null);
        setPasswordError(null);
        setConfirmPasswordError(null);

        if (emailRef.current && passwordRef.current && confirmPasswordRef.current) {

            if (passwordRef.current.value !== confirmPasswordRef.current.value) {
                return setConfirmPasswordError(t('authentication.erros.passwordsDontMatch'));
            }

            setLoading(true);

            try {
                const credential = await signup(emailRef.current.value, passwordRef.current.value);

                credential.user?.sendEmailVerification();

                history.push('/');
            } catch (e) {
                const authError = handleError(e as firebase.auth.Error);

                if (authError) {
                    authError.field === 'password'
                        ? setPasswordError(authError.message)
                        : setEmailError(authError.message);
                }
            }
        }

        setLoading(false);
    }

    return (
        <HomepageLayout onSubmit={handleSubmit}>
            <LargeHeader className="mb-4">
                <RouterLink to={routes.authentication.login.build()}><ArrowLeftRounded className="mr-2 inline-flex" /></RouterLink>
                {t('authentication.registration.title')}
            </LargeHeader>
            <Validation message={emailError}>
                <Textfield ref={emailRef} placeholder={t('authentication.common.emailPlaceholder')} autoComplete="off" />
            </Validation>
            <Validation message={passwordError}>
                <Textfield ref={passwordRef} type="password" placeholder={t('authentication.common.passwordPlaceholder')} autoComplete="off" className="mt-4" />
            </Validation>
            <Validation message={confirmPasswordError}>
                <Textfield ref={confirmPasswordRef} type="password" placeholder={t('authentication.common.confirmPlaceholder')} autoComplete="off" className="mt-4" />
            </Validation>
            <Button type="submit" className="w-full mt-4" size="large" value={t('authentication.registration.registerButton')} loading={loading} />
            <div className="flex mb-10 mt-4">
                <Paragraph>
                    <Trans i18nKey="authentication.registration.legalNote">
                        <Link to="/terms-of-service" target="_blank" className="text-blue-500"></Link>
                        <Link to="/privacy-policy" target="_blank" className="text-blue-500"></Link>
                    </Trans>
                </Paragraph>
            </div>
        </HomepageLayout>
    )
}

export default Registration;