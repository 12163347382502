import Dialog, { DialogSaveButton, DialogSection } from "@app/dialog/Dialog";
import { Label, Textfield } from "@ui";
import { useAuth } from '@authentication/AuthProvider';
import firebase from "firebase";
import { useState } from 'react';
import useFormFields from "@hooks/useFormFields";

export interface ReauthenticationDialogProps {
    onComplete: () => void;
    onFail: () => void;
}

const ReauthenticationDialog: React.FC<ReauthenticationDialogProps> = ({ onComplete, onFail, ...props }) => {

    const { currentUser } = useAuth();

    const [error, setError] = useState<string>();

    const { formFields, createChangeHandler } = useFormFields({
        password: '',
    });

    const reauthenticate = async () => {
        const credential = firebase.auth.EmailAuthProvider.credential(
            currentUser?.email ?? "",
            formFields.password
        );
        
        currentUser?.reauthenticateWithCredential(credential)
            .then(() => {
                onComplete();
            })
            .catch((error: firebase.FirebaseError) => {
                setError(error.message)
            });
    }

    return (
        <Dialog title="Reauthenticate" onClose={onFail} onSave={reauthenticate} customSaveButton={(isSaving) =>
            <DialogSaveButton isSaving={isSaving} value="Login" />
        }>
            <DialogSection>

                {error && <p>{error}</p>}

                <Label value={'Password'}>
                    <Textfield type="password" value={formFields.password} onChange={createChangeHandler('password')} />
                </Label>
            </DialogSection>
        </Dialog>
    );
}

export default ReauthenticationDialog;