import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
<path d="M10.1213 5.17157C10.3166 4.97631 10.6332 4.97631 10.8284 5.17157C11.0237 5.36683 11.0237 5.68342 10.8284 5.87868L8.70711 8L10.8284 10.1213C11.0237 10.3166 11.0237 10.6332 10.8284 10.8284C10.6332 11.0237 10.3166 11.0237 10.1213 10.8284L8 8.70711L5.87868 10.8284C5.68342 11.0237 5.36683 11.0237 5.17157 10.8284C4.97631 10.6332 4.97631 10.3166 5.17157 10.1213L7.29289 8L5.17157 5.87868C4.97631 5.68342 4.97631 5.36683 5.17157 5.17157C5.36683 4.97631 5.68342 4.97631 5.87868 5.17157L8 7.29289L10.1213 5.17157Z"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.6569 13.6569C10.5327 16.781 5.46734 16.781 2.34315 13.6569C-0.781049 10.5327 -0.781049 5.46734 2.34315 2.34315C5.46734 -0.781049 10.5327 -0.781049 13.6569 2.34315C16.781 5.46734 16.781 10.5327 13.6569 13.6569ZM12.9497 12.9497C10.2161 15.6834 5.78392 15.6834 3.05025 12.9497C0.316583 10.2161 0.316582 5.78392 3.05025 3.05025C5.78392 0.316583 10.2161 0.316583 12.9497 3.05025C15.6834 5.78392 15.6834 10.2161 12.9497 12.9497Z"/>
</svg>
  );
}

export default Icon;
