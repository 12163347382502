import { CostifyLogoOrange } from '@icons';
import { Link, DefaultBreadcrumbs } from '@ui';
import React from 'react';
import classNames from 'classnames';

const HomepageLayout: React.FC<React.FormHTMLAttributes<HTMLFormElement>> = (({ className, children, ...props }) => {

    const classes = classNames(
        className,
        'flex flex-col h-screen',
    );

    return (
        <form className={classes} {...props}>
            <div className="p-10 md:pl-20 lg:pl-20 h-60 flex justify-center md:justify-start">
                <Link to="/">
                    <CostifyLogoOrange width="64" height="96" />
                </Link>
            </div>
            <div className="flex-grow flex md:justify-left md:items-center p-4 md:pl-20">
                <div className="w-full md:w-11/12 lg:max-w-lg">
                    {children}
                </div>
            </div>
            <DefaultBreadcrumbs />
        </form>
    )
});

export default HomepageLayout;