import classNames from 'classnames';

// Table
export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
}

const Table: React.FC<TableProps> = ({
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
    );

    return (
        <table className={classes} {...props}>
            {children}
        </table>
    );
};

export default Table;

// Table Header
export interface TableHeaderProps extends React.HTMLAttributes<HTMLTableSectionElement> {
    canSelect?: boolean;
    canSelectAll?: boolean;
    allSelected?: boolean;
    selectAll?: () => void;
    deselectAll?: () => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
    canSelect = false,
    canSelectAll = false,
    allSelected = false,
    selectAll,
    deselectAll,
    className,
    children,
    ...props
}) => {

    function handleSelection() {
        if (allSelected)
            deselectAll && deselectAll();
        else
            selectAll && selectAll();
    }

    const classes = classNames(
        className,
    );

    return (
        <thead className={classes} {...props}>
            <tr className=" dark:text-white">
                {canSelect && (
                    <th className="text-left px-2 flex-1">
                        {canSelectAll && (
                            <input type="checkbox" checked={allSelected} onChange={handleSelection} />
                        )}
                    </th>
                )}
                {children}
            </tr>
        </thead>
    );
};

// Table Cell
export enum TableSortOrder {
    none,
    ascending,
    descending,
}
export interface TableColumnProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
    isSorted?: TableSortOrder;
    onSort?: (order: TableSortOrder) => void;
}

export const TableColumn: React.FC<TableColumnProps> = ({
    className,
    children,
    isSorted,
    onSort,
    ...props
}) => {

    const classes = classNames(
        className,
        'text-sm text-left p-2 cursor-pointer select-none',
    );

    let nextSortOrder = isSorted === TableSortOrder.descending ? TableSortOrder.ascending : TableSortOrder.descending;

    return (
        <th className={classes} {...props} onClick={() => onSort && onSort(nextSortOrder)}>
            {children}
            {isSorted !== undefined && isSorted !== TableSortOrder.none && 
                <span className='ml-1'>{isSorted === TableSortOrder.ascending ? '↑' : '↓'}</span>
            }
        </th>
    );
};

// Table Row
export interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    canSelect?: boolean;
    selected?: boolean;
    onSelect?: () => void;
    onDeselect?: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({
    canSelect = false,
    selected = false,
    onSelect,
    onDeselect,
    className,
    children,
    ...props
}) => {

    function handleSelection() {
        if (selected)
            onSelect && onSelect();
        else
            onDeselect && onDeselect();
    }

    const classes = classNames(
        className,
        'odd:bg-oddGrayBright rounded-md dark:odd:bg-oddGray hover:bg-white dark:hover:bg-oddGrayHover dark:hover:border-gray-500',
    )

    return (
        <tr className={classes} {...props}>
            {canSelect && (
                <td className="px-2 w-1">
                    <input type="checkbox" checked={selected} onChange={handleSelection} />
                </td>
            )}
            {children}
        </tr>
    );
};

// Table Cell
export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
}

export const TableCell: React.FC<TableCellProps> = ({
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
        'text-sm p-2 dark:text-gray-400',
    )

    return (
        <td className={classes} {...props}>
            {children}
        </td>
    );
};