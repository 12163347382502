import { createContext, useContext, useEffect, useReducer, useMemo } from 'react';
import firebase from 'firebase';
import { database, largeBatch } from '@lib/firebase';
import Item, { ItemCollection, itemSchema } from '@models/Item';
import { useAuth } from '@modules/authentication/AuthProvider';
import { groupArray, distinctArray } from '@lib/helpers';
import { ItemGroup } from '@models/Item';
import Tag from '@models/Tag';
import RepeatInterval from '@models/RepeatInterval';
import { DateTime } from 'luxon';

type Action =
    | { type: "set-loading" }
    | { type: "set-items"; items: Item[] }

function reducer(state: ItemCollection | undefined, action: Action): ItemCollection | undefined {
    switch (action.type) {
        case 'set-loading':
            return undefined;
        case 'set-items':
            console.log('updating items', action.items);

            return {
                ...state,
                items: action.items,
                getItemsWithTags: (tags) => ItemCollection.lookupItemsWithTags(action.items, tags),
                getItemMetadata: () => ItemCollection.getItemCollectionMetadata(action.items),
            }
        default:
            return state;
    }
}

const ItemsContext = createContext<ItemCollection | undefined>(undefined)

export const ItemsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, undefined);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser)
            return;

        return database.items
            .where('ownerUid', '==', currentUser.uid)
            .withConverter(new Item.FirestoreConverter())
            .onSnapshot(snapshot => {
                console.log('Updating Items');

                const items = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    try {
                        return itemSchema.parse({ ...docData, ref: doc.ref }) as Item;
                    }
                    catch (e) {
                        console.error(e, { originalObject: docData })
                        return null;
                    }
                }).filter((item): item is Item => item !== null)

                dispatch({ type: 'set-items', items })
            })

    }, [currentUser]);

    return (
        <ItemsContext.Provider value={state}>
            {children}
        </ItemsContext.Provider>
    );
}

export function useItemsOpt(tags?: firebase.firestore.DocumentReference[]): ItemCollection | undefined {
    const items = useContext(ItemsContext);

    return useMemo(() => {
        if (items === undefined)
            return items;

        const filteredItems = tags !== undefined
            ? items.items.filter(x => x.tags.some(tagReference => tags.map(tag => tag.id).includes(tagReference.id)))
            : items.items
            
        return { ...items, items: [...filteredItems] };
    }, [items, tags]);

}

export function useItems(tags?: firebase.firestore.DocumentReference[]) {
    return useItemsOpt(tags)!;
}

export function useItem(itemId: string) {
    const itemCollection = useItems();

    const item = useMemo(() => {
        return itemCollection?.items.find(item => item.id === itemId);
    }, [itemId, itemCollection?.items]);

    return item;
}

export function useItemGroups() {
    const items = useItems();

    const itemGroups = useMemo(() => {
        const itemsGroupedByName = groupArray(items.items, item => item.name);
    
        const itemGroups = Object.keys(itemsGroupedByName).map(itemName => {
            const itemsWithName = itemsGroupedByName[itemName];
    
            const allItemTags = distinctArray(itemsWithName.flatMap(item => item.tags));
    
            return new ItemGroup(itemName, allItemTags, itemsWithName, itemsWithName.length);
        })

        return itemGroups;
    }, [items.items]);

    return itemGroups;
}

export function useItemGroup(name: string) {
    const items = useItems();

    const itemGroup = useMemo(() => {
        const itemsWithName = items.items.filter(item => item.name === name);

        if (itemsWithName.length === 0)
            return undefined;

        const allItemTags = distinctArray(itemsWithName.flatMap(item => item.tags));
        
        return new ItemGroup(name, allItemTags, itemsWithName, itemsWithName.length);
    }, [items.items, name]);

    return itemGroup;
}

export function useImportedItemHashes() {
    const items = useItems();

    return useMemo(() => {
        return items.items
            .flatMap(item => item.importHash)
            .filter(hash => hash !== null)
            .map(hash => hash!);
    }, [items.items]);
}

export function createItem(
    existingItemReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    name: string,
    userId: string,
    unit: number,
    price: number,
    quantity: number,
    date: DateTime,
    repeatInterval: RepeatInterval | null,
    importHash: number | null,
    tagNames: string[],
    availableTags: Tag[],
) {
    return createItems([{ 
        existingItemReference,
        name,
        unit,
        price,
        quantity,
        date,
        repeatInterval,
        importHash,
        tagNames
    }], userId, availableTags)
}

export interface CreateItemProps {
    existingItemReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined;
    name: string;
    unit: number;
    price: number;
    quantity: number;
    date: DateTime;
    repeatInterval: RepeatInterval | null;
    importHash: number | null;
    tagNames: string[];
}

export function createItems(items: CreateItemProps[], userId: string, availableTags: Tag[]) {
    const batch = largeBatch();

    const tagNames = distinctArray(items.flatMap(item => item.tagNames));

    const tagReferencesWithNames = availableTags
        .filter(x => x.ref !== undefined)
        .map(tag => ({ name: tag.name, ref: tag.ref! }));

    const newTagNames = tagNames.filter(tagName => !tagReferencesWithNames.find(tagReferenceWithName => tagReferenceWithName.name === tagName))
    
    for (const newTagName of newTagNames) {
        const newTagReference = database.tags.doc();

        batch.set(newTagReference, {
            id: newTagReference.id,
            ownerUid: userId,
            name: newTagName,
        } as Tag);

        tagReferencesWithNames.push({ name: newTagName, ref: newTagReference });
    }

    for (const item of items) {
        const { 
            existingItemReference,
            name,
            unit,
            price,
            quantity,
            date,
            repeatInterval,
            importHash,
            tagNames 
        } = item;
        
        const tagReferences = tagNames
            .map(tagName => tagReferencesWithNames.find(tagReferenceWithName => tagReferenceWithName.name === tagName)?.ref)
            .filter(ref => ref !== undefined)
            .map(ref => ref!);

        const documentRef = database.items.doc(existingItemReference?.id).withConverter(new Item.FirestoreConverter());
        batch.set(documentRef, {
            id: documentRef.id,
            ownerUid: userId,
            name: name,
            tags: tagReferences,
            unit,
            price,
            quantity,
            date,
            repeatInterval,
            importHash,
        } as Item);
    }

    return batch.commit()
}

export function deleteItem(item: Item) {
    const documentRef = database.items.doc(item.id);

    return documentRef.delete()
}

export function deleteItemGroup(itemGroup: ItemGroup) {
    const batch = largeBatch();

    const documentRefs = itemGroup.items
        .map(item => item.ref)
        .filter(ref => ref !== undefined)
        .map(ref => ref!);

    for (const ref of documentRefs)
        batch.delete(ref);

    return batch.commit();
}