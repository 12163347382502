import useFormFields from "@hooks/useFormFields";
import Dialog, { DialogProps, DialogSection } from '@app/dialog/Dialog';
import { useEffect, useState, useCallback, useMemo } from 'react';
import Papa from "papaparse";
import { Label, Paragraph, Select, Textfield, Table, TableHeader, TableColumn, TableRow, TableCell, Checkbox, Button, Tagfield, Dropzone, Validation, Link } from '@ui';
import classNames from "classnames";
import { createItems, CreateItemProps } from '@app/hooks/useItems';
import { useAuth } from "@modules/authentication/AuthProvider";
import { DateTime } from "luxon";
import Tag from "@models/Tag";
import './FileImportDialog.css';
import useGroupedImportRows, { ImportGroup } from "./useGroupedImportRows";
import { useTranslation } from "react-i18next";
import { Trash } from "@icons";
import { Tag as TagLabel } from "@ui";
import { useConfirmDialog } from "../confirmDialog/ConfirmDialog";
import { currencyToString, quantityToString } from "@lib/stringConversion";
import { useUserSettings } from '../hooks/useUserSettings';
import { dateTimeToDateString } from '../../../lib/stringConversion';
import React from "react";
import firebase from 'firebase'
import { getStringHash } from '../../../lib/helpers';

export interface FileImportDialogProps extends DialogProps {
    availableTags: Tag[];
    alreadyImportedHashes: number[];
    files?: FileList;
}

const maxRowCheckCount = 200;

export type ImportRow = string[];
export type ImportRows = ImportRow[];

const FileImportDialog: React.FC<FileImportDialogProps> = ({ availableTags, alreadyImportedHashes, files, onClose, ...dialogProps }) => {
    const { currentUser } = useAuth();
    const userSettings = useUserSettings();

    const { t } = useTranslation();

    const [confirmDialog, showConfirmDialog] = useConfirmDialog();
    
    const { formFields, createChangeHandler, createSimpleChangeHandler, createSelectChangeHandler, setFormField } = useFormFields({
        files: files ?? null,
        
        nameColumn: null as number | null,
        dateColumn: null as number | null,
        priceColumn: null as number | null,
        comissionerColumn: null as number | null,
        quantityColumn: null as number | null,

        customDateFormat: '',
        invertPrices: false,
    });

    const [rows, setRows] = useState<ImportRows | null>(null);
    
    const [textSelectionHandling, setTextSelectionHandling] = useState<{
        identifier: string,
        textSelection: string,
    } | null>(null);

    const [error, setError] = useState<string | null>(null);

    const allColumnsAssigned = formFields.nameColumn !== null && formFields.dateColumn !== null && formFields.priceColumn !== null;
    const emptyFile = !rows || rows.length === 0;
    
    // We always assume that there is a header row
    const headerRow = !emptyFile && rows ? rows[0] : null;

    const { importGroups, importGroupsModified, combineGroupsWithNameSubstring, splitGroup, updateGroup } = useGroupedImportRows(rows, formFields.nameColumn, formFields.comissionerColumn, allColumnsAssigned);

    const parseDate = (dateString: string, dateFormat: string) => {
        const fixedDateString = dateString.replaceAll(/[^A-Za-z0-9]/g, '');

        return DateTime.fromFormat(fixedDateString, dateFormat);
    }

    const detectedDateFormat = useMemo(() => {
        const predefinedDateFormats = [
            'ddMMyyyy',
            'ddMM',
            'ddMMyy'
        ];

        const dateColumn = formFields.dateColumn;

        if (rows === null || dateColumn === null)
            return undefined;

        for (let i = 0; i < Math.min(rows?.length ?? 0, maxRowCheckCount); i++) {
            const row = rows[i];
            const dateString = row[dateColumn];

            for (const dateFormat of predefinedDateFormats) {
                const date = parseDate(dateString, dateFormat);

                if (date.isValid)
                    return dateFormat;
            }
        }

        // If no format was matching
        return null;
    }, [rows, formFields.dateColumn]);

    const dateFormat = detectedDateFormat ?? formFields.customDateFormat;

    const isDateFormatValid = useMemo(() => {
        const dateColumn = formFields.dateColumn;

        if (detectedDateFormat === undefined || rows === null || dateColumn === null)
            return false;

        if (detectedDateFormat !== null)
            return true;

        if (formFields.customDateFormat.trim().length === 0)

        for (let i = 0; i < Math.min(rows?.length ?? 0, maxRowCheckCount); i++) {
            const row = rows[i];
            const dateString = row[dateColumn];

            const date = parseDate(dateString, dateFormat);

            if (date.isValid)
                return true;
        }

        return false;
    }, [dateFormat, detectedDateFormat, formFields.customDateFormat, formFields.dateColumn, rows]);

    const parseNumber = (str: string) => Number(str.replace(/[.,](?=.*[.,].*)/g,"").replace(',', '.'));

    const checkNumberColumnValid = useCallback((columnIndex: number | null) => {
        if (rows === null)
            return false;

        if (columnIndex === null)
            return true;

        for (let i = 0; i < Math.min(rows?.length ?? 0, maxRowCheckCount); i++) {
            const row = rows[i];
            const column = row[columnIndex].trim();

            if (column.length === 0)
                continue;

            const number = parseNumber(column);

            if (!isNaN(number))
                return true;
        }
        
        return false;
    }, [rows]);

    const isPriceColumnValid = useMemo(() => checkNumberColumnValid(formFields.priceColumn), [formFields.priceColumn, checkNumberColumnValid]);
    const isQuantityColumnValid = useMemo(() => checkNumberColumnValid(formFields.quantityColumn), [formFields.quantityColumn, checkNumberColumnValid]);

    // Helper for CSV Parsing
    const getColumnCount = useCallback((rows: ImportRows) => {
        if (!rows)
            return 0;

        let maxColumnCount = 0;
        for (let i = 0; i < Math.min(rows?.length ?? 0, maxRowCheckCount); i++) {
            const row = rows[i];

            if (row.length > maxColumnCount)
                maxColumnCount = row.length;
        }

        return maxColumnCount;
    }, []);

    // Helper for CSV Parsing
    const getLastColumnAlwaysEmpty = useCallback((rows: ImportRows) => {
        for (let i = 0; i < Math.min(rows?.length ?? 0, maxRowCheckCount); i++) {
            const row = rows[i];

            if (row.length === 0 || row[row.length - 1].trim())
                return false;
        }
        return true;
    }, [])

    // CSV Parsing using Papaparse
    useEffect(() => {
        if (formFields.files === null || formFields.files.length === 0)
            return;
        
        const file = formFields.files[0];

        Papa.parse(file, {
            complete: (result) => {
                const rows = result.data as ImportRows;

                const columnCount = getColumnCount(rows);
                
                // Remove all rows with fewer columns
                let fixedRows = rows.filter(row => row.length === columnCount);

                if (getLastColumnAlwaysEmpty(fixedRows)) {
                    fixedRows = fixedRows.map(row => row.slice(0, -1));
                }

                setRows(fixedRows);
            }
        });
    }, [formFields.files, getColumnCount, getLastColumnAlwaysEmpty]);

    const handleTextSelection = useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>, key: string) => {
        const { value, selectionStart, selectionEnd } = e.currentTarget;

        if (selectionStart === null || selectionEnd === null || selectionStart - selectionEnd === 0) {
            setTextSelectionHandling(null);
            return;
        }
        
        const textSelection = value.substring(selectionStart, selectionEnd);

        setTextSelectionHandling({ identifier: key, textSelection });
    }, [setTextSelectionHandling]);

    const parseRow = (group: ImportGroup, row: ImportRow) => {
        const dateColumn = formFields.dateColumn;
        const priceColumn = formFields.priceColumn;
        const quantityColumn = formFields.quantityColumn;

        const name = group.name;
        const tags = group.tags;

        if (dateColumn === null || priceColumn === null)
            return;

        const hash = getStringHash(row.join('|'));
        const alreadyImported = alreadyImportedHashes.includes(hash);

        const dateString = row[dateColumn];
        const priceString = row[priceColumn];
        const quantityString = quantityColumn !== null ? row[quantityColumn] : null;

        const date = parseDate(dateString, dateFormat);
        let price = parseNumber(priceString);
        let quantity = quantityString !== null ? parseNumber(quantityString) : 0;

        if (!date.isValid) 
            return;

        if (isNaN(price))
            price = 0;

        if (isNaN(quantity))
            quantity = 1;

        return { 
            name,
            tags,
            date,
            price: price * (formFields.invertPrices ? 1 : -1),
            quantity,
            hash,
            alreadyImported,
        }
    }

    const save = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const dateColumn = formFields.dateColumn;
        const priceColumn = formFields.priceColumn;

        if (importGroups === undefined || dateColumn === null || priceColumn === null || currentUser === null || currentUser === undefined)
            return;

        let createItemProps: CreateItemProps[] = [];
        for (const group of importGroups) {
            if (group.ignored)
                continue;

            for (const row of group.rows) {
                const parsedRow = parseRow(group, row);

                if (!parsedRow || parsedRow.alreadyImported)
                    continue;

                createItemProps.push({ 
                    name: parsedRow.name,
                    tagNames: parsedRow.tags,
                    date: parsedRow.date,
                    price: parsedRow.price,
                    quantity: parsedRow.quantity,
                    unit: 0,
                    repeatInterval: null,
                    importHash: parsedRow.hash,
                    existingItemReference: undefined
                })
            }
        }

        try {
            await createItems(createItemProps, currentUser.uid, availableTags);

            if (onClose) {
                onClose();
            }
        }
        catch (e: any) {
            console.error(e);

            const err: firebase.firestore.FirestoreError = e;

            setError('Could not save: '+err.message)
        }
    };
    
    const getAssignmentColumns = (allowUndefined?: boolean) => headerRow ? [
        (
            // Default
            <option key="-1" value={-1} disabled={!(allowUndefined ?? false)}>{t('app.dialog.import.selectColumn')}</option>
        ), 
        ...headerRow.map((column, i) => (
            // Values
            <option key={i} value={i}>{column}</option>
        )
    )] : undefined;

    const confirmSelectionIfModified = (field: keyof typeof formFields, value: number | null) => {
        if (!importGroupsModified) {
            return setFormField(field, value);
        }

        showConfirmDialog({
            title: t('app.dialog.import.confirm.title'),
            description: t('app.dialog.import.confirm.description'),
            okLabel: t('app.dialog.import.confirm.overwritten'),
            okButtonColor: 'warning',
            onOk: () => setFormField(field, value),
        });
    }

    const parseColumnSelectValue = (value: string) => {
        const number = parseInt(value);
        return number === -1 ? null : number;
    }

    return (
        <Dialog title={t('app.dialog.import.title')} {...dialogProps} onClose={onClose} onSave={e => save(e)} size="large" error={error}>
            <DialogSection className="p-4 mb-4 rounded-md bg-white dark:bg-gray-800">
                <Dropzone onFilesDrop={createSimpleChangeHandler('files')} files={formFields.files ?? undefined} />
            </DialogSection>
            {rows !== null && (<>
                {!emptyFile ? (<>
                    <DialogSection title={t('app.dialog.import.assignTitle')} className="p-4 mb-4 rounded-md bg-white dark:bg-gray-800">
                        <Label value={t('app.label.date')} className="bg-gray-100 dark:bg-gray-900">
                            <Select value={formFields.dateColumn ?? -1} onChange={createSelectChangeHandler('dateColumn', value => parseColumnSelectValue(value))} className="bg-gray-100">{getAssignmentColumns()}</Select>
                        </Label>
                        {detectedDateFormat === null && (<>
                            <Paragraph>{t('app.dialog.import.hintDateFormat')} <Link to={{ pathname: "https://en.wikipedia.org/wiki/Date_format_by_country" }} target="_blank">Wikipadia</Link>.</Paragraph>
                            <Validation message={(isDateFormatValid || formFields.customDateFormat.length === 0) ? null : 'The date format is not correct.'}>
                                <Label value={t('app.dialog.import.dateFormat')} className="bg-gray-100 dark:bg-gray-900">
                                    <Textfield value={formFields.customDateFormat} onChange={createChangeHandler('customDateFormat')} placeholder="dd.MM.yyyy" />
                                </Label>
                            </Validation>
                        </>)}
                        <Label value={t('app.dialog.import.itemName.title') + '' + t('app.dialog.import.itemName.span')} className="bg-gray-100 dark:bg-gray-900">
                            <Select value={formFields.nameColumn ?? -1} onChange={e => confirmSelectionIfModified('nameColumn', parseColumnSelectValue(e.target.value))} className="bg-gray-100">{getAssignmentColumns()}</Select>
                        </Label>
                        <Validation message={isPriceColumnValid ? null : t('app.dialog.import.hintNumbers')}>
                            <Label value={t('app.label.price')} className="bg-gray-100 dark:bg-gray-900">
                                <Select value={formFields.priceColumn ?? -1} onChange={createSelectChangeHandler('priceColumn', value => parseColumnSelectValue(value))} className="bg-gray-100">{getAssignmentColumns()}</Select>
                            </Label>
                        </Validation>

                        <h2 className="font-semibold text-gray-400 mb-2 mt-4">Optional</h2>
                        <Label value={t('app.dialog.import.comissioner.title') + ' ' + t('app.dialog.import.comissioner.span')} className="bg-gray-100 dark:bg-gray-900">
                            <Select value={formFields.comissionerColumn ?? -1} onChange={e => confirmSelectionIfModified('comissionerColumn', parseColumnSelectValue(e.target.value))} className="bg-gray-100">{getAssignmentColumns(true)}</Select>
                        </Label>
                        <Validation message={isQuantityColumnValid ? null : t('app.dialog.import.hintNumbers')}>
                            <Label value={t('app.dialog.import.quantity')} className="bg-gray-100 dark:bg-gray-900">
                                <Select value={formFields.quantityColumn ?? -1} onChange={createSelectChangeHandler('quantityColumn', value => parseColumnSelectValue(value))} className="bg-gray-100">{getAssignmentColumns(true)}</Select>
                            </Label>
                        </Validation>
                        
                        <h2 className="font-semibold text-gray-400 mb-2 mt-4">{t('app.dialog.import.otherOptions')}</h2>
                        <Checkbox className="w-full block" onChange={createChangeHandler('invertPrices')} text={t('app.dialog.import.invertPrices')} />
                    </DialogSection>

                    {allColumnsAssigned && importGroups && isDateFormatValid && isPriceColumnValid && (
                        <DialogSection title={t('app.dialog.import.tagTitle')} className="p-4 mb-4 rounded-md bg-white dark:bg-gray-800">
                            <Paragraph><b>{rows.length}</b> {t('app.dialog.import.itemsAnd')} <b>{importGroups.length}</b> {t('app.dialog.import.matchingName')}</Paragraph>

                            <Table className="w-full">
                                <TableHeader>
                                    <TableColumn className="w-auto pl-0 pr-0"></TableColumn>
                                    <TableColumn className="w-8/12">Name</TableColumn>
                                    <TableColumn className="w-4/12">Tags</TableColumn>
                                    <TableColumn className="w-auto p-0"></TableColumn>
                                </TableHeader>
                                <tbody>
                                    {importGroups.map(group => {
                                        if (group.ignored)
                                            return undefined;

                                        return (
                                            <React.Fragment key={group.identifier}>
                                                <TableRow className={classNames(group.ignored && 'opacity-50')}>
                                                    <TableCell className="">
                                                        <Button type="button" color="tertiary" value={group.expanded ? '↓' + group.rows.length : '→' + group.rows.length} onClick={() => updateGroup({ ...group, expanded: !group.expanded })} />
                                                    </TableCell>
                                                    <TableCell className="p-2">
                                                        <Textfield value={group.name} placeholder="Name" className="bg-gray-100 dark:bg-gray-700 break-all" multiline onChange={e => updateGroup({ ...group, name: e.target.value })} onSelect={(e) => handleTextSelection(e, group.identifier)} />
                                                        {textSelectionHandling && textSelectionHandling.identifier === group.identifier && (
                                                            <Button type="button" color="tertiary" value="Combine with similar names" onClick={() => combineGroupsWithNameSubstring(textSelectionHandling.textSelection, group) } />
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="p-2">
                                                        <Tagfield
                                                            placeholder={t('app.label.enterTagName')} 
                                                            availableTags={availableTags.map(tag => tag.name)}
                                                            selectedTags={group.tags}
                                                            value={group.tags}
                                                            onSelectionChange={selectedTags => updateGroup({ ...group, tags: selectedTags })}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                        <Checkbox text="" checked={group.ignored} onChange={e => updateGroup({ ...group, ignored: e.target.checked })} title="Ignore item" customIcon icon={<Trash />} />
                                                    </TableCell>
                                                    
                                                </TableRow>
                                                
                                                {group.expanded && (
                                                    <TableRow>
                                                        <TableCell className=""></TableCell>
                                                        <TableCell colSpan={3} className="col-span-full">
                                                            {/* Preview Table */}
                                                            <Table className="w-full">
                                                                <TableHeader>
                                                                    <TableColumn className="w-auto ">Name</TableColumn>
                                                                    <TableColumn className="w-auto">Price</TableColumn>
                                                                    <TableColumn className="w-auto">Date</TableColumn>
                                                                    <TableColumn className="w-auto">Quantity</TableColumn>
                                                                    <TableColumn className="w-auto">Tags</TableColumn>
                                                                </TableHeader>
                                                                <tbody>
                                                                    {group.rows.map((row, i) => {
                                                                        const parsedRow = parseRow(group, row);

                                                                        if (!parsedRow)
                                                                            return undefined;

                                                                        return (
                                                                            <TableRow key={i} className={classNames(parsedRow.alreadyImported && 'opacity-50')}>
                                                                                <TableCell className="w-6/12 break-all">{parsedRow.name}</TableCell>
                                                                                <TableCell className="">{currencyToString(parsedRow.price, userSettings.currency)}</TableCell>
                                                                                <TableCell className="">{dateTimeToDateString(parsedRow.date)}</TableCell>
                                                                                <TableCell className="">{quantityToString(parsedRow.quantity, t, 0)}</TableCell>
                                                                                <TableCell className="">
                                                                                    {parsedRow.tags.map((tag, i) => <TagLabel key={tag+i} value={tag} />)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                            <Button type="button" color="tertiary" value={t('app.dialog.import.ungroup') + '(' + group.rows.length + ')'} onClick={() => splitGroup(group)} />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </DialogSection>
                    )}
                </>) : (
                    <Paragraph>The imported file is empty.</Paragraph>
                )}
            </>)}

            {confirmDialog}
        </Dialog>
    );
};

export default FileImportDialog;