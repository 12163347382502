import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Paragraph, NoteText } from '../';

export interface DropzoneProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onFilesDrop: (files: FileList) => void;
    files?: FileList,
}

const Dropzone: React.FC<DropzoneProps> = ({
    className,
    accept,
    type,
    files,
    onFilesDrop,
    ...props
}) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | undefined>(files);
    const [showDragIndicator, setShowDragIndicator] = useState<boolean>(false);
    const dropzoneRef = useRef<HTMLDivElement>(null);

    const classes = classNames(
        className,
        "p-8 border-2",
        showDragIndicator ? 'border-solid border-purple-500' : 'border-dashed'
    );

    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (files: FileList) => {
        setSelectedFiles(files);
        onFilesDrop(files);
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault(); 
        
        handleFileChange(e.dataTransfer.files);
        setShowDragIndicator(false);
    }

    let dragEventTargets : EventTarget[] = [];

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        dragEventTargets.push(e.target);
        setShowDragIndicator(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        dragEventTargets = dragEventTargets.filter(x => x !== e.target);

        if (dragEventTargets.length === 0)
            setShowDragIndicator(false);
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files)
            handleFileChange(e.target.files);
    }

    const selectFilesManually = () => {
        inputRef.current?.click();
    }

    const fileNames = useMemo(() => {
        if (!selectedFiles)
            return;

        const fileNames: string[] = [];
        
        for (let i = 0; i < selectedFiles.length; i++) {
            const fileName = selectedFiles.item(i)?.name;
            if (fileName)
                fileNames.push(fileName);
        }

        return fileNames;
    }, [selectedFiles])

    return (
        <div ref={dropzoneRef} className={classes} onDrop={e => handleDrop(e)} onDragOver={e => e.preventDefault()} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} {...props}>
            <Paragraph className='text-center font-semibold'>
            {t('app.dialog.import.dropText')} <span className='bg-blue-500 hover:shadow-md text-white hover:border-transparent border border-blue-600 px-2 py-1 rounded-md cursor-pointer whitespace-nowrap' onClick={() => selectFilesManually()}>{t('app.dialog.import.selectFile')}</span>
            </Paragraph>
            <NoteText className='text-center font-semibold mt-2'>
                {t('app.dialog.import.hint')}
                </NoteText>
            {fileNames && (
                <Paragraph className='text-center font-semibold mt-2'>{fileNames?.join(', ')}</Paragraph>
            )}
            <input type="file" accept={accept} ref={inputRef} onChange={e => handleFileSelect(e)} hidden />
        </div>
    );
};

export default Dropzone;