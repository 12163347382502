import classNames from 'classnames';

const sizeClasses = {
    small: 'bg-white dark:bg-gray-800 p-2',
    medium: 'bg-white dark:bg-gray-800 p-6 col-span-2',
}

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    size?: keyof typeof sizeClasses;
}

const Card: React.FC<CardProps> = ({
    className,
    children,
    size = 'small',
    ...props
}) => {

    const classes = classNames(
        className,
        "text-black dark:text-white hover:bg-white cursor-pointer dark:hover:bg-gray-700 rounded-md hover:shadow-md relative",
        sizeClasses[size],
    );

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
};

export default Card;