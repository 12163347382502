import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { database } from '@lib/firebase';
import { useAuth } from '@modules/authentication/AuthProvider';
import BankConnection, { BankConnectionCollection, bankConnectionSchema } from '@models/BankConnection';
    
type Action =
    | { type: 'set-loading' }
    | { type: 'set-bankConnections'; bankConnections: BankConnection[] }

function reducer (state: BankConnectionCollection | undefined, action: Action): BankConnectionCollection | undefined {
    switch (action.type) {
        case 'set-loading':
            return undefined
        case 'set-bankConnections':
            return {
                ...state,
                bankConnections: action.bankConnections,
            }
        default:
            return state;
    }
}

const BankConnectionsContext = createContext<BankConnectionCollection | undefined>(undefined)

export const BankConnectionsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, undefined);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        return database.bankConnections
            .where('ownerUid', '==', currentUser.uid)
            .onSnapshot(snapshot => {
                console.log('Updating BankConnections');

                const bankConnections = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    try {
                        return bankConnectionSchema.parse({ ...docData, id: doc.id, ref: doc.ref }) as BankConnection;
                    }
                    catch (e) {
                        console.error(e, { originalObject: docData })
                        return null;
                    }
                }).filter((bankConnection): bankConnection is BankConnection => bankConnection !== null)
                
                dispatch({
                    type: 'set-bankConnections',
                    bankConnections: bankConnections
                })
            })
    }, [currentUser]);

    return (
        <BankConnectionsContext.Provider value={state}>
            {children}
        </BankConnectionsContext.Provider>
    )
}

export function useBankConnectionsOpt() {
    return useContext(BankConnectionsContext);
}

export function useBankConnections() {
    return useBankConnectionsOpt()!;
}

export function useBankConnection(bankConnectionId?: string) {
    const { bankConnections } = useBankConnections();

    const bankConnection = useMemo(() => {
        return bankConnections.find(bankConnection => bankConnection.id === bankConnectionId)
    }, [bankConnections, bankConnectionId]);

    return bankConnection;
}