const currencyCodesWithSigns = {
    THB: '฿',
    VND: '₫',
    EUR: '€',
    GBP: '£',
    NOK: 'kr',
    MXN: '₱',
    ZAR: 'R',
    RUB: '₽',
    PKR: '₨',
    ILS: '₪',
    USD: '$',
    KRW: '₩',
    JPY: '¥',
    CNY: '元',
}

export type CurrencyCode = keyof typeof currencyCodesWithSigns;

export const defaultCurrencyCode: CurrencyCode = 'EUR';

export const currencyCodes = Object.keys(currencyCodesWithSigns);

export function getCurrencySign(currencyCode: CurrencyCode | string) {
    if (currencyCode in currencyCodesWithSigns)
        return currencyCodesWithSigns[currencyCode as CurrencyCode];

    return currencyCodesWithSigns[defaultCurrencyCode];
}

export function stringToCurrencyCode(input: string) {
    if (input in currencyCodesWithSigns)
        return input as CurrencyCode;
    else
        return null;
}