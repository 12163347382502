import { DateTime } from "luxon";
import { z } from "zod";

export enum Weekday {
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
    sunday = 7,
}

export enum Month {
    january = 1,
    february = 2,
    march = 3,
    april = 4,
    may = 5,
    june = 6,
    july = 7,
    august = 8,
    september = 9,
    october = 10,
    november = 11,
    december = 12,
}

export const repeatIntervalSchema = z.discriminatedUnion("type", [
    z.object({ type: z.literal("daily"), days: z.number().positive() }),
    z.object({ type: z.literal("weekly"), weeks: z.number().positive(), weekday: z.nativeEnum(Weekday) }),
    z.object({ type: z.literal("monthly"), months: z.number().positive(), day: z.number() }),
    z.object({ type: z.literal("yearly"), years: z.number().positive(), day: z.number(), month: z.nativeEnum(Month) }),
])

type RepeatInterval = z.infer<typeof repeatIntervalSchema>

/* eslint-disable  @typescript-eslint/no-redeclare */
namespace RepeatInterval {
    export function getNextRepetitionDate(repeatInterval: RepeatInterval, start?: DateTime): DateTime {
        const startDate = (start ?? DateTime.now()).startOf('day');

        let nextDate: DateTime;

        switch (repeatInterval.type) {
            case 'daily': {
                // Get the next possible date.
                nextDate = startDate.plus({ days: repeatInterval.days });

                break;
            }

            case 'weekly': {
                // Get the next possible date.
                const startWeekday = startDate.weekday;
                const targetWeekday = repeatInterval.weekday as number;

                nextDate = startDate
                    .plus({ days: targetWeekday - startWeekday, weeks: repeatInterval.weeks });

                break;
            }

            case 'monthly': {
                // Get the next possible date.
                const targetDayInMonth = repeatInterval.day;

                nextDate = startDate
                    .set({ day: targetDayInMonth })
                    .plus({ months: repeatInterval.months });

                break;
            }

            case 'yearly': {
                // Get the next possible date.
                const targetMonthInYear = repeatInterval.month;
                const targetDayInYear = repeatInterval.day;

                nextDate = startDate
                    .set({ month: targetMonthInYear, day: targetDayInYear })
                    .plus({ years: repeatInterval.years });
                
                break;
            }
        }
                
        return nextDate;
    }

    export function getRepetitionCountBetweenDates(repeatInterval: RepeatInterval, start: DateTime, end: DateTime): number {
        const startDate = start.startOf('day');
        const endDate = end.startOf('day');

        switch (repeatInterval.type) {
            case 'daily': {
                const daysBetween = endDate.diff(startDate, 'days').days;

                return daysBetween;
            }

            case 'weekly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');

                // Get the weeks between the next date and the end date.
                const weeksBetween = endDate.diff(nextDate, 'weeks').weeks;

                return weeksBetween;
            }

            case 'monthly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');

                // Get the months between the next date and the end date.
                const monthsBetween = endDate.diff(nextDate, 'months').months;

                return monthsBetween;
            }

            case 'yearly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');
                    
                // Get the months between the next date and the end date.
                const yearsBetween = endDate.diff(nextDate, 'years').years;

                return yearsBetween;
            }
        }
    }

    export function getRepetitionsBetweenDates(repeatInterval: RepeatInterval, start: DateTime, end?: DateTime): DateTime[] {
        const startDate = start.startOf('day');
        const endDate = (end ?? DateTime.now()).startOf('day');

        switch (repeatInterval.type) {
            case 'daily': {
                const daysBetween = endDate.diff(startDate, 'days').days;

                const dates: DateTime[] = [];
                for (let days = 0; days < daysBetween; days += repeatInterval.days) {
                    dates.push(startDate.plus({ days: days }))
                }

                return dates;
            }

            case 'weekly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');

                // Get the weeks between the next date and the end date.
                const weeksBetween = endDate.diff(nextDate, 'weeks').weeks;

                const dates: DateTime[] = [];
                for (let weeks = 0; weeks < weeksBetween; weeks += repeatInterval.weeks) {
                    dates.push(nextDate.plus({ weeks: weeks }))
                }

                return dates;
            }

            case 'monthly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');

                // Get the months between the next date and the end date.
                const monthsBetween = endDate.diff(nextDate, 'months').months;

                const dates: DateTime[] = [];
                for (let months = 0; months < monthsBetween; months += repeatInterval.months) {
                    dates.push(nextDate.plus({ months: months }))
                }

                return dates;
            }

            case 'yearly': {
                // Get the next possible date.
                const nextDate = getNextRepetitionDate(repeatInterval, start).startOf('day');
                    
                // Get the months between the next date and the end date.
                const yearsBetween = endDate.diff(nextDate, 'years').years;

                const dates: DateTime[] = [];
                for (let years = 0; years < yearsBetween; years += repeatInterval.years) {
                    dates.push(nextDate.plus({ years: years }))
                }

                return dates;
            }
        }
    }
}

export default RepeatInterval;