export const colorClasses = {
    primary: 'fill-current text-black dark:text-white',
    secondary: 'fill-current text-white dark:text-black',
    alert: 'fill-current text-red-500',
    white: 'fill-current text-white dark:text-white',
    blue: 'fill-current text-blue-500',
    gray: 'fill-current text-gray-400'
}

export const sizeClasses = {
    small: 'w-5 h-5',
    medium: 'w-7 h-7',
    large: 'w-9 h-9',
    xlarge: 'w-11 h-11',
    xxlarge: 'w-13 h-13'
}

export default interface IconProps extends React.SVGProps<SVGSVGElement> {
    color?: keyof typeof colorClasses;
    size?: keyof typeof sizeClasses;
}