import { Card, CardHeader, Tag } from "@ui";
import { useGroups } from '@app/hooks/useGroups';
import { useTags } from '@app/hooks/useTags';
import { useHistory } from 'react-router-dom';
import Group from "@models/Group";
import routes from "@modules/routes/Routes";
import classNames from "classnames";

export interface GroupsListPanelProps {
    selectedGroup?: Group
}

const GroupsListPanel: React.FC<GroupsListPanelProps> = ({ selectedGroup }) => {
    const history = useHistory();

    const groupCollection = useGroups();
    const tagCollection = useTags();

    function handleGroupClick(e: React.MouseEvent, group: Group) {
        history.push(routes.app.groupDetails.build(group.id))
    }

    return (
        <>
            {groupCollection.groups.map((group, i) => (
                <Card key={i} className={classNames('mb-2 w-full', selectedGroup === group && 'ring-4')} onClick={e => handleGroupClick(e, group)}>
                    <CardHeader>{group.name}</CardHeader>
                    <div>
                        {Group.lookupReferencedTags(group, tagCollection.tags).map((tag, i) => (
                            <Tag key={i} color="action" value={tag.name} className="mr-2 mt-2" />
                        ))}
                    </div>
                </Card>
            ))}
        </>
    )
}

export default GroupsListPanel;