import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M4.5 0C4.22386 0 4 0.223858 4 0.5C4 0.776142 4.22386 1 4.5 1H15.5C15.7761 1 16 0.776142 16 0.5C16 0.223858 15.7761 0 15.5 0H4.5ZM4.5 3C4.22386 3 4 3.22386 4 3.5C4 3.77614 4.22386 4 4.5 4H15.5C15.7761 4 16 3.77614 16 3.5C16 3.22386 15.7761 3 15.5 3H4.5ZM4 6.5C4 6.22386 4.22386 6 4.5 6H15.5C15.7761 6 16 6.22386 16 6.5C16 6.77614 15.7761 7 15.5 7H4.5C4.22386 7 4 6.77614 4 6.5ZM4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10H15.5C15.7761 10 16 9.77614 16 9.5C16 9.22386 15.7761 9 15.5 9H4.5ZM4 12.5C4 12.2239 4.22386 12 4.5 12H15.5C15.7761 12 16 12.2239 16 12.5C16 12.7761 15.7761 13 15.5 13H4.5C4.22386 13 4 12.7761 4 12.5ZM4.5 15C4.22386 15 4 15.2239 4 15.5C4 15.7761 4.22386 16 4.5 16H15.5C15.7761 16 16 15.7761 16 15.5C16 15.2239 15.7761 15 15.5 15H4.5ZM0 3.5C0 3.22386 0.223858 3 0.5 3H1.5C1.77614 3 2 3.22386 2 3.5C2 3.77614 1.77614 4 1.5 4H0.5C0.223858 4 0 3.77614 0 3.5ZM0.5 0C0.223858 0 0 0.223858 0 0.5C0 0.776142 0.223858 1 0.5 1H1.5C1.77614 1 2 0.776142 2 0.5C2 0.223858 1.77614 0 1.5 0H0.5ZM0 6.5C0 6.22386 0.223858 6 0.5 6H1.5C1.77614 6 2 6.22386 2 6.5C2 6.77614 1.77614 7 1.5 7H0.5C0.223858 7 0 6.77614 0 6.5ZM0.5 9C0.223858 9 0 9.22386 0 9.5C0 9.77614 0.223858 10 0.5 10H1.5C1.77614 10 2 9.77614 2 9.5C2 9.22386 1.77614 9 1.5 9H0.5ZM0 12.5C0 12.2239 0.223858 12 0.5 12H1.5C1.77614 12 2 12.2239 2 12.5C2 12.7761 1.77614 13 1.5 13H0.5C0.223858 13 0 12.7761 0 12.5ZM0.5 15C0.223858 15 0 15.2239 0 15.5C0 15.7761 0.223858 16 0.5 16H1.5C1.77614 16 2 15.7761 2 15.5C2 15.2239 1.77614 15 1.5 15H0.5Z"/>
    </svg>
  );
}

export default Icon;