import { useState } from "react";

export default function useFormFields<T>(initialValues: T) {
    const [formFields, setFormFields] = useState<T>(initialValues);
    
    const setFormField = (key: keyof T, value: any) => {
        setFormFields((prev: T) => ({ ...prev, [key]: value }));
    }
    
    const createChangeHandler = (key: keyof T, parser?: (value: any) => any) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: any;

        switch (e.target.type) {
            case "checkbox":
            case "radio":
                value = e.target.checked;
                break;
            case "file":
                value = e.target.files;
                break;
            case "number":
                value = parseFloat(e.target.value);
                break;
            default:
                value = e.target.value;
                break;
        }

        setFormField(key, parser ? parser(value) : value);
    };

    const createSimpleChangeHandler = (key: keyof T, parser?: (value: any) => any) => (value: any) => {
        setFormField(key, parser ? parser(value) : value);
    };

    const createSelectChangeHandler = (key: keyof T, parser?: (value: any) => any) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormField(key, parser ? parser(e.target.value) : e.target.value);
    };

    const createDateChangeHandler = (key: keyof T, parser?: (value: any) => any) => (date: Date) => {
        setFormField(key, parser ? parser(date) : date);
    };

    return { formFields, createChangeHandler, createSimpleChangeHandler, createSelectChangeHandler, createDateChangeHandler, setFormFields, setFormField };
}
