import classNames from 'classnames';

export interface OffscreenContainerProps extends React.HTMLAttributes<HTMLDivElement> {
}

const OffscreenContainer: React.FC<OffscreenContainerProps> = ({
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
    )

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default OffscreenContainer;