import { GlobalEventHandler } from "@pages/GlobalEvents";
import { callableFunctions } from "./firebase";

// This is registered as a onAuthenticatedStartup event
const synchTransactionsEvent: GlobalEventHandler = () => {
    console.log("Attempting to synchronize transactions.");
    
    callableFunctions.transactions.syncTransactions({ bankConnectionId: 'njH2gr8bWZd9KK6wTCBF' }) // TODO: Make me dynamic
        .catch((e) => {
            console.warn("Unable to sync transactions. ", e);
        })
}

export default synchTransactionsEvent;