import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M3.5 7.5L3.5 7.5003L12.5 7.5H13C13.5 7.5 13.5 7.49895 13.5 7.49895L13.5 7.4973L13.5 7.49224L13.4998 7.47515C13.4996 7.46072 13.4993 7.44026 13.4986 7.41419C13.4973 7.36204 13.4946 7.28733 13.4895 7.19327C13.4792 7.0053 13.4586 6.73919 13.4178 6.42079C13.3662 6.01789 13.2814 5.52607 13.1414 5H15V15H1V5H2.85861C2.71865 5.52607 2.63384 6.01789 2.58218 6.42079C2.54136 6.73919 2.52085 7.0053 2.51052 7.19327C2.50535 7.28733 2.50272 7.36204 2.50138 7.41419C2.50072 7.44026 2.50037 7.46072 2.50019 7.47515L2.50003 7.49224L2.5 7.4973L2.5 7.49955C2.5 7.49955 2.5 7.5 3 7.5H3.5ZM3.89757 5C3.72821 5.56629 3.63028 6.10945 3.57407 6.54796C3.53676 6.83893 3.51822 7.08064 3.50901 7.24813C3.50442 7.33181 3.50216 7.39675 3.50106 7.43982C3.5005 7.46135 3.50024 7.47739 3.50011 7.48756L3.50001 7.49834L3.5 7.5H12.5L12.5 7.49834L12.4999 7.48756C12.4998 7.47739 12.4995 7.46135 12.4989 7.43982C12.4978 7.39675 12.4956 7.33181 12.491 7.24813C12.4818 7.08064 12.4632 6.83893 12.4259 6.54796C12.3697 6.10945 12.2718 5.56629 12.1024 5H3.89757ZM4.27353 4H11.7265C11.4447 3.39428 11.0669 2.83005 10.5632 2.39347C9.9517 1.86354 9.13071 1.5 8 1.5C6.86929 1.5 6.0483 1.86354 5.43684 2.39347C4.93309 2.83005 4.5553 3.39428 4.27353 4ZM12.8142 4H15C15.5523 4 16 4.44772 16 5V15C16 15.5523 15.5523 16 15 16H1C0.447715 16 0 15.5523 0 15V5C0 4.44772 0.447715 4 1 4H3.18582C3.517 3.16201 4.01833 2.29955 4.78191 1.63778C5.5767 0.948964 6.63071 0.5 8 0.5C9.36929 0.5 10.4233 0.948964 11.2181 1.63778C11.9817 2.29955 12.483 3.16201 12.8142 4Z"/>
  </svg>
  );
}

export default Icon;