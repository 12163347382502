import { DateTime } from "luxon";

export function groupArray<T, TKey extends string | number>(input: T[], groupBy: (obj: T) => TKey) {
    return input.reduce((objectsByKey, obj) => {
        const key = groupBy(obj);

        const values = objectsByKey[key] || [];

        // We must use push instead of concat as concat behaves differently for arrays as values
        values.push(obj);

        objectsByKey[key] = values;
        
        return objectsByKey;
    }, {} as { [id in TKey]: T[] });
}

export function groupArrayByMany<T, TKey extends string | number>(input: T[], groupBy: (obj: T) => TKey[]) {
    return input.reduce((objectsByKey, obj) => {
        const keys = groupBy(obj);
        
        for(const key of keys) {
            const values = objectsByKey[key] || [];

            // We must use push instead of concat as concat behaves differently for arrays as values
            values.push(obj);

            objectsByKey[key] = values;
        }
        
        return objectsByKey;
    }, {} as { [id in TKey]: T[] });
}

export function distinctArray<T>(input: T[]): T[] {
    return input.filter((value, index, self) => self.indexOf(value) === index);
}

export function arrayToDictionary<T, TKey extends string | number | symbol, TValue>(input: T[], getKey: (value: T) => TKey, getValue: (value: T) => TValue) {
    return Object.assign({}, ...input.map((x) => ({[getKey(x)]: getValue(x)}))) as { [id in TKey]: TValue };
}

interface SwitchCases<T> {
    case: (value: T, result: () => React.ReactNode) => SwitchCases<T>;
    default: (result: () => React.ReactNode) => SwitchCases<T>;
}

export function componentSwitch<T>(value: T, cases: (cases: SwitchCases<T>) => void) {

    var possibleCases: { value: T, result: () => React.ReactNode }[] = [];
    var defaultResult: (() => React.ReactNode) | null = null;

    var getSwitchCases: () => SwitchCases<T> = () => ({
        case: (value: T, result: () => React.ReactNode) => {
            possibleCases.push({ value: value, result });

            return getSwitchCases();
        },
        default: (result: () => React.ReactNode) => {
            defaultResult = result;

            return getSwitchCases();
        },
    })
    
    // getSwitchCases is recursive and will add all possible cases to the possibleCases array and sets defaultResult.
    cases(getSwitchCases());

    // Check if one of the cases is met
    for(const possibleCase of possibleCases) {
        if (possibleCase.value === value) {
            return possibleCase.result();
        }
    }

    // Check if the default case is defined
    if (defaultResult) {
        // Typescript wrongly assumes that defaultResult is always null.
        var fixedDefaultResult = defaultResult as (() => React.ReactNode);

        return fixedDefaultResult();
    }

    // None of the cases were met and default was not defined.
    return undefined;
}

export function advancedCompare(l: any, r: any) {
    if (typeof l === 'number' && typeof r === 'number') {
        return l - r;
    }
    else if (typeof l === 'string' && typeof r === 'string') {
        return l.localeCompare(r);
    }
    else if (l instanceof DateTime && r instanceof DateTime) {
        return l.toMillis() - r.toMillis();
    }
    else if (l instanceof Date && r instanceof Date) {
        return l.getTime() - r.getTime();
    }
    else if (Array.isArray(l) && Array.isArray(r)) {
        return l.length - r.length;
    }

    return 0;
}

export function getPercentageChange(oldNumber: number, newNumber: number){
    if (oldNumber === 0)
        return 100;

    var decreaseValue = oldNumber - newNumber;

    return (decreaseValue / oldNumber) * -100;
}

export function getStringHash(input: string) {
    var hash = 0;
    for (var i = 0; i < input.length; i++) {
        var code = input.charCodeAt(i);
        hash = ((hash<<5)-hash)+code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}