import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.14644 8.36048C6.04793 8.26197 5.99912 8.13257 6.00001 8.00346L6 7.99998C6 7.83662 6.07833 7.69157 6.19949 7.60032L9.44627 4.35354C9.64153 4.15828 9.95812 4.15828 10.1534 4.35354C10.3324 4.53253 10.3473 4.81346 10.1981 5.00944L10.1534 5.06065L7.71405 7.49998H14.8333C15.1095 7.49998 15.3333 7.72384 15.3333 7.99998C15.3333 8.27612 15.1095 8.49998 14.8333 8.49998H7.70016L10.1534 10.9532C10.3324 11.1322 10.3473 11.4131 10.1981 11.6091L10.1534 11.6603C9.97439 11.8393 9.69346 11.8542 9.49748 11.7051L9.44627 11.6603L6.14644 8.36048ZM2 9.99998C3.10457 9.99998 4 9.10455 4 7.99998C4 6.89541 3.10457 5.99998 2 5.99998C0.895431 5.99998 0 6.89541 0 7.99998C0 9.10455 0.895431 9.99998 2 9.99998Z"/>
    </svg>
  );
}

export default Icon;