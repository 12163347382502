import { Paragraph } from "@ui";
import { useState } from "react";
import { ButtonColors } from '@ui';
import Dialog from "../dialog/Dialog";
import { useTranslation } from "react-i18next";

export interface ConfirmDialogProps {
    title: string;
    description?: string;
    okLabel?: string;
    okButtonColor?: typeof ButtonColors;
    cancelLabel?: string;
    onOk?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, description, okLabel, okButtonColor, cancelLabel, onOk, onCancel, onClose }) => {
    const { t } = useTranslation();

    const submitDialog = async () => {
        onOk && onOk();
        onClose && onClose();
    }

    const cancelDialog = () => {
        onCancel && onCancel();
        onClose && onClose();
    }

    return (
        <Dialog 
            title={title} 
            closeLabel={cancelLabel ?? t('app.button.cancel')}
            saveLabel={okLabel ??  t('app.button.save')}
            saveButtonColor={okButtonColor}
            onSave={submitDialog}
            onClose={cancelDialog}
            allowNesting
        >
            {description && <Paragraph className="mb-2">{description}</Paragraph>}
        </Dialog>
    );
}

export default ConfirmDialog;

export interface ConfirmDialogHookProps {
    title: string;
    description?: string;
    okLabel?: string;
    okButtonColor?: typeof ButtonColors;
    cancelLabel?: string;
    onOk?: () => void;
    onCancel?: () => void;
}

export function useConfirmDialog() {
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogHookProps | null>(null);

    const showConfirmDialog = (props: ConfirmDialogHookProps) => {
        setConfirmDialogProps(props);
    }

    const closeConfirmDialog = () => {
        setConfirmDialogProps(null);
    }

    const confirmDialog = (
        confirmDialogProps && <ConfirmDialog {...confirmDialogProps} onClose={closeConfirmDialog} />
    );

    return [
        confirmDialog,
        showConfirmDialog,
    ] as [typeof confirmDialog, typeof showConfirmDialog];
} 

