import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path id="Union (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M7 4V3C7 2.44772 6.55228 2 6 2H2C1.44772 2 1 2.44772 1 3V13C1 13.5523 1.44772 14 2 14H14C14.5523 14 15 13.5523 15 13V5C15 4.44772 14.5523 4 14 4H7ZM14 3C15.1046 3 16 3.89543 16 5V13C16 14.1046 15.1046 15 14 15H2C0.895431 15 0 14.1046 0 13V3C0 1.89543 0.895431 1 2 1H6C7.10457 1 8 1.89543 8 3H14Z"/>
  </svg>
  );
}

export default Icon;
