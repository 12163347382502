import { useRef, useState } from "react";
import { useHistory } from "react-router-dom"
import firebase from 'firebase';
import { handleError, useAuth } from "@authentication/AuthProvider";
import HomepageLayout from "@modules/components/HomepageLayout";
import { Button, Textfield, Validation, LargeHeader } from "@ui";
import { ArrowLeftRounded } from "@icons";
import { Link as RouterLink } from 'react-router-dom';
import routes from "@modules/routes/Routes";
import { useTranslation } from "react-i18next";

const Forgot: React.FC = () => {
    const { sendPasswordResetMail, setAuthenticationNotification } = useAuth();
    const { t } = useTranslation();

    const emailRef = useRef<HTMLInputElement>(null);

    const [emailError, setEmailError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setEmailError(null);

        if (emailRef.current) {
            try {
                setLoading(true);
                await sendPasswordResetMail(emailRef.current.value);

                setAuthenticationNotification(t('authentication.resetMailSentBanner'));

                history.push('/');
            } catch (e) {
                const authError = handleError(e as firebase.auth.Error);

                if (authError) {
                    setEmailError(authError.message);
                }
            }
        }
        setLoading(false);
    }

    return (
        <HomepageLayout onSubmit={handleSubmit}>
            <LargeHeader className="mb-4">
                <RouterLink to={routes.authentication.login.build()}><ArrowLeftRounded className="mr-2 inline-flex" /></RouterLink>
                {t('authentication.forgot.title')}
            </LargeHeader>
            <div className="flex mb-8">
                <p className="text-gray-400">{t('authentication.forgot.description')}</p>
            </div>
            <Validation message={emailError}>
                <Textfield ref={emailRef} placeholder={t('authentication.forgot.emailPlaceholder')} autoComplete="off" />
            </Validation>
            <Button type="submit" className="w-full mt-4" size="large" value={t('authentication.forgot.sendButton')} loading={loading} />
        </HomepageLayout>
    )
}

export default Forgot;