import { useAuth } from "@modules/authentication/AuthProvider";
import { useEffect } from "react";


export type GlobalEventHandler = () => void | Promise<void>;

const onAuthenticatedSessionStartupEvents: GlobalEventHandler[] = [];
export const registerOnAuthenticatedSessionStartup = (fn: GlobalEventHandler) => {
    onAuthenticatedSessionStartupEvents.push(fn)
}

const useOnAuthenticatedSessionStartupEvent = async () => {
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            sessionStorage.removeItem("SessionStartupTriggeredForUser");
            console.log("Not authenticated");
            return;
        }

        // User is logged in
        const sessionStorageFlag = sessionStorage.getItem("SessionStartupTriggeredForUser")

        if (sessionStorageFlag === currentUser.uid) return;

        // User is logged in for the first time in this session
        sessionStorage.setItem("SessionStartupTriggeredForUser", currentUser.uid);

        // Trigger events
        for (const event of onAuthenticatedSessionStartupEvents) {
            try {
                event();
            }
            catch (e) {
                console.error("Error caused in onAuthenticatedSessionStartupEvent. ", { e });
            }
        }
    }, [currentUser]);
}


const GlobalEvents: React.FC = () => {
    useOnAuthenticatedSessionStartupEvent();
    
    return null;
}

export default GlobalEvents;