import { User } from "@icons"
import { Dropdown, Button, DropdownItem, DropdownSplitter, Textfield } from '@ui';
import { OffscreenContainer } from '@ui';
import { useState, useMemo } from 'react';
import UserSettingsDialog from "@modules/app/userSettingsDialog/UserSettingsDialog";
import ShortcutOverviewDialog from "@modules/app/userSettingsDialog/ShortcutOverviewDialog";
import useKeyboardShortcut from "@hooks/useKeyboardShortcut";
import { useAuth } from "@authentication/AuthProvider";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export interface NavigationBarProps extends React.HTMLAttributes<HTMLDivElement> {
    leftItems?: React.ReactNode;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
    leftItems,
    className,
    children,
    ...props
}) => {
    const { logout } = useAuth();
    const history = useHistory();

    const { t } = useTranslation();

    const [showSettingsDropdown, setShowSettingsDropdown] = useState<boolean>(false);
    const [showUserSettingsDialog, setShowUserSettingsDialog] = useState<boolean>(false);
    const [showShortcutOverviewDialog, setShowShortcutOverviewDialog] = useState<boolean>(false);


    useKeyboardShortcut("u", () => {
        if (!isShowingDialog)
        setShowUserSettingsDialog(true);
    });

    useKeyboardShortcut("k", () => {
        if (!isShowingDialog)
        setShowShortcutOverviewDialog(true);
    });

    useKeyboardShortcut(['Shift', 'L'], () => {
        handleLogoutClick();
    });

    const isShowingDialog = useMemo(() => {
        return showUserSettingsDialog || showShortcutOverviewDialog;
    }, [showUserSettingsDialog, showShortcutOverviewDialog]);


    async function handleLogoutClick() {
        try {
            await logout();
            history.push('/');
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="w-full bg-gray-100 dark:bg-voodooGray">
            <nav className="p-2 pt-0 md:p-4 h-22">
                <div className="flex items-center justify-between flex-wrap">
                    {/* Left items */}
                    <div className="w-auto flex-shrink-0 items-center mt-2 mr-1 md:mr-2 md:mt-0">
                        {leftItems}
                    </div>
                    {/* Center items */}
                    <div className="order-2 mt-2 md:mt-0 flex-grow w-auto">
                        {/* Search bar */}
                        <Textfield type="search" placeholder={t('app.label.searchDescription')} className="appearance-none bg-white"></Textfield>
                    </div>
                    {/* Right items */}
                    <div className="order-1 sm:order-3 mt-2 flex-shrink-0 md:mt-0 md:ml-2">
                        {/* Settings */}
                        <OffscreenContainer className="relative">
                            <Button color="tertiary" icon={<User color="blue" size="medium" />} title={t('app.label.settings')} onClick={() => setShowSettingsDropdown(true)} />
                            {showSettingsDropdown && (
                                <Dropdown onClose={() => setShowSettingsDropdown(false)}>
                                    <DropdownItem onClick={() => setShowUserSettingsDialog(true)}>{t('app.label.settings')}</DropdownItem>
                                    <DropdownItem onClick={() => setShowShortcutOverviewDialog(true)}>{t('app.label.shortcuts')}</DropdownItem>
                                    <DropdownSplitter />
                                    <DropdownItem onClick={handleLogoutClick}>{t('app.label.logout')}</DropdownItem>
                                </Dropdown>
                            )}
                        </OffscreenContainer>
                    </div>
                </div>
            </nav>

            {/* Portals */}
            {showUserSettingsDialog && <UserSettingsDialog onClose={() => setShowUserSettingsDialog(false)} />}
            {showShortcutOverviewDialog && <ShortcutOverviewDialog onClose={() => setShowShortcutOverviewDialog(false)} />}
        </div>
    );
};

export default NavigationBar;