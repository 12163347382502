import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
  <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M8.5 0H7.5V8H8.5V0ZM0 8.00001C0 4.21256 2.63196 1.03983 6.16667 0.21106V1.24254C3.18989 2.04821 1 4.76837 1 8.00001C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8.00001C15 4.76837 12.8101 2.04821 9.83333 1.24254V0.21106C13.368 1.03983 16 4.21256 16 8.00001C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8.00001Z"/>
</svg>
  );
}

export default Icon;