import { createContext, useEffect, useState, useContext } from 'react';
import { database } from '@lib/firebase';
import UserSettings, { getDefaultUserSettings, userSettingsSchema } from '@models/UserSettings';
import { useAuth } from '../../authentication/AuthProvider';

const UserSettingsContext = createContext<UserSettings | undefined>(undefined);

export const UserSettingsProvider: React.FC = ({ children }) => {
    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined);
    const { currentUser } = useAuth();

    const userId = currentUser?.uid;

    useEffect(() => {
        if (!userId) {
           setUserSettings(undefined); 
        } else {
            return database.userSettings
                .doc(userId)
                .onSnapshot(doc => {
                    console.log('Updating User Settings');
                    
                    if (!doc.exists) {
                        setUserSettings(getDefaultUserSettings(userId));
                    } else {
                        const docData = doc.data();
                        try {
                            const userSettings = userSettingsSchema.parse({ ...docData, ref: doc.ref }) as UserSettings;
                            setUserSettings(userSettings);
                            return;
                        }
                        catch (e) {
                            console.error(e, { originalObject: docData })
                            return;
                        }
                    }
                });
        }
    }, [userId]);

    return (
        <UserSettingsContext.Provider value={userSettings}>
            {children}
        </UserSettingsContext.Provider>
    );
}

export function useUserSettingsOpt() {
    return useContext(UserSettingsContext);
}

export function useUserSettings() {
    return useUserSettingsOpt()!;
}