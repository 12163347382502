import classNames from 'classnames';

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
    value: string;
    inline?: boolean;
    disabled?: boolean;
    footer?: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({
    value,
    inline = false,
    disabled = false,
    footer,
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
        'items-center rounded-md bg-white dark:bg-gray-800 p-1 w-full',
    )

    const nameClasses = inline 
        // Inline
        ? classNames(
            'ml-2 text-base font-semibold dark:text-white',
            disabled && 'text-gray-400'
        )
        // Not inline
        : classNames(
            'p-2 font-semibold whitespace-no-wrap dark:text-white w-1/2',
            disabled && 'text-gray-600 dark:text-opacity-80'
        )

    return inline ? (
        <label className={classes} {...props}>
            {children}
            <span className={nameClasses}>{value}</span>
        </label>
    ) : (
        <div className={classes}>
            <div className="flex">
                <span className={nameClasses}>{value}</span>
                <div className="w-1/2 text-right">
                    {children}
                </div>  
            </div>
            {footer && (
                <div className="w-full p-2">
                    {footer}
                </div>
            )}
        </div>
    );
};

export default Label;