import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import './Datefield.css';

export interface DatefieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onDateChange: (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => void;
    selected: Date | null | undefined;
}

const useDatefieldLocale = () => {
    const { t } = useTranslation();

    const daysTranslations = t('app.conversionHelpers.workdays', { returnObjects: true });
    const monthsTranslations = t('app.conversionHelpers.months', { returnObjects: true });
    const todayTranslation = t('app.button.today');

    const days = [
        daysTranslations.sunday,
        daysTranslations.monday,
        daysTranslations.tuesday,
        daysTranslations.wednesday,
        daysTranslations.thursday,
        daysTranslations.friday,
        daysTranslations.saturday,
    ].map(x => (x ?? '-').substring(0, 2));

    const months = [
        monthsTranslations.january,
        monthsTranslations.february,
        monthsTranslations.march,
        monthsTranslations.april,
        monthsTranslations.may,
        monthsTranslations.june,
        monthsTranslations.july,
        monthsTranslations.august,
        monthsTranslations.september,
        monthsTranslations.october,
        monthsTranslations.november,
        monthsTranslations.december,
    ];

    const locale = {
        localize: {
            day: (n: any) => days[n],
            month: (n: any) => months[n],
            today: todayTranslation,
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    }

    return locale;
}

const Datefield: React.FC<DatefieldProps> = ({
    className,
    selected,
    onDateChange,
    ...props
}) => {
    const locale = useDatefieldLocale();

    const classes = classNames(
        className,
        'py-0.5 px-2 bg-white rounded-md text-blue-500 font-semibold text-right dark:bg-gray-800 appearance-none items-stretch',
    );

    const customInput = (
        <input type="text" {...props} className={classes} onChange={undefined} value={undefined} />
    );

    return (
        <DatePicker
            customInput={customInput}
            previousMonthButtonLabel="←"
            nextMonthButtonLabel="→"
            popperClassName="react-datepicker-right"
            onChange={onDateChange}
            selected={selected}
            locale={locale as any}
            inline
            yearDropdownItemNumber={10}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton={locale.localize.today}
        />
    );
};

export default Datefield;