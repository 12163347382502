import classNames from 'classnames';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    inline?: boolean
}

const Select: React.FC<SelectProps> = ({
    inline = false,
    className,
    children,
    ...props
}) => {

    const classes = inline
        // Inline
        ? classNames(
            className,
            'p-2 mx-1 appearance-none focus:outline-none text-blue-500 font-semibold dark:bg-transparent',
        )
        // Not inline
        : classNames(
            className,
            'p-2 bg-white appearance-none focus:outline-none whitespace-no-wrap text-blue-500 font-semibold dark:bg-transparent w-full',
        )

    return (
        <select className={classes} {...props}>
            {children}
        </select>
    );
};

export default Select;