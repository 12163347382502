import { Textfield, Label, Numberfield, Datefield, Link, Select, Tagfield, NoteText } from '@ui';
import { useAuth } from '@authentication/AuthProvider';
import useFormFields from "@hooks/useFormFields";
import Tag from '@models/Tag';
import Item from '@models/Item';
import Dialog, { DialogProps, DialogSection } from '@app/dialog/Dialog';
import { Trash } from '@icons';
import { useTranslation } from "react-i18next";
import { getCurrencySign } from '@lib/currencies';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { componentSwitch } from '@lib/helpers';
import RepeatInterval, { Month, Weekday, repeatIntervalSchema } from '@models/RepeatInterval';
import { dateTimeToDateString } from '@lib/stringConversion';
import { Trans } from 'react-i18next';
import { deleteItem, createItem } from '@app/hooks/useItems';
import { DateTime } from 'luxon';

export interface AddItemProps {
    editItem?: Item | null | undefined;
    availableTags: Tag[];
    onDelete?: () => void;
}

const AddItemDialog: React.FC<AddItemProps & DialogProps> = ({ editItem, availableTags, onClose, onDelete, ...dialogProps }) => {
    const { currentUser } = useAuth();
    const userSettings = useUserSettings();

    const { t } = useTranslation();

    const mode = editItem ? 'edit' : 'add';

    const initialTags = editItem && Item.lookupReferencedTags(editItem, availableTags);
    const initialTagNames = initialTags?.map(tag => tag.name);

    const { formFields, createChangeHandler, createSelectChangeHandler, createDateChangeHandler, setFormField } = useFormFields({
        name: editItem?.name ?? '',
        tags: initialTagNames ?? [],
        unit: (editItem?.unit ?? 0).toString(),
        price: editItem?.price ?? 0,
        quantity: editItem?.quantity ?? 0,
        date: (editItem?.date ?? DateTime.now()).toJSDate(),
        
        repeatIntervalType: editItem?.repeatInterval?.type ?? 'none',
        repeatIntervalDailyDays: editItem?.repeatInterval?.type === 'daily' ? editItem?.repeatInterval?.days : 1,
        repeatIntervalWeeklyWeeks: editItem?.repeatInterval?.type === 'weekly' ? editItem?.repeatInterval?.weeks : 1,
        repeatIntervalWeeklyWeekday: (editItem?.repeatInterval?.type === 'weekly' ? editItem?.repeatInterval?.weekday : Weekday.monday) as number,
        repeatIntervalMonthlyMonths: editItem?.repeatInterval?.type === 'monthly' ? editItem?.repeatInterval?.months : 1,
        repeatIntervalMonthlyDay: editItem?.repeatInterval?.type === 'monthly' ? editItem?.repeatInterval?.day : 1,
        repeatIntervalYearlyYears: editItem?.repeatInterval?.type === 'yearly' ? editItem?.repeatInterval?.years : 1,
        repeatIntervalYearlyDay: editItem?.repeatInterval?.type === 'yearly' ? editItem?.repeatInterval?.day : 1,
        repeatIntervalYearlyMonth: (editItem?.repeatInterval?.type === 'yearly' ? editItem?.repeatInterval?.month : Month.january) as number,
    });

    if (!currentUser)
        return null;

    const repeatInterval: RepeatInterval | null = (() => {
        try {
            switch (formFields.repeatIntervalType) {
                case 'daily':
                    return repeatIntervalSchema.parse({ type: formFields.repeatIntervalType, days: formFields.repeatIntervalDailyDays });
                case 'weekly':
                    return repeatIntervalSchema.parse({ type: formFields.repeatIntervalType, weeks: formFields.repeatIntervalWeeklyWeeks, weekday: formFields.repeatIntervalWeeklyWeekday });
                case 'monthly':
                    return repeatIntervalSchema.parse({ type: formFields.repeatIntervalType, months: formFields.repeatIntervalMonthlyMonths, day: formFields.repeatIntervalMonthlyDay });
                case 'yearly':
                    return repeatIntervalSchema.parse({ type: formFields.repeatIntervalType, years: formFields.repeatIntervalYearlyYears, day: formFields.repeatIntervalYearlyDay, month: formFields.repeatIntervalYearlyMonth });
                default:
                    return null;
            }
        } catch (e) {
            console.info("Not a valid repetition interval", e);
            return null;
        }
    })()

    const save = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        let fixedName = formFields.name;
        if (!fixedName || !fixedName.trim())
            fixedName = t('app.dialog.addItemDialog.defaultName');


        const definedTags = formFields.tags;

        createItem(
            editItem?.ref,
            fixedName,
            currentUser.uid,
            parseInt(formFields.unit),
            formFields.price,
            formFields.quantity,
            DateTime.fromJSDate(formFields.date),
            repeatInterval,
            null,
            definedTags,
            availableTags,
        )
            .then(() => {
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const handleDelete = (e: React.SyntheticEvent) => {
        if (!editItem)
            return;

        deleteItem(editItem)
            .then(() => {
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    return (
        <Dialog title={`${mode === 'add' ? 'Add' : 'Edit'} Item`} {...dialogProps} onClose={onClose} onSave={e => save(e)}>
            <DialogSection>
                <Textfield autoFocus placeholder={t('app.label.itemDescription')} value={formFields.name} onChange={createChangeHandler("name")} />
                <Tagfield
                    placeholder={t('app.label.enterTagName')}
                    availableTags={availableTags.map(tag => tag.name)}
                    selectedTags={formFields.tags}
                    value={formFields.tags}
                    onSelectionChange={(selectedTags => setFormField('tags', selectedTags))}
                />                
                <Datefield selected={formFields.date} onDateChange={createDateChangeHandler("date")} className="" />
                <Label value={t('app.dialog.addItemDialog.priceLabel', { currency: getCurrencySign(userSettings.currency) })}>
                    <Numberfield value={formFields.price} onChange={createChangeHandler("price")} step="0.01" className="text-right focus:select-all" decimalPlaces={2} placeholder="0,00" />
                </Label>
                <Label value={t('app.label.quantity')}>
                    <Numberfield value={formFields.quantity} onChange={createChangeHandler("quantity")} step="0.01" className="text-right focus:select-all" decimalPlaces={2} />
                </Label>
                <Label value={t('app.label.unit')}>
                    <Select onChange={createSelectChangeHandler("unit")} defaultValue={formFields.unit} dir="rtl">
                        <option value={0}>{t('app.units.piece')}</option>
                        <option value={1}>{t('app.units.kilogram')}</option>
                        <option value={2}>{t('app.units.ton')}</option>
                        <option value={3}>{t('app.units.liter')}</option>
                        <option value={4}>{t('app.units.meter')}</option>
                        <option value={5}>{t('app.units.kilometer')}</option>
                        <option value={6}>{t('app.units.cubicmeter')}</option>
                        <option value={7}>{t('app.units.kwh')}</option>
                    </Select>
                </Label>

                <Label value={t('app.label.repeat')} children={
                    <Select onChange={createSelectChangeHandler("repeatIntervalType")} defaultValue={formFields.repeatIntervalType} dir="rtl">
                        <option value="none">{t('app.dialog.addItemDialog.repeatModes.none.name')}</option>
                        <option value="daily">{t('app.dialog.addItemDialog.repeatModes.daily.name')}</option>
                        <option value="weekly">{t('app.dialog.addItemDialog.repeatModes.weekly.name')}</option>
                        <option value="monthly">{t('app.dialog.addItemDialog.repeatModes.monthly.name')}</option>
                        <option value="yearly">{t('app.dialog.addItemDialog.repeatModes.yearly.name')}</option>
                    </Select>
                } footer={
                    formFields.repeatIntervalType !== 'none' && <>
                        <div className='dark:text-white'>
                            {componentSwitch(formFields.repeatIntervalType, switcher => switcher
                                .case("daily", () => <>
                                    <Trans i18nKey="app.dialog.addItemDialog.repeatModes.daily.text">
                                        <Numberfield inline min={1} value={formFields.repeatIntervalDailyDays} onChange={createChangeHandler('repeatIntervalDailyDays')} />
                                    </Trans>
                                </>)
                                .case("weekly", () => <>
                                    <Trans i18nKey="app.dialog.addItemDialog.repeatModes.weekly.text">
                                        <Numberfield inline min={1} value={formFields.repeatIntervalWeeklyWeeks} onChange={createChangeHandler('repeatIntervalWeeklyWeeks')} />
                                        <Select inline defaultValue={formFields.repeatIntervalWeeklyWeekday} onChange={createSelectChangeHandler('repeatIntervalWeeklyWeekday', val => parseInt(val))}>
                                            <option value={1}>{t('app.conversionHelpers.workdays.monday')}</option>
                                            <option value={2}>{t('app.conversionHelpers.workdays.tuesday')}</option>
                                            <option value={3}>{t('app.conversionHelpers.workdays.wednesday')}</option>
                                            <option value={4}>{t('app.conversionHelpers.workdays.thursday')}</option>
                                            <option value={5}>{t('app.conversionHelpers.workdays.friday')}</option>
                                            <option value={6}>{t('app.conversionHelpers.workdays.saturday')}</option>
                                            <option value={7}>{t('app.conversionHelpers.workdays.sunday')}</option>
                                        </Select>
                                    </Trans>
                                </>)
                                .case("monthly", () => <>
                                    <Trans i18nKey="app.dialog.addItemDialog.repeatModes.monthly.text">
                                        <Numberfield inline min={1} value={formFields.repeatIntervalMonthlyMonths} onChange={createChangeHandler('repeatIntervalMonthlyMonths')}/>
                                        <Numberfield inline min={1} max={31} value={formFields.repeatIntervalMonthlyDay} onChange={createChangeHandler('repeatIntervalMonthlyDay')} />
                                    </Trans>
                                </>)
                                .case("yearly", () => <>
                                    <Trans i18nKey="app.dialog.addItemDialog.repeatModes.yearly.text">
                                        <Numberfield inline min={1} value={formFields.repeatIntervalYearlyYears} onChange={createChangeHandler('repeatIntervalYearlyYears')}/>
                                        <Numberfield inline min={1} max={31} value={formFields.repeatIntervalYearlyDay} onChange={createChangeHandler('repeatIntervalYearlyDay')}/>
                                        <Select inline defaultValue={formFields.repeatIntervalYearlyMonth} onChange={createSelectChangeHandler('repeatIntervalYearlyMonth', val => parseInt(val))}>
                                            <option value={1}>{t('app.conversionHelpers.months.january')}</option>
                                            <option value={2}>{t('app.conversionHelpers.months.february')}</option>
                                            <option value={3}>{t('app.conversionHelpers.months.march')}</option>
                                            <option value={4}>{t('app.conversionHelpers.months.april')}</option>
                                            <option value={5}>{t('app.conversionHelpers.months.may')}</option>
                                            <option value={6}>{t('app.conversionHelpers.months.june')}</option>
                                            <option value={7}>{t('app.conversionHelpers.months.july')}</option>
                                            <option value={8}>{t('app.conversionHelpers.months.august')}</option>
                                            <option value={9}>{t('app.conversionHelpers.months.september')}</option>
                                            <option value={10}>{t('app.conversionHelpers.months.october')}</option>
                                            <option value={11}>{t('app.conversionHelpers.months.november')}</option>
                                            <option value={12}>{t('app.conversionHelpers.months.december')}</option>
                                        </Select>
                                    </Trans>
                                </>)
                            )}

                            {repeatInterval && 
                                <NoteText className='mt-2'>
                                    {t('app.dialog.addItemDialog.repeatNextDate', { date: dateTimeToDateString(RepeatInterval.getNextRepetitionDate(repeatInterval, DateTime.fromJSDate(formFields.date))) })}
                                </NoteText>
                            }
                        </div>
                    </>
                }/>

                {mode === 'edit' && (
                    <Link onClick={(e) => handleDelete(e)} to="#">
                        <Trash color="alert" className="mr-2 inline-flex" /> {t('app.button.delete')}
                    </Link>
                )}
            </DialogSection>
        </Dialog>
    )
};

export default AddItemDialog;