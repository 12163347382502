import classNames from 'classnames';

export interface ValidationProps extends React.HTMLAttributes<HTMLDivElement> {
    message?: string | null;
}

const Validation: React.FC<ValidationProps> = ({
    message,
    className,
    children,
    ...props
}) => {
    const invalid = !!message;

    const classes = classNames(
        className,
        'font-semibold bg-red-500 text-white p-1 text-left rounded-md text-xs mt-1',
    )

    return invalid ? (
        <>
            {children}
            <div className={classes} {...props}>
                {message}
            </div>
        </>
    ) : (
        <>
            {children}
        </>
    );
};

export default Validation;