import { useTags } from "@app/hooks/useTags";
import { Redirect, useParams } from "react-router-dom";
import DetailsPage from "@app/detailsPage/DetailsPage";
import { useItemGroup } from '@app/hooks/useItems';

interface RouteParams {
    itemName: string;
}

const ItemsDetailsPage: React.FC = () => {
    const { itemName: itemNameEncoded } = useParams<RouteParams>();

    const itemName = decodeURIComponent(itemNameEncoded);

    const itemGroup = useItemGroup(itemName);
    const tagCollection = useTags();

    // If the group was not found
    if (!itemGroup) {
        return <Redirect to="/app" />
    }

    const tagsInGroup = itemGroup.lookupReferencedTags(tagCollection.tags);

    return <DetailsPage items={itemGroup.items ?? []} tags={tagsInGroup} name={itemGroup.name ?? ''} />
}

export default ItemsDetailsPage;