import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.80055 7.60034C9.92169 7.69159 10 7.83663 10 7.99998C10 8.001 10 8.00203 10 8.00305C10.001 8.1323 9.95219 8.26186 9.85358 8.36048L6.55375 11.6603L6.50255 11.7051C6.30656 11.8542 6.02563 11.8393 5.84664 11.6603L5.80189 11.6091C5.65274 11.4131 5.66765 11.1322 5.84664 10.9532L8.29986 8.49998H1.16669C0.890545 8.49998 0.666687 8.27612 0.666687 7.99998C0.666687 7.72384 0.890544 7.49998 1.16669 7.49998H8.28597L5.84664 5.06065L5.80189 5.00944C5.65274 4.81346 5.66765 4.53253 5.84664 4.35354C6.0419 4.15828 6.35849 4.15828 6.55375 4.35354L9.80055 7.60034ZM12 7.99998C12 9.10455 12.8955 9.99998 14 9.99998C15.1046 9.99998 16 9.10455 16 7.99998C16 6.89541 15.1046 5.99998 14 5.99998C12.8955 5.99998 12 6.89541 12 7.99998Z"/>
    </svg>
  );
}

export default Icon;