import { modelSchema } from './Model';
import { z } from "zod";

// Tags
export const tagSchema = modelSchema.extend({
    name: z.string()
        .catch("-"),
})

type Tag = z.infer<typeof tagSchema>;

export default Tag;

// Tag Collection
export const tagCollectionSchema = z.object({
    tags: z.array(tagSchema),
});

export type TagCollection = z.infer<typeof tagCollectionSchema>;