import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '@authentication/AuthProvider';
import { useGroupsOpt } from '@app/hooks/useGroups';
import { useTagsOpt } from '@app/hooks/useTags';
import { useUserSettingsOpt } from '@app/hooks/useUserSettings';
import LoadingOverlay from '@modules/components/loadingOverlay/LoadingOverlay';
import routes from './Routes';

interface AppRouteProps extends RouteProps {
    component: any;
}

// A route that only works if the user is signed in. 
// Otherwise it will redirect to the login page
const AppRoute: React.FC<AppRouteProps> = ({ component: Component, ...rest }: AppRouteProps) => {
    const { currentUser } = useAuth();

    const groupCollection = useGroupsOpt();
    const tagCollection = useTagsOpt();
    const userSettings = useUserSettingsOpt();
    
    const checkIfLoading = () => {
        return groupCollection === undefined || tagCollection === undefined || userSettings === undefined;
    }

    function componentIfLoaded(props: any) {
        return checkIfLoading() ? (
            <LoadingOverlay />
        ) : (
            <Component {...props} />
        )
    }

    return (
        <Route {...rest} render={props => {
            return currentUser
                // If logged in, render the component
                ? componentIfLoaded(props)
                // Otherwise redirect to the login page
                : <Redirect to={routes.authentication.login.build()} />
        }} />
    );
};

export default AppRoute;