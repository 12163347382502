import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5 2C15.7761 2 16 2.22386 16 2.5V13.5C16 13.7761 15.7761 14 15.5 14C15.2239 14 15 13.7761 15 13.5V2.5C15 2.22386 15.2239 2 15.5 2ZM12.8536 8.36054C12.9521 8.26203 13.0009 8.13265 13 8.00354L13 8.00006C13 7.83675 12.9217 7.69172 12.8006 7.60047L9.55372 4.3536C9.35846 4.15834 9.04188 4.15834 8.84662 4.3536C8.66762 4.53259 8.65271 4.81352 8.80187 5.0095L8.84662 5.06071L11.286 7.50006H2.5C2.22386 7.50006 2 7.72392 2 8.00006C2 8.2762 2.22386 8.50006 2.5 8.50006H11.2998L8.84662 10.9533C8.66762 11.1323 8.65271 11.4132 8.80187 11.6092L8.84662 11.6604C9.02561 11.8394 9.30654 11.8543 9.50252 11.7051L9.55372 11.6604L12.8536 8.36054Z"/>
    </svg>
  );
}

export default Icon;