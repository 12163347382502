import React, { forwardRef } from 'react';
import { TypographyClasses } from '../';
import classNames from 'classnames';

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    multiline?: boolean;
    textStyle?: keyof typeof TypographyClasses;
    focusOnly?: boolean;
}

const Textfield = forwardRef<HTMLInputElement, TextFieldProps>(({ className, multiline = false, textStyle, focusOnly, ...props }, ref) => {
    
    const classes = classNames(
        className,
        'p-2 w-full disabled:cursor-not-allowed disabled:opactity-50 dark:bg-transparent disabled:opacity-50',
        focusOnly ? 'bg-transparent focus:bg-white border-0' : 'rounded-md',
        textStyle ? TypographyClasses[textStyle] : 'text-blue-500 font-semibold',
    );

    return multiline ? (
        <textarea ref={ref} className={classes} {...props as any} />
    ) : (
        <input ref={ref} type="text" className={classes} {...props} />
    );
});

export default Textfield;