import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M10.7867 1.47559e-05L14.4231 0.0148171C15.283 0.018499 15.9809 0.715803 15.9852 1.57562L16 5.20514C16.0015 5.66542 15.8004 6.04743 15.436 6.4388L6.49626 15.3658C5.74785 16.1136 4.41648 16.2411 3.70326 15.5273L0.476379 12.3062C-0.238825 11.5922 -0.117032 10.2544 0.629896 9.50814L9.42834 0.722188C9.93926 0.214589 10.2708 -0.00206023 10.7867 1.47559e-05ZM14.419 1.01481L10.7827 1.00001C10.6655 0.999535 10.5803 1.02667 10.4152 1.16645L10.292 1.27727L10.1377 1.42706L9.86697 1.69874L1.33671 10.2155C0.96834 10.5836 0.904081 11.2261 1.13314 11.5402L1.18285 11.5985L4.41064 14.8205C4.67592 15.086 5.30707 15.0595 5.71193 14.7284L5.78971 14.6582L14.6255 5.83875C14.8829 5.58127 14.9808 5.41642 14.9974 5.26085L15 5.20922L14.9852 1.58062C14.9837 1.29342 14.7676 1.05546 14.4897 1.0195L14.419 1.01481ZM12.3333 1.66669C11.2287 1.66669 10.3333 2.56206 10.3333 3.66647C10.3333 4.7709 11.2287 5.66669 12.3333 5.66669C13.4377 5.66669 14.3333 4.77072 14.3333 3.66647C14.3333 2.56225 13.4377 1.66669 12.3333 1.66669ZM12.3333 2.66669C12.8856 2.66669 13.3333 3.11464 13.3333 3.66647C13.3333 4.21843 12.8854 4.66669 12.3333 4.66669C11.781 4.66669 11.3333 4.21859 11.3333 3.66647C11.3333 3.11447 11.7808 2.66669 12.3333 2.66669Z"/>
    </svg>
  );
}

export default Icon;