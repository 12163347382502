import classNames from 'classnames';

export interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
}

const Divider: React.FC<DividerProps> = ({
    className,
    ...props
}) => {

    const classes = classNames(
        className,
        "block my-4 border-gray-500 border-1",
    );

    return (
        <hr className={classes} {...props}/>
    );
};

export default Divider;