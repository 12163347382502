import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 2.22386 0.223858 2 0.5 2C0.776142 2 1 2.22386 1 2.5V13.5C1 13.7761 0.776142 14 0.5 14C0.223858 14 0 13.7761 0 13.5V2.5ZM13.5 7.50002C13.7761 7.50002 14 7.72388 14 8.00002C14 8.27616 13.7761 8.50002 13.5 8.50002H2.5C2.22386 8.50002 2 8.27616 2 8.00002C2 7.72388 2.22386 7.50002 2.5 7.50002H13.5ZM15.5 2C15.2239 2 15 2.22386 15 2.5V13.5C15 13.7761 15.2239 14 15.5 14C15.7761 14 16 13.7761 16 13.5V2.5C16 2.22386 15.7761 2 15.5 2Z"/>
</svg>
  );
}

export default Icon;
