import { documentReferenceSchema } from '@lib/customSchemas';
import { z } from "zod";

export const modelSchema = z.object({
    id: z.string(),
    ownerUid: z.string(),

    ref: documentReferenceSchema().optional(),
});

type Model = z.infer<typeof modelSchema>;

export default Model;