import { Button, ExtraLargeWebsiteHeader, LabelText, LargeHeader, SectionHeader } from "@ui";
import { useAuth } from '@authentication/AuthProvider';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useTranslation } from "react-i18next";

import image01 from "./slider-image-01.jpg";
import image02 from "./slider-image-02.jpg";
import image03 from "./slider-image-03.jpg";
import image04 from "./slider-image-04.jpg";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const OnboardingScreen: React.FC = () => {
    const { currentUser } = useAuth();

    const { t } = useTranslation();

    if (!currentUser)
        return null;

    return (
        <Swiper className="mt-1 sm:mt-10 md:mt-15 w-12/12 md:w-11/12 lg:w-9/12 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md"
            modules={[Navigation, Pagination, Scrollbar]}
            autoHeight= {true}
            keyboard={{ enabled: true, onlyInViewport: true }}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            mousewheel
            pagination={{ clickable: true }}
        >
            <SwiperSlide className="grid grid-cols-1 lg:grid-cols-2 gap-0">
                <div className="p-14">
                    <LabelText>
                        {t('app.onboarding.slide-01-number')}
                    </LabelText>
                    <ExtraLargeWebsiteHeader>
                        {t('app.onboarding.slide-01-headline')}
                    </ExtraLargeWebsiteHeader>
                    <SectionHeader className="mb-6">
                        {t('app.onboarding.slide-01-content')}
                    </SectionHeader>
                </div>
                <div className="hidden lg:block h-full">    
                    <img className="object-cover w-full h-full" src={image01} alt="" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="grid grid-cols-1 lg:grid-cols-2 gap-0d">
                <div className="p-14">    
                    <LabelText>
                        {t('app.onboarding.slide-02-number')}
                    </LabelText>
                    <ExtraLargeWebsiteHeader>
                        {t('app.onboarding.slide-02-headline')}
                    </ExtraLargeWebsiteHeader>
                    <SectionHeader className="mb-6">
                        {t('app.onboarding.slide-02-content')}
                    </SectionHeader>
                </div>
                <div className="hidden lg:block">    
                    <img className="object-cover w-full h-full" src={image02} alt="" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="grid grid-cols-1 lg:grid-cols-2 gap-0">    
                <div className="p-14">  
                    <LabelText>
                        {t('app.onboarding.slide-03-number')}
                    </LabelText>
                    <LargeHeader>
                        {t('app.onboarding.slide-03-headline')}
                    </LargeHeader>
                    <SectionHeader className="mb-6">
                        {t('app.onboarding.slide-03-content')}
                    </SectionHeader>
                </div>
                <div className="hidden lg:block">    
                    <img className="object-cover w-full h-full" src={image03} alt="" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="grid grid-cols-1 lg:grid-cols-2 gap-0">
                <div className="p-14">
                    <LabelText>
                        {t('app.onboarding.slide-04-number')}
                    </LabelText>
                    <ExtraLargeWebsiteHeader>
                        {t('app.onboarding.slide-04-headline')}
                    </ExtraLargeWebsiteHeader>
                    <SectionHeader className="mb-6">
                        {t('app.onboarding.slide-04-content')}
                    </SectionHeader>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                        <Button value="CSV impotieren" className="w-full"></Button>
                        <Button value="Artikel hinzufügen" className="w-full"></Button>
                    </div>
                </div>
                <div className="hidden lg:block">    
                    <img className="object-cover w-full h-full" src={image04} alt="" />
                </div>
            </SwiperSlide>
        </Swiper>
    )
}

export default OnboardingScreen;