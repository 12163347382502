import classNames from 'classnames';

// StatisticsList
export interface StatisticsListProps extends React.HTMLAttributes<HTMLTableElement> {
}

const StatisticsList: React.FC<StatisticsListProps> = ({
    className,
    children,
    ...props
}) => {
    return (
        <div className='rounded-md bg-white dark:bg-black px-4 py-1 mb-4'>
            <table className={`${className}`}>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export default StatisticsList;

// StatisticsListRow
export interface StatisticsListRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    icon?: React.ReactNode;
    name: string | React.ReactNode;
    value: string | React.ReactNode;
    size?: "small" | "large";
}

export const StatisticsListRow: React.FC<StatisticsListRowProps> = ({
    icon,
    name,
    value,
    size = "small",
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
        size === 'small' && 'border-b border-gray-200 dark:border-gray-700',
        size === 'large' && 'border-b border-gray-200 dark:border-gray-700',
    );

    return size === 'small' ? (
        <tr className={classes}>
            <td className="py-2 w-3/5">
                {icon && (
                    <span className="inline-block align-middle mr-2">{icon}</span>
                )}
                <span className="inline-block align-middle text-base md:text-sm font-semibold text-black dark:text-white">{name}</span>
            </td>
            <td className="py-2 w-2/5 align-middle text-base md:text-sm font-semibold text-gray-500 dark:text-gray-400 text-right">
                {value}
            </td>
        </tr>
    ) : (
        <tr className={classes}>
            <td className="py-2 pr-2 w-4">
                {icon && (
                    <span className="inline-block align-middle mr-0">{icon}</span>
                )}

            </td>
            <td className="py-2">
                {/* <span className="block text-xs font-semibold text-black dark:text-gray-500">{name}</span> */}
                <span className="block text-base md:text-sm font-semibold text-black dark:text-white">{value}</span>
                {children}
            </td>
        </tr>
    );
};