import { database, firestore } from '@lib/firebase';
import { Label, Textfield, Checkbox, Select, Button } from '@ui';
import { useAuth } from '@authentication/AuthProvider';
import useFormFields from '@hooks/useFormFields';
import { useUserSettings } from '@modules/app/hooks/useUserSettings';
import UserSettings from '@models/UserSettings';
import { Trash } from '@icons';
import Dialog, { DialogProps, DialogSaveButton, DialogSection } from '@app/dialog/Dialog';
import { useTranslation } from "react-i18next";
import { currencyCodes, defaultCurrencyCode } from '@lib/currencies';
import { getCurrencySign } from '../../../lib/currencies';
import { useState } from 'react';
import ReauthenticationDialog from '../reauthenticationDialog/ReauthenticationDialog';

export interface UserSettingsFormProps {
}

const UserSettingsDialog: React.FC<UserSettingsFormProps & DialogProps> = ({ onClose, ...dialogProps }) => {
    const { currentUser } = useAuth();
    const userSettings = useUserSettings();
    const { t } = useTranslation();

    const { formFields, createChangeHandler, createSelectChangeHandler } = useFormFields({
        firstName: userSettings.firstName ?? '',
        lastName: userSettings.lastName ?? '',

        email: currentUser?.email ?? '',

        currency: userSettings?.currency ?? defaultCurrencyCode,

        receiveStatusNotifications: userSettings.receiveStatusNotifications,
        receiveChangeNotifications: userSettings.receiveChangeNotifications,
        receiveBirthdayNotifications: userSettings.receiveBirthdayNotifications,
    });

    const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState<boolean>(false);
    const [confirmedAccountDeletion, setConfirmedAccountDeletion] = useState<boolean>(false);

    const [authErrorComponent, setAuthErrorComponent] = useState<React.ReactNode>(undefined);

    if (!currentUser)
        return null;

    const save = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const batch = firestore.batch();
        const documentRef = database.userSettings.doc(currentUser.uid)
        batch.set(documentRef, {
            id: currentUser.uid,
            ownerUid: currentUser.uid,

            firstName: formFields.firstName ? formFields.firstName : null,
            lastName: formFields.lastName ? formFields.lastName : null,
            
            currency: formFields.currency ? formFields.currency : defaultCurrencyCode,

            receiveStatusNotifications: formFields.receiveStatusNotifications,
            receiveChangeNotifications: formFields.receiveChangeNotifications,
            receiveBirthdayNotifications: formFields.receiveBirthdayNotifications,
        } as UserSettings);

        return batch.commit()
            .then(() => {
                if (onClose) {
                    onClose();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const deleteAccount = async (allowReauthentication: boolean | undefined = true) => {
        if (confirmedAccountDeletion) {
            currentUser.delete()
                .then(() => {
                    setShowDeleteAccountDialog(false);
                })
                .catch((error: firebase.default.FirebaseError) => {
                    if (error.code === "auth/requires-recent-login" && allowReauthentication) {
                        setAuthErrorComponent(<ReauthenticationDialog 
                            onComplete={() => {
                                setAuthErrorComponent(undefined);
                                deleteAccount(false);
                            }}
                            onFail={() => {
                                setAuthErrorComponent(undefined);
                            }} 
                        />)
                    }
                });
        }
    }

    return (
        <Dialog title={t('app.label.userSettings')} {...dialogProps} onClose={onClose} onSave={e => save(e)}>
            <DialogSection className='mb-6'>
                <Label value={t('app.label.firstName')}>
                    <Textfield placeholder={t('app.label.firstName')} value={formFields.firstName} onChange={createChangeHandler("firstName")} autoFocus />
                </Label>
                <Label value={t('app.label.lastName')}>
                    <Textfield placeholder={t('app.label.lastName')} value={formFields.lastName} onChange={createChangeHandler("lastName")} />
                </Label>
                <Label value={t('app.label.email')}>
                    <Textfield placeholder={t('app.label.email')} value={formFields.email} onChange={createChangeHandler("lastName")} readOnly disabled />
                </Label>
                <Label value={t('app.label.currency')}>
                    <Select onChange={createSelectChangeHandler("currency")}>
                        {currencyCodes.map(currencyCode => (
                            <option key={currencyCode} value={currencyCode} selected={formFields.currency === currencyCode}>{`${currencyCode} (${getCurrencySign(currencyCode)})`}</option>
                        ))}
                    </Select>
                </Label>
            </DialogSection>
            <DialogSection title={t('app.label.notifications')}  className='mb-6'>
                <Checkbox checked={formFields.receiveStatusNotifications} onChange={createChangeHandler("receiveStatusNotifications")} text="Status E-Mail 1x im Monat erhalten" readOnly />
                <Checkbox checked={formFields.receiveChangeNotifications} onChange={createChangeHandler("receiveChangeNotifications")} text="Status E-Mail bei Veränderungen erhalten" readOnly />
                <Checkbox checked={formFields.receiveBirthdayNotifications} onChange={createChangeHandler("receiveBirthdayNotifications")} text="E-Blumenstrauß zum Geburstag erhalten" readOnly />
            </DialogSection>
            <DialogSection title={t('app.userAccount.dangerZone')}>
                <Button type="button" icon={<Trash color="alert" />} value={t('app.userAccount.deleteAccount')} color="alert" onClick={() => setShowDeleteAccountDialog(true)} />
                {showDeleteAccountDialog &&
                    <Dialog title={t('app.userAccount.deleteAccount')} allowNesting onClose={() => { setShowDeleteAccountDialog(false); setConfirmedAccountDeletion(false) }} onSave={async () => deleteAccount()} customSaveButton={(isSaving) =>
                        <DialogSaveButton isSaving={isSaving} color="warning" value={t('app.button.delete')} disabled={isSaving || !confirmedAccountDeletion} />
                    }>
                        <DialogSection>
                            <Checkbox checked={confirmedAccountDeletion} onChange={() => setConfirmedAccountDeletion(!confirmedAccountDeletion)} readOnly text={t('app.userAccount.confirmText')} />
                        </DialogSection>
                    </Dialog>
                }
            </DialogSection>

            {authErrorComponent}
        </Dialog>
    )
};

export default UserSettingsDialog;