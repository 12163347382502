import { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import * as H from 'history';
import ModalBackdrop from '../ModalBackdrop/ModalBackdrop';

// Dropdown
export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose?: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
    className,
    onClose,
    children,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const close = useCallback(() => {
        onClose && onClose();
    }, [onClose]);

    const classes = classNames(
        className,
        'bg-white rounded shadow-md absolute border border-gray-200 dark:border-gray-500 mt-11 top-0 right-0 min-w-full overflow-auto z-30',
    )

    return (
        <>
            <ModalBackdrop onClose={() => close()} />
            <div onClick={e => { close(); e.stopPropagation(); }} ref={ref} className={classes}>
                <ul className="list-reset">
                    {children}
                </ul>
            </div>
        </>
    );
};

export default Dropdown;

// Dropdown Item
export interface DropdownItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    linkTo?: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>);
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
    className,
    children,
    linkTo,
    ...props
}) => {

    const classes = classNames(
        className,
        'px-4 py-2 block text-gray-900 hover:bg-indigo-400 hover:text-white no-underline hover:no-underline min-w-full text-left dark:text-white dark:bg-gray-800',
    );

    return (
        <li>
            {linkTo ? (
                <Link to={linkTo} className={classes} {...props as any}>
                    {children}
                </Link>
            ) : (
                <button className={classes} {...props}>
                    {children}
                </button>
            )}
        </li>
    );
};

// Dropdown Splitter
export interface DropdownSplitterProps extends React.HTMLAttributes<HTMLHRElement> {
}

export const DropdownSplitter: React.FC<DropdownSplitterProps> = ({
    className,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
        'border-t border-gray-200 dark:border-gray-500',
    );

    return (
        <li>
            <hr className={classes} {...props} />
        </li>
    );
};

