import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
       <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M8.68616 8.36042C8.50717 8.18143 8.49225 7.90049 8.64141 7.70451L8.68616 7.65331L11.986 4.35348C12.1813 4.15822 12.4978 4.15822 12.6931 4.35348C12.8721 4.53247 12.887 4.8134 12.7378 5.00938L12.6931 5.06058L10.2537 7.50001H15.4996C15.7757 7.50001 15.9996 7.72386 15.9996 8.00001C15.9996 8.27615 15.7757 8.50001 15.4996 8.50001H10.24L12.6931 10.9531C12.8721 11.1321 12.887 11.4131 12.7378 11.609L12.6931 11.6602C12.5141 11.8392 12.2332 11.8542 12.0372 11.705L11.986 11.6602L8.68616 8.36042ZM7.31311 8.3605C7.4921 8.18151 7.50702 7.90057 7.35786 7.70459L7.31311 7.65339L4.01328 4.35356C3.81802 4.1583 3.50144 4.1583 3.30617 4.35356C3.12718 4.53255 3.11227 4.81348 3.26143 5.00946L3.30617 5.06067L5.74551 7.50001H0.5C0.223857 7.50001 0 7.72386 0 8.00001C0 8.27615 0.223857 8.5 0.5 8.5H5.75939L3.30617 10.9532C3.12718 11.1322 3.11227 11.4131 3.26143 11.6091L3.30617 11.6603C3.48517 11.8393 3.7661 11.8542 3.96208 11.7051L4.01328 11.6603L7.31311 8.3605Z"/>
    </svg>
  );
}

export default Icon;

