import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '@authentication/AuthProvider';
import routes from './Routes';

interface AuthenticationRouteProps extends RouteProps {
    component: any;
}

// A route that only works if the user is signed in. 
// Otherwise it will redirect to the login page
const AuthenticationRoute: React.FC<AuthenticationRouteProps> = ({ component: Component, ...rest }: AuthenticationRouteProps) => {
    const { currentUser } = useAuth();

    return (
        <Route {...rest} render={props => {
            return !currentUser
                // If logged in, render the component
                ? <Component {...props} />
                // Otherwise redirect to the login page
                : <Redirect to={routes.authentication.login.build()} />
        }} />
    );
};

export default AuthenticationRoute;