import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.34321 13.6569C-0.780988 10.5327 -0.780988 5.46734 2.34321 2.34315C5.4674 -0.781049 10.5327 -0.781049 13.6569 2.34315C16.7811 5.46734 16.7811 10.5327 13.6569 13.6569C10.5327 16.7811 5.4674 16.7811 2.34321 13.6569ZM3.05031 3.05025C0.316643 5.78392 0.316643 10.2161 3.05031 12.9497C5.78398 15.6834 10.2161 15.6834 12.9498 12.9497C15.6835 10.2161 15.6835 5.78392 12.9498 3.05025C10.2161 0.316582 5.78398 0.316582 3.05031 3.05025ZM8.00006 3.33326C8.2762 3.33326 8.50006 3.55712 8.50006 3.83326L8.50006 7.49993L12.1667 7.49993C12.4429 7.49993 12.6667 7.72379 12.6667 7.99993C12.6667 8.27607 12.4429 8.49993 12.1667 8.49993H8.50006V12.1666C8.50006 12.4427 8.2762 12.6666 8.00006 12.6666C7.72392 12.6666 7.50006 12.4427 7.50006 12.1666L7.50006 8.49993H3.83339C3.55725 8.49993 3.33339 8.27607 3.33339 7.99993C3.33339 7.72379 3.55725 7.49993 3.83339 7.49993L7.50006 7.49993L7.50006 3.83326C7.50006 3.55712 7.72392 3.33326 8.00006 3.33326Z"/>
</svg>
  );
}

export default Icon;
