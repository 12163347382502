import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
        <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M2.26839 7.70457C2.11923 7.90055 2.13414 8.18149 2.31313 8.36048L5.61297 11.6603L5.66417 11.7051C5.86015 11.8542 6.14108 11.8393 6.32007 11.6603L6.36482 11.6091C6.51398 11.4131 6.49906 11.1322 6.32007 10.9532L3.86685 8.49998H13.1262C13.4024 8.49998 13.6262 8.27613 13.6262 7.99998C13.6262 7.72384 13.4024 7.49998 13.1262 7.49998H3.88073L6.32007 5.06065L6.36482 5.00944C6.51398 4.81346 6.49906 4.53253 6.32007 4.35354C6.12481 4.15828 5.80823 4.15828 5.61297 4.35354L2.31313 7.65337L2.26839 7.70457Z"/>
    </svg>
  );
}

export default Icon;

