import classNames from 'classnames';

export interface ModalBackdropProps extends React.HTMLAttributes<HTMLDivElement> {
    color?: 'invisible';
    onClose?: () => void;
}

const ModalBackdrop: React.FC<ModalBackdropProps> = ({
    color = 'invisible',
    className,
    onClose,
    children,
    ...props
}) => {

    const classes = classNames(
        className,
        "fixed inset-0 h-full w-full z-10",
    );

    function handleClick(e: React.MouseEvent) {
        e.stopPropagation();

        onClose && onClose();
    }

    return (
        <div {...props} onClick={handleClick} className={classes}>
            {children}
        </div>
    );
};

export default ModalBackdrop;