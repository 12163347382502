import classNames from 'classnames';

export interface NumberfieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inline?: boolean;
    decimalPlaces?: number;
}

const Numberfield: React.FC<NumberfieldProps> = ({
    inline = false,
    decimalPlaces,
    className,
    ...props
}) => {

    let pattern = `^\\d*`;
    if (decimalPlaces && decimalPlaces > 0)
        pattern += `(\\.\\d{0,${decimalPlaces}})?$`;

    const classes = inline
        // Inline
        ? classNames(
            className,
            'p-1 px-1 mx-1 w-14 appearance-none text-blue-500 disabled:text-gray-700 font-semibold dark:bg-gray-800',
        )
        // Not inline
        : classNames(
            className,
            'py-1 px-2 w-full text-blue-500 focus:outline-none font-semibold rounded-md disabled:cursor-not-allowed dark:bg-gray-800 disabled:opacity-60 disabled:bg-gray-100 disabled:text-gray-500',
        )

    return (
        <input type="number" className={classes} step="any" pattern={decimalPlaces ? pattern : undefined} {...props} />
    );
};

export default Numberfield;