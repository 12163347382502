import { floatToString } from '@lib/stringConversion';
import { StatisticsListRow, StatisticsListRowProps } from '@ui';
import { useTranslation } from 'react-i18next';

// StatisticsList
export interface DetailsStatisticsRowProps extends StatisticsListRowProps {
    months?: number;
    weeks?: number;
    days?: number;
}

const DetailsStatisticsRow: React.FC<DetailsStatisticsRowProps> = ({
    icon,
    name,
    value,
    months,
    weeks,
    days,
    className,
    children,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <StatisticsListRow size="large" name={name} value={value} icon={icon} className={className} {...props}>
            {months && (
                <span className="text-xs font-semibold text-gray-500 dark:text-white">{`${floatToString(months)} ${t('app.statistics.months')}`}, </span>
            )}
            {weeks && (
                <span className="text-xs font-semibold text-gray-500 dark:text-white">{`${floatToString(weeks)} ${t('app.statistics.weeks')}`}, </span>
            )}
            {days && (
                <span className="text-xs font-semibold text-gray-500 dark:text-white">{`${floatToString(days)} ${t('app.statistics.days')}`}</span>
            )}
        </StatisticsListRow>
    );
};

export default DetailsStatisticsRow;