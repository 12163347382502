import { modelSchema } from './Model';
import { z } from "zod";

// Tags
export const institutionMetadataSchema = z.object({
    id: z.string(),
    name: z.string(),
    logo: z.string().nullable().catch(null),
    url: z.string().nullable().catch(null),
})

export type InstitutionMetadata = z.infer<typeof institutionMetadataSchema>;

export const bankConnectionSchema = modelSchema.extend({
    itemId: z.string(),
    institutionMetadata: institutionMetadataSchema.nullable().catch(null),
})

type BankConnection = z.infer<typeof bankConnectionSchema>;

export default BankConnection;

// Tag Collection
export const bankConnectionCollectionSchema = z.object({
    bankConnections: z.array(bankConnectionSchema),
});

export type BankConnectionCollection = z.infer<typeof bankConnectionCollectionSchema>;