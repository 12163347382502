import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import firebase from "firebase";
import { handleError, useAuth } from "@authentication/AuthProvider";
import HomepageLayout from "@modules/components/HomepageLayout";
import { Button, Textfield, Validation, Link, LargeHeader } from "@ui";
import { useTranslation } from "react-i18next";

const Reset: React.FC = () => {
    const { verifyPasswordResetCode, resetPassword } = useAuth();
    const { t } = useTranslation();

    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [verifying, setVerifying] = useState<boolean>(true);
    const [resetCodeError, setResetCodeError] = useState<string | null>(null);

    const history = useHistory();
    const location = useLocation().search;

    const resetCode = new URLSearchParams(location).get('oobCode');

    // Verify if the resetCode is set and valid
    useEffect(() => {
        if (resetCode == null) {
            history.push('/');
        } else {
            verifyPasswordResetCode(resetCode)
                .catch(e => {
                    setResetCodeError(e.message);
                })
                .finally(() => {
                    setVerifying(false);
                });
        }
    }, [history, resetCode, verifyPasswordResetCode]);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setPasswordError(null);
        setConfirmPasswordError(null);

        if (passwordRef.current && confirmPasswordRef.current) {

            if (passwordRef.current.value !== confirmPasswordRef.current.value) {
                return setConfirmPasswordError('Passwords do not match.');
            }

            setLoading(true);

            try {
                await resetPassword(resetCode!, passwordRef.current.value);

                history.push('/');
            } catch (e) {
                const authError = handleError(e as firebase.auth.Error);

                if (authError) {
                    authError.field === 'password'
                        ? setPasswordError(authError.message)
                        : setError(authError.message);
                }
            }
        }

        setLoading(false);
    }

    return (
        <HomepageLayout onSubmit={handleSubmit}>
            <LargeHeader className="mb-4">
                {t('authentication.reset.title')}
            </LargeHeader>
            {verifying ? (
                <div className="flex mb-10">
                    <p className="text-gray-400">{t('authentication.common.loading')}</p>
                </div >
            ) : (
                !resetCodeError ? (
                    <>
                        <div className="flex mb-10">
                            <p className="text-gray-400">{t('authentication.reset.description')}</p>
                        </div >
                        <Validation message={passwordError}>
                            <Textfield type="password" ref={passwordRef} placeholder={t('authentication.common.newPasswordPlaceholder')} autoComplete="off" />
                        </Validation>
                        <div className="mb-4" />
                        <Validation message={confirmPasswordError}>
                            <Textfield type="password" ref={confirmPasswordRef} placeholder={t('authentication.common.confirmPlaceholder')} autoComplete="off" />
                        </Validation>
                        <Button type="submit" className="w-full mt-4" size="large" value={t('authentication.reset.submitButton')} loading={loading} />
                    </>
                ) : (
                    <>
                        {/* @TODO */}
                        <div className="flex mb-10">
                            <p className="text-gray-400">
                                {error} <Link to='/app/login'>{t('authentication.reset.loginLink')}</Link>
                            </p>
                        </div >
                    </>
                )
            )}
        </HomepageLayout>
    )
}

export default Reset;