import { modelSchema } from "./Model";
import Tag from "./Tag";
import { z } from "zod";
import { documentReferenceSchema } from "@lib/customSchemas";

// Group
export const groupSchema = modelSchema.extend({
    name: z.string()
        .optional()
        .catch(undefined),
    tags: z.array(documentReferenceSchema())
        .min(1), // Tags are required. Here we can't use a fallback.
    itemGroups: z.array(z.string())
        .catch([]),
})

type Group = z.infer<typeof groupSchema>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace Group {
    export function lookupReferencedTags(group: Group, availableTags: Tag[]) {
        return availableTags.filter(tag => 
            group.tags.filter(groupTag => groupTag.id === tag.id).length > 0
        )
    }
}

export default Group;

// Group Collection
export const groupCollectionSchema = z.object({
    groups: z.array(groupSchema),
});

export type GroupCollection = z.infer<typeof groupCollectionSchema>;