import { Textfield, Tag as TagContainer, OffscreenContainer } from '../';
import React, { forwardRef, useEffect } from 'react';
import { useState } from 'react';
import classNames from 'classnames';

export interface TagfieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    availableTags: string[];
    selectedTags?: string[];
    onSelectionChange?: (selectedTags: string[]) => void;
}

const Tagfield = forwardRef<HTMLInputElement, TagfieldProps>(({ selectedTags: initialSelectedTags, availableTags, onSelectionChange, className, ...props }, ref) => {
    const recommendationLimit = 5;

    const [textfieldValue, setTextfieldValue] = useState<string>("");
    const [selectedTags, setSelectedTags] = useState<string[]>(initialSelectedTags ? [...initialSelectedTags] : []);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isRecommendationsHidden, setIsRecommendationsHidden] = useState<boolean>(false);

    const [recommendations, setRecommendations] = useState<string[]>([]);
    const [currentSelection, setCurrentSelection] = useState<string | null>(null)
    const [fallbackSelectionIndex, setFallbackSelectionIndex] = useState<number>(0);

    function handleTextfieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        setTextfieldValue(e.target.value);

        setIsRecommendationsHidden(false);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();

            let newSelectionIndex = 0;

            if (e.key === 'ArrowUp') {
                // Up
                newSelectionIndex = fallbackSelectionIndex - 1;
            } else if (e.key === 'ArrowDown') {
                // Down
                newSelectionIndex = fallbackSelectionIndex + 1;
            }

            // Validate that the new index is not out of bounds
            newSelectionIndex = Math.min(Math.max(newSelectionIndex, 0), recommendations.length);

            setFallbackSelectionIndex(newSelectionIndex);

            // The recommended items start at index 1 (0 is for the "Add tag ..." option)
            const newSelectedReccomendationIndex = newSelectionIndex - 1;

            const newSelectedReccomendation = newSelectedReccomendationIndex >= 0 ? recommendations[newSelectedReccomendationIndex] : null

            setCurrentSelection(newSelectedReccomendation);
        }
        else if (e.key === 'Enter') {
            const newTagName = currentSelection ?? textfieldValue;

            if (newTagName) {
                e.preventDefault();
                addTagToSelection(newTagName);
            }
        }
        else if (e.key === 'Escape') {
            if (recommendationsVisible()) {
                setIsRecommendationsHidden(true);
                setCurrentSelection(null);

                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    // Update Recommendations
    useEffect(() => {
        const searchValue = textfieldValue.trim().toLowerCase();

        if (searchValue.length === 0) {
            setRecommendations([]);
        } else {
            // Query the first n tags that start the with query ignoring case
            const newRecomendations = availableTags
                .filter(tag => tag.toLowerCase().startsWith(searchValue) && !selectedTags.find(alreadySelectedTag => alreadySelectedTag.toLowerCase() === tag.toLowerCase()))
                .slice(0, recommendationLimit);

            setRecommendations(newRecomendations);
        }
    }, [textfieldValue, availableTags, selectedTags]);

    // Update both currentSelection and fallbackSelection index
    useEffect(() => {
        if (currentSelection == null) {
            setFallbackSelectionIndex(0);
            return;
        }

        if (recommendations.includes(currentSelection)) {
            // Selected recommendation still matches but index might have changed. -> Only update the index.
            // + 1 to take the "Add tag ..." option into account
            const newSelectionIndex = recommendations.indexOf(currentSelection) + 1;

            setFallbackSelectionIndex(newSelectionIndex);
        } else {
            // Selected recommendation does not exist any more. -> Set the selection to the fallback index.
            // fallbackSelectionIndex - 1 to take the "Add tag ..." option into account
            const newSelectionIndex = fallbackSelectionIndex - 1;
            const validatedSelectionIndex = Math.min(newSelectionIndex, recommendations.length - 1);

            const itemAtIndex = validatedSelectionIndex >= 0
                ? recommendations[validatedSelectionIndex]
                : null;

            setCurrentSelection(itemAtIndex);
        }
    }, [recommendations, currentSelection, fallbackSelectionIndex]);

    function deleteTagFromSelection(tagToDelete: string) {
        const tagsWithOutRemoved = selectedTags.filter(tag => tag !== tagToDelete);
        setSelectedTags(tagsWithOutRemoved);

        if (onSelectionChange)
            onSelectionChange(tagsWithOutRemoved);
    }

    function addTagToSelection(newTagName: string, resetTextfield: boolean = true) {
        const validatedTagName = newTagName.trim();
        if (validatedTagName.length === 0)
            return;

        let updatedSelectedTags = selectedTags;

        // Only add the tag if it does not already exist
        if (!selectedTags.find(x => x.toLowerCase() === validatedTagName.toLowerCase())) {
            updatedSelectedTags = [...selectedTags, validatedTagName]
            setSelectedTags(updatedSelectedTags);

            if (onSelectionChange)
                onSelectionChange(updatedSelectedTags);
        }

        if (resetTextfield)
            setTextfieldValue('');
    }

    function recommendationsVisible() {
        return isFocused && textfieldValue.trim().length > 0 && !isRecommendationsHidden;
    }

    const recommendationButtonClases = (selected: boolean) => classNames(
        'block py-1 px-5 cursor-pointer hover:bg-indigo-600 hover:text-white',
        selected && 'bg-indigo-600 text-white'
    )

    return (
        <OffscreenContainer className={className}>
            <Textfield
                {...props}
                ref={ref}
                value={textfieldValue}
                onChange={e => handleTextfieldChange(e)}
                onFocus={e => setIsFocused(true)}
                onBlur={e => setIsFocused(false)}
                onKeyDown={e => handleKeyDown(e)}
            />

            {/* Recommendation drop down */}
            {recommendationsVisible() && (
                <div className="absolute z-40 left-0 mt-2 w-full">
                    <div className="py-1 text-sm bg-white rounded shadow-lg border border-gray-300">

                        {/* Option "Add Tag ..." */}
                        <div onClick={() => textfieldValue && addTagToSelection(textfieldValue)} onMouseDown={e => e.preventDefault()} className={recommendationButtonClases(currentSelection === null)}>Add tag "<span className="font-semibold" x-text="textInput">{textfieldValue}</span>"</div>

                        {/* Recommendation options */}
                        {recommendations.map((recommendation) => (
                            <div key={recommendation} onClick={() => addTagToSelection(recommendation)} onMouseDown={e => e.preventDefault()} className={recommendationButtonClases(currentSelection === recommendation)}>{recommendation}</div>
                        ))}
                    </div>
                </div>
            )}

            {/* Selected Tags */}
            {selectedTags.map((tag, i) => (
                <TagContainer key={i} value={tag} className="mt-2 mr-1" onRemove={() => deleteTagFromSelection(tag)} />
            ))}
        </OffscreenContainer>
    )
});

export default Tagfield;