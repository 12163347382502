import classNames from 'classnames';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    text: string;
    customIcon?: boolean;
    icon?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
    className,
    customIcon = false,
    children,
    icon,
    text,
    ...props
}) => {
    const classes = classNames(
        className,
        'form-checkbox p-2 w-full block bg-white dark:bg-gray-800 dark:text-white font-semibold rounded-md'
    );

    const nameClasses = customIcon 
        // Custom icon
        ? classNames(
            'w-6 h-6 relative',
        )
        // Without custom icon
        : classNames(
            'focus:outline-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-500',
        )

    return customIcon ? (
        <label className=" h-6 w-6 inline-block">
         <input type="checkbox" className='hidden relative h-6 w-6' {...props} />
         {icon && <div className={nameClasses}>{icon}</div>}
            {text}
        </label>
        ) : (
        <label className={classes}>
         <input type="checkbox" className='mr-2' {...props} />
            {text}
        </label>
    );
};

export default Checkbox;