import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M13.6568 13.6569C10.5326 16.781 5.46733 16.781 2.34313 13.6569C-0.781061 10.5327 -0.781061 5.46734 2.34313 2.34315C5.46733 -0.781049 10.5326 -0.781048 13.6568 2.34315C16.781 5.46734 16.781 10.5327 13.6568 13.6569ZM3.05024 3.05025C0.31657 5.78392 0.31657 10.2161 3.05024 12.9497C5.78391 15.6834 10.2161 15.6834 12.9497 12.9497C15.6834 10.2161 15.6834 5.78392 12.9497 3.05025C10.2161 0.316582 5.78391 0.316582 3.05024 3.05025ZM10.7862 5.72361C10.7862 5.47048 10.5981 5.26128 10.354 5.22818L10.2862 5.22361H5.61953C5.34339 5.22361 5.11953 5.44747 5.11953 5.72361C5.11953 5.97674 5.30763 6.18594 5.55168 6.21905L5.61953 6.22361L9.06928 6.22361L5.36014 9.93275C5.16488 10.128 5.16488 10.4446 5.36014 10.6399C5.5554 10.8351 5.87198 10.8351 6.06724 10.6399L9.7862 6.92091L9.7862 10.3903C9.7862 10.6434 9.9743 10.8526 10.2183 10.8857L10.2862 10.8903C10.5393 10.8903 10.7485 10.7022 10.7816 10.4581L10.7862 10.3903L10.7862 5.72361Z"/>
    </svg>
  );
}

export default Icon;