import * as React from "react";
import IconProps, { colorClasses , sizeClasses } from './IconProps';

function Icon({ color = "primary", size = "small", className, ...props }: IconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${colorClasses[color]} ${sizeClasses[size]} ${className}`} {...props}>
    <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M3.33333 0.5C3.33333 0.223857 3.55719 0 3.83333 0C4.10948 0 4.33333 0.223858 4.33333 0.5V1.33333H11.6667V0.5C11.6667 0.223857 11.8905 0 12.1667 0C12.4428 0 12.6667 0.223858 12.6667 0.5V1.33333H14.6667C15.403 1.33333 16 1.93029 16 2.66667V14.6667C16 15.403 15.403 16 14.6667 16H1.33333C0.596954 16 0 15.403 0 14.6667V2.66667C0 1.93029 0.596954 1.33333 1.33333 1.33333H3.33333V0.5ZM11.6667 2.33333V2.83333C11.6667 3.10948 11.8905 3.33333 12.1667 3.33333C12.4428 3.33333 12.6667 3.10948 12.6667 2.83333V2.33333H14.6667L14.7266 2.3387C14.8821 2.36693 15 2.50303 15 2.66667V14.6667L14.9946 14.7266C14.9664 14.8821 14.8303 15 14.6667 15H1.33333L1.27342 14.9946C1.11792 14.9664 1 14.8303 1 14.6667V2.66667L1.00537 2.60675C1.03359 2.45125 1.16969 2.33333 1.33333 2.33333H3.33333V2.83333C3.33333 3.10948 3.55719 3.33333 3.83333 3.33333C4.10948 3.33333 4.33333 3.10948 4.33333 2.83333V2.33333H11.6667ZM7.48405 11.3822C7.19108 11.3822 6.98275 11.1901 6.98275 10.9004C6.98275 10.806 7.0153 10.7083 7.07389 10.5977L8.52572 7.76237V7.74284H6.8265C6.50749 7.74284 6.31869 7.57682 6.31869 7.31966C6.31869 7.05925 6.50749 6.88997 6.8265 6.88997H9.0791C9.39811 6.88997 9.62272 7.09831 9.62272 7.40755C9.62272 7.62891 9.56087 7.83724 9.40462 8.14323L7.9528 11.0566C7.83887 11.2747 7.69564 11.3822 7.48405 11.3822Z"/>
</svg>
  );
}

export default Icon;