import { bind, unbind, ExtendedKeyboardEvent } from 'mousetrap';
import { useEffect } from 'react';

// A hook for Mousetrap which takes care of unbinding the handlers
export default function useKeyboardShortcut(keys: string | string[], callback: (e: ExtendedKeyboardEvent, combo: string) => any, action?: string | undefined, preventDefault: boolean = true) {
    useEffect(() => {

        let fixedCallback = callback;

        if (preventDefault) {
            fixedCallback = (e, combo) => {
                if (e.preventDefault) {
                    e.preventDefault();
                } else {
                    // internet explorer
                    e.returnValue = false;
                }

                // Call the original callback
                callback(e, combo);
            }
        }

        bind(keys, fixedCallback, action);

        return () => { unbind(keys, action) }
    }, [keys, callback, action, preventDefault]);
}