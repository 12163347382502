import useKeyboardShortcut from "@hooks/useKeyboardShortcut";
import { useState } from "react";
import classNames from 'classnames';

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
    navigationBar?: React.ReactNode;
    leftSidebar?: React.ReactNode;
    rightSidebar?: React.ReactNode;
}

const Page: React.FC<PageProps> = ({
    navigationBar,
    leftSidebar,
    rightSidebar,
    className,
    children,
    ...props
}) => {

    const [leftSidebarEnabled, setLeftSidebarEnabled] = useState<boolean>(false);
    const [rightSidebarEnabled, setRightSidebarEnabled] = useState<boolean>(true);

    const leftSidebarVisible = leftSidebar && leftSidebarEnabled;
    const rightSidebarVisible = rightSidebar && rightSidebarEnabled;

    useKeyboardShortcut("l", () => {
        setLeftSidebarEnabled(!leftSidebarEnabled);
    });

    useKeyboardShortcut("r", () => {
        setRightSidebarEnabled(!rightSidebarEnabled);
    });

    const classes = classNames(
        className,
        'flex flex-row flex-wrap flex-1 flex-grow content-start',
    );

    return (
        <div className={classes} {...props}>
            {navigationBar}

            {leftSidebar && leftSidebarEnabled && (
                <div className="w-full p-2 md:p-4 xl:w-1/5 xl:max-w-6xl h-sidebar hidden md:hidden xl:block lg:max-w-sm content-start scrollbar scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-800 scrollbar-track-white dark:scrollbar-track-gray-700 overflow-y-scroll scrolling-touch">
                    {leftSidebar}
                </div>
            )}
            <div className={classNames(
                `p-2 pb-0 md:p-4 w-full`,
                // Only one side bar
                ((leftSidebarVisible && ! rightSidebarVisible) || (rightSidebarVisible && ! leftSidebarVisible)) && 'xl:w-4/5 xl:max-w-8xl',
                // Right sidebar
                rightSidebarVisible && 'xl:w-3/5 xl:max-w-8xl lg:h-sidebar bg-white scrollbar scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-800 scrollbar-track-white dark:scrollbar-track-gray-700 overflow-y-scroll scrolling-touch'
            )}>
                {children}
            </div>
            {rightSidebar && rightSidebarEnabled && (
                <div className="w-full p-2 md:p-4 xl:w-1/5 dark:bg-sidepanel xl:max-w-6xl lg:h-sidebar scrollbar scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-800 scrollbar-track-white dark:scrollbar-track-gray-700 overflow-y-scroll scrolling-touch">
                    {rightSidebarEnabled && rightSidebar}
                </div>
            )}
        </div>
    );
};

export default Page;