import { useHistory } from "react-router-dom"
import { useAuth, handleError } from "@authentication/AuthProvider";
import firebase from "firebase";
import { useRef, useState, useEffect } from "react";
import HomepageLayout from "@modules/components/HomepageLayout";
import { Button, Textfield, Link, Validation } from "@ui";
import { useTranslation } from "react-i18next";
import routes from "@modules/routes/Routes";

const Login: React.FC = () => {
    const { login, authenticationNotification, setAuthenticationNotification } = useAuth();
    const { t } = useTranslation();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentAuthenticationNotification, setCurrentAuthenticationNotification] = useState<string | null>(null);

    const history = useHistory();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        
        setEmailError(null);
        setPasswordError(null);

        if (emailRef.current && passwordRef.current) {
            try {
                setLoading(true);
                await login(emailRef.current.value, passwordRef.current.value);
                history.push('/');
            } catch (e) {
                const authError = handleError(e as firebase.auth.Error);

                if (authError) {
                    authError.field === 'password'
                        ? setPasswordError(authError.message)
                        : setEmailError(authError.message);
                }
            }
        }
        setLoading(false);
    }

    // Show a banner if the user just verified his email and dismiss the property
    useEffect(() => {
        if (authenticationNotification) {
            setCurrentAuthenticationNotification(authenticationNotification);
            setAuthenticationNotification(null);
        }
    }, [authenticationNotification, setAuthenticationNotification]);

    return (
        <HomepageLayout onSubmit={handleSubmit}>
            <h1 className="text-4xl font-bold mb-4 dark:text-white">{t('authentication.login.title')}</h1>
            {currentAuthenticationNotification && (
                //@CHANGEME add banner styles
                <p className="bg-green-500 rounded-sm mb-4 p-4">{currentAuthenticationNotification}</p>
            )}
            <Validation message={emailError}>
                <Textfield ref={emailRef} type="email" placeholder={t('authentication.common.emailPlaceholder')} autoComplete="off" />
            </Validation>
            <Validation message={passwordError}>
                <Textfield ref={passwordRef} type="password" placeholder={t('authentication.common.passwordPlaceholder')} autoComplete="off" className="mt-4" />
            </Validation>
            <Button type="submit" className="w-full mt-4 mb-4" size="large" value={t('authentication.login.loginButton')} loading={loading} />
            <div className="flex mb-10">
                <Link to={routes.authentication.forgot.build()}>{t('authentication.login.forgotPasswordLink')}</Link>
            </div>
            <Button linkTo={routes.authentication.registration.build()} className="w-full inline-block text-center" color="mute" value={t('authentication.login.createAccountLink')} size="large" />
        </HomepageLayout>
    )
}

export default Login;