import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { database } from '@lib/firebase';
import { useAuth } from '@modules/authentication/AuthProvider';
import Tag, { TagCollection, tagSchema } from '@models/Tag';
    
type Action =
    | { type: 'set-loading' }
    | { type: 'set-tags'; tags: Tag[] }

function reducer (state: TagCollection | undefined, action: Action): TagCollection | undefined {
    switch (action.type) {
        case 'set-loading':
            return undefined
        case 'set-tags':
            return {
                ...state,
                tags: action.tags,
            }
        default:
            return state;
    }
}

const TagsContext = createContext<TagCollection | undefined>(undefined)

export const TagsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, undefined);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        return database.tags
            .where('ownerUid', '==', currentUser.uid)
            .onSnapshot(snapshot => {
                console.log('Updating Tags');

                const tags = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    try {
                        return tagSchema.parse({ ...docData, ref: doc.ref }) as Tag;
                    }
                    catch (e) {
                        console.error(e, { originalObject: docData })
                        return null;
                    }
                }).filter((tag): tag is Tag => tag !== null)
                
                dispatch({
                    type: 'set-tags',
                    tags: tags
                })
            })
    }, [currentUser]);

    return (
        <TagsContext.Provider value={state}>
            {children}
        </TagsContext.Provider>
    )
}

export function useTagsOpt() {
    return useContext(TagsContext);
}

export function useTags() {
    return useTagsOpt()!;
}

export function useTag(tagId?: string) {
    const { tags } = useTags();

    const tag = useMemo(() => {
        return tags.find(tag => tag.id === tagId)
    }, [tags, tagId]);

    return tag;
}

export function deleteTag(tag: Tag) {
    const documentRef = database.tags.doc(tag.id);

    return documentRef.delete()
}