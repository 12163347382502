import { createPortal } from "react-dom";

const LoadingOverlay: React.FC = () => {
    const overlayParent = document.getElementById('modal-root');

    if (!overlayParent)
        return null;

    return (
        createPortal(
            <div className="absolute h-screen w-full bg-white flex justify-center items-center z-50">
                <div className="absolute h-screen w-full bg-white bg-opacity-80 flex justify-center items-center bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 z-50">
                    <div className="animate-pulse m-auto mx-auto z-50 overflow-y-auto text-center" z-index="100000">
                        {/* <img src="../img/CostiExcel.svg" width="250" height="250" /> */}
                        <div className="text-white font-bold text-2xl">Loading …</div>
                    </div>
                </div>
            </div>
            , overlayParent)
    )
}

export default LoadingOverlay;